import DatePickerV2 from "./DatePickerV2";
import { Menu, Transition } from "@headlessui/react";
import { useState } from "react";
import { CalendarIcon } from "@heroicons/react/outline";
import format from "date-fns/format";
import { Fragment } from "react";
import dayjs from "dayjs";

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default function DatePickerMain({ onDateChange, selectedDateChild }) {
  const [selectedDate, setSelectedDate] = useState(
    selectedDateChild ?? format(new Date(), "dd/MM/yyyy")
  );
  const handleDateChange = (date) => {
    const buffer = new Date(date);
    const dateString = format(buffer, "dd/MM/yyyy");
    setSelectedDate(dateString);
    onDateChange(date);
  };

  return (
    <div>
      <Menu>
        <div>
          <Menu.Button className="w-[150px] h-8 border-2 rounded-lg flex justify-between items-center p-2">
            <div className="text-sm text-slate-400">{selectedDate}</div>
            <CalendarIcon className="h-5 cursor-pointer" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            {({ close }) => {
              return (
                <Menu.Items
                  className={
                    "absolute z-[1] w-[250px] h-fit rounded-lg p-[5px] bg-white shadow-md"
                  }
                >
                  <Menu.Item>
                    <DatePickerV2
                      handleDateChange={handleDateChange}
                      actualSelectedDate={dayjs(selectedDate, "DD MM YYYY")}
                      handleDateClick={() => {
                        close();
                      }}
                    />
                  </Menu.Item>
                </Menu.Items>
              );
            }}
          </Transition>
        </div>
      </Menu>
    </div>
  );
}
