import React from "react";
import FormGenerator from "components/form-generator/FormGenerator";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { MultiSelect } from "react-multi-select-component";

import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import Card from "ui/Card";
import customAxios from "middleware/customAxios";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline";

export default function UsersAdd() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let [selectedRoles, setSelectedRoles] = React.useState([]);
  let [selectedOutlets, setSelectedOutlets] = React.useState([]);
  let [roles, setRoles] = React.useState([]);
  let [outlets, setOutlets] = React.useState([]);
  let [loading, setLoading] = React.useState(false);
  let [username, setUsername] = React.useState("");
  let [password, setPassword] = React.useState("");
  let [uniqueLog, setUniqueLog] = React.useState("None");

  React.useEffect(() => {
    customAxios.get("/admin/roles").then((res) => {
      setRoles(res.data.map((role) => ({ label: role.name, value: role.id })));
    });
    customAxios.get("/admin/user-groups").then((res) => {
      setOutlets(
        res.data.map((outlet) => ({ label: outlet.name, value: outlet.id }))
      );
    });
  }, []);

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "Add User",
      })
    );
    dispatch(setNavigationComponents(null));
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Users",
          path: "/user",
        },
        {
          name: "Add User",
          path: "/users/add",
        },
      ])
    );
  });

  React.useEffect(() => {
    customAxios.get("/admin/user/check-uniqueness/" + username).then((res) => {
      setUniqueLog(res.data);
    });
  }, [username]);

  const formData = {
    contents: [
      {
        fields: [
          {
            name: "firstName",
            displayName: "First Name",
            type: "field",
            inputType: "string",
            required: true,
          },
          {
            name: "lastName",
            displayName: "Last Name",
            type: "field",
            inputType: "string",
            required: true,
          },
          {
            name: "email",
            displayName: "Email",
            type: "field",
            inputType: "email",
            required: true,
          },
          {
            name: "phone",
            displayName: "Phone",
            type: "field",
            inputType: "string",
            required: true,
          },
          {
            name: "address",
            displayName: "Address",
            type: "textArea",
            inputType: "text",
            required: true,
          },
          {
            name: "city",
            displayName: "City",
            type: "field",
            inputType: "string",
            required: true,
          },
          {
            name: "state",
            displayName: "State",
            type: "field",
            inputType: "string",
            required: true,
          },
        ],
      },
    ],
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      state: "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Email is invalid")
        .required("Email is required"),
      phone: Yup.string().required("Phone is required"),
      address: Yup.string().required("Address is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
    }),
    onSubmit: async (values, { setErrors }) => {
      let data = {
        username: username,
        firstName: values.firstName,
        lastName: values.lastName,
        password: password,
        email: values.email,
        address: values.address,
        city: values.city,
        state: values.state,
        phone: values.phone,
        roles: selectedRoles.map((role) => role.value),
        outlets: selectedOutlets.map((outlet) => outlet.value),
      };

      console.log(data);

      customAxios.post("/admin/user", data).then((res) => {
        navigate("/users");
      });
    },
  };

  return (
    <div className="absolute grid pb-40 gap-3 bg-slate-50  rounded-xl top-0 w-full ">
      <Card
        heading={"User Information"}
        description={"Please fill the following details"}
      >
        <div className="grid grid-cols-2 gap-3">
          <div className="flex flex-col space-y-2">
            <p className="text-[12px]">Username</p>
            <input
              type="text"
              className="border border-gray-300 rounded-md p-2"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            {
              // check character length of username
              username.length > 3 && (
                <div>
                  {uniqueLog ? (
                    <div className="flex flex-row items-center space-x-1">
                      <CheckCircleIcon className="h-5 w-5 text-green-500" />
                      <span className="text-green-500">
                        {`'${username}' is available`}
                      </span>
                    </div>
                  ) : (
                    <div className="flex flex-row animate-bounce items-center space-x-1">
                      <XCircleIcon className="h-5 w-5 text-red-500" />
                      <span className="text-red-500">
                        {`'${username}' is taken`}
                      </span>
                    </div>
                  )}
                </div>
              )
            }
          </div>
          <div className="flex flex-col space-y-2">
            <p className="text-[12px]">Password</p>
            <input
              type="password"
              className="border border-gray-300 rounded-md p-2"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
      </Card>
      <Card
        heading={"User Information"}
        description={"Please fill the following details"}
      >
        <FormGenerator
          grids={2}
          modal={true}
          buttonSubmit={{ title: "Proceed" }}
          formData={formData}
        />
      </Card>

      <Card
        heading={"Roles and Outlets"}
        description={"Assign the user to roles and outlets"}
      >
        <div className="flex flex-col space-y-3">
          <p>Select Roles</p>
          <MultiSelect
            style={{ height: 5 }}
            options={roles}
            value={selectedRoles}
            onChange={setSelectedRoles}
            labelledBy="Select"
          />
          <p>Select Outlets</p>
          <MultiSelect
            style={{ height: 5 }}
            options={outlets}
            value={selectedOutlets}
            onChange={setSelectedOutlets}
            labelledBy="Select Outlet"
          />
        </div>
      </Card>
    </div>
  );
}
