export const columns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Added on",
    accessor: "createdAt",
  },
  {
    Header: "Updated on",
    accessor: "updatedAt",
  },
  {
    Header: "Status",
    accessor: "status",
  },

  {
    Header: "Actions",
    accessor: "actions",
  },
];
