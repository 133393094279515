export const cellFunction = (cell) => {
  if (cell.column.id === "serializedProduct.stbSerialNumber") {
    return (
      <div className="flex flex-row space-x-2">
        <p className="text-sm ">
          {cell.value ??
            cell.row.original?.productVariant?.serializedProduc
              ?.sncSerialNumber ?? <p className="text-red-700">Not Assigned</p>}
        </p>
      </div>
    );
  }
  if (cell.column.id === "name") {
    return (
      <div className="flex flex-row space-x-2">
        <img
          src={
            process.env.REACT_APP_SERVER_URL +
              cell.row.original?.productVariant?.image ||
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcKGtXOa4PF2h8Gr04XXB7cwJwDBdz8ScN892tcpsi_A&s"
          }
          className="w-10 h-10"
          alt={cell.row.original?.productVariant?.image || "Product Image"}
        />
        <div>
          <h1>{cell.row.original.productVariant?.product?.name}</h1>
          <p className="text-sm font-semibold">
            {cell.row.original.productVariant?.name}
          </p>
        </div>
      </div>
    );
  }
  if (cell.column.id === "quantity") {
    return (
      <div className="flex flex-row space-x-2">
        <p className="text-sm ">{cell.value}</p>
        <p className="text-sm ">
          {cell.row.original.productVariant?.unit?.name}
        </p>
      </div>
    );
  }
  if (cell.column.id === "unitPrice") {
    return (
      <div className="flex flex-row space-x-2">
        <p className="text-sm ">{cell.value}</p>
      </div>
    );
  }
  if (cell.column.id === "totalPrice") {
    return (
      <div className="flex flex-row space-x-2">
        <p className="text-sm font-semibold">
          {cell.row.original.productVariant.price * cell.row.original.quantity}
        </p>
      </div>
    );
  }

  return cell.value ?? "-";
};
