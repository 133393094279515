import { StarIcon } from "@heroicons/react/solid";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";
import JSConfetti from "js-confetti";
import customAxios from "middleware/customAxios";
import axios from "axios";

export default function ReviewPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  let ticketId = useParams().ticketId;
  let userId = searchParams.get("userId");
  let [rating, setRating] = React.useState(0);
  let [hoveredRating, setHoveredRating] = React.useState(0);
  let [ratingSelected, setRatingSelected] = React.useState(false);
  let [submitted, setSubmitted] = React.useState(false);
  let [review, setReview] = React.useState("");
  let [ratingText, setRatingText] = React.useState("");
  let [ratingTexts, setRatingTexts] = React.useState({
    1: "We are sorry for the inconvenience. Please let us know how we can improve",
    2: "We are sorry for the inconvenience. Please let us know how we can improve",
    3: "Let us know how we can improve",
    4: "Great. We are glad you are satisfied",
    5: "We are glad you are satisfied",
  });

  //   if submitted throw confetti
  React.useEffect(() => {
    if (submitted) {
      const confetti = new JSConfetti();
      confetti.addConfetti({
        //    only star emoji
      });
    }
  }, [submitted]);

  return (
    <div className="flex justify-center items-center flex-col space-y-10  h-screen ">
      {/* logo */}
      <img
        src={"https://medianet.mv/img/medianet_logo.png"}
        alt="logo"
        className="w-20 h-20  rounded-md"
      />
      {submitted ? (
        <div className="flex flex-col space-y-5 items-center text-center justify-center">
          <h1 className="text-3xl font-bold">Thank you for your feedback</h1>
          <p className="text-gray-500 w-72">
            We appreciate your feedback to improve our services. You may close
            this window
          </p>
        </div>
      ) : (
        <div className="flex flex-col space-y-10 items-center justify-center">
          <div className="space-y-3 text-center">
            <h1 className="text-3xl font-bold">
              Please rate the service you received
            </h1>
            <p className="text-gray-500">
              We appreciate your feedback to improve our services
            </p>
          </div>

          <div className="flex items-center  justify-center space-x-4">
            {[1, 2, 3, 4, 5].map((star) => (
              <button
                key={star}
                onMouseEnter={() => setHoveredRating(star)}
                onMouseLeave={() => setHoveredRating(0)}
                onClick={() => {
                  setRating(star);
                  setRatingSelected(true);
                }}
                className="text-6xl"
              >
                <StarIcon
                  className={`h-16 w-16
              
                ${star <= rating ? "text-yellow-500" : "text-gray-300"}`}
                />
              </button>
            ))}
          </div>
          {ratingSelected && (
            <div className="text-center max-w-lg w-72">
              <p className="text-gray-800 text-sm font-medium">
                {ratingTexts[rating]}
              </p>
            </div>
          )}
          {/* Text Area */}
          <textarea
            onChange={(e) => setReview(e.target.value)}
            value={review}
            className="border-2 max-w-sm w-full h-32 rounded-xl bg-white border-gray-300 p-2"
            placeholder="Write your review here..."
          />
          <PrimaryButton
            title={"Submit Review"}
            onClick={() => {
              if (rating === 0) {
                alert("Please select a rating");
                return;
              }
              fetch(`https://api.mydhaaira.mv/api/v1/feedbacks/${ticketId}`, {
                method: "POST",
                mode: "cors",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  userId: userId,
                  rating: rating,
                  review: review,
                }),
              })
                .then((res) => {
                  if (res.status == 403) {
                    alert("You have already submitted a review");
                    return;
                  } else {
                    return res.json();
                  }
                })
                .then((data) => {
                  if (data.statusText == 403) {
                    alert(data.message);
                    return;
                  } else {
                    setSubmitted(true);
                  }
                })
                .catch((err) => {
                  alert(err);
                  console.log(err);
                });
            }}
          >
            Submit Review
          </PrimaryButton>
        </div>
      )}
    </div>
  );
}
