import DataGrid from "components/data-grid/DataGrid";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { columns } from "./columns";
import { useNavigate } from "react-router-dom";
import DropDownButton from "ui/buttons/DropDownButton";
import { PencilAltIcon, XIcon } from "@heroicons/react/outline";
import Card from "ui/Card";

export default function Outlets() {
  let navigate = useNavigate();
  let dispatch = useDispatch();

  let userData = useSelector((state) => state.data.userData);
  let permissions = userData.permissions;

  React.useEffect(() => {
    if (permissions && permissions[0].length > 0) {
      if (!permissions[0].includes("r-inventory")) {
        navigate("/401");
      }
    }
  }, []);

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "Inventories",
      })
    );
    dispatch(
      setNavigationComponents(
        <PrimaryButton
          title="Add Warehouse"
          onClick={() => navigate("/outlets/add")}
        />
      )
    );
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/home",
        },
        {
          name: "Inventories",
          path: "/outlets",
        },
      ])
    );
  });

  const cellFunction = (cell) => {
    if (cell.column.id === "name") {
      return (
        <div
          onClick={() => {
            navigate("/outlets/view/" + cell.row.original.id, {
              state: cell.row.original,
            });
          }}
          className="flex flex-row items-center space-x-3 cursor-pointer"
        >
          <p className="hover:underline">{cell.value}</p>
        </div>
      );
    }
    if (cell.column.id === "actions") {
      return (
        <DropDownButton
          items={[
            {
              title: "Edit",
              icon: <PencilAltIcon className="h-5 w-5" />,
              path: "/outlets/edit/" + cell.row.original.id,
              state: cell.row.original,
            },
            {
              title: "Delete",
              icon: <XIcon className="h-5 w-5" />,
              path: "/outlets/delete/" + cell.row.original.id,
              state: cell.row.original,
            },
          ]}
          title="Actions"
          icon={<i className="fas fa-ellipsis-v"></i>}
          className=""
          setSelected={(item) => {
            console.log(item);
          }}
          path="/outlets/"
        />
      );
    }
    return cell.value;
  };

  return (
    <div className="relative overflow-scroll min-h-[80vh] ">
      <Card
        heading={"All Inventories"}
        description={"Type in the search field below to search for a inventory"}
      >
        <div className="relative flex space-y-3 flex-col w-full">
          <DataGrid
            searchable={true}
            x_columns={columns}
            height={"h-[calc(100vh-230px)]"}
            api={"/admin/user-groups"}
            showHeader={true}
            cellFunction={cellFunction}
          />
        </div>
      </Card>
      <Outlet />
    </div>
  );
}
