import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { CogIcon, CubeIcon, RssIcon } from "@heroicons/react/outline";
import {
  FilmIcon,
  UserIcon,
  ChartBarIcon,
  XIcon,
  ChevronUpIcon,
} from "@heroicons/react/solid";
import { HomeIcon, TagIcon, ViewListIcon } from "@heroicons/react/solid";
import { FaSearchLocation, FaWarehouse } from "react-icons/fa";
import { Disclosure, Popover, Transition } from "@headlessui/react";
import SecondaryButton from "ui/buttons/SecondaryButton";

export default function NavBar() {
  let navigate = useNavigate();
  let userData = useSelector((state) => state.data.userData);
  let permissions = userData.permissions;

  const menuItems = [
    {
      name: "Home",
      icon: <HomeIcon className="w-4 h-4" />,
      path: "/home",
    },
    {
      name: "Warehouses",
      icon: <FaWarehouse className="w-4 h-4" />,
      path: "/outlets",
      permissions: ["r-inventory"],
    },
    {
      name: "Assesments",
      icon: <FaSearchLocation className="w-4 h-4" />,
      path: "/assesments",
      permissions: ["r-asses"],
    },
    {
      name: "Products",
      icon: <ViewListIcon className="w-4 h-4" />,
      permissions: ["r-product"],
      subMenu: [
        {
          id: 1,
          name: "All Products",
          path: "/products/all",
          permissions: ["r-product"],
        },
        {
          id: 2,
          name: "Serialized Products",
          path: "/products/serialized-products",
          permissions: ["r-product"],
        },
        {
          id: 3,
          name: "Collections",
          path: "/products/categories",
          permissions: ["r-product"],
        },
        {
          id: 4,
          name: "Stock Adjustments",
          path: "/products/adjustments",
          permissions: ["r-inventory"],
        },
        {
          id: 5,
          name: "Migrations",
          path: "/products/migrations",
          permissions: ["w-inventory"],
        },
      ],
    },
    {
      name: "Order Requests",
      icon: <CubeIcon className="w-4 h-4" />,
      path: "/order-requests",
      permissions: ["r-order-request"],
    },
    {
      name: "Sales",
      icon: <TagIcon className="w-4 h-4" />,
      path: "/sales",
      permissions: ["r-sales"],
    },
    {
      name: "Discounts",
      icon: <FilmIcon className="w-4 h-4" />,
      path: "/discounts",
      permissions: ["r-discount"],
    },
    {
      name: "Users",
      icon: <UserIcon className="w-4 h-4" />,
      path: "/users",
      permissions: ["r-usr"],
    },
    {
      name: "Analytics",
      icon: <ChartBarIcon className="w-4 h-4" />,
      path: "/analytics",
      permissions: ["r-report"],
    },
    {
      name: "Settings",
      icon: <CogIcon className="w-4 h-4" />,
      permissions: ["r-role", "w-role"],
      subMenu: [
        {
          id: 1,
          name: "General",
          path: "/settings/general",
          permissions: ["w-role"],
        },
        {
          id: 2,
          name: "Role Management",
          path: "/settings/role-management",
          permissions: ["r-role"],
        },
      ],
    },
  ];

  return (
    <div className="w-full relative flex flex-row space-x-2 px-4 items-center justify-between bg-slate-900 p-2">
      {/* Menu Popover */}
      <div className="flex flex-row justify-start items-center space-x-2">
        <Popover className="">
          {({ open }) => (
            <>
              <Popover.Button className="text-black focus:outline-none">
                {open ? (
                  <XIcon className="h-6 w-6" />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Popover.Panel
                  style={{
                    zIndex: 1000,
                  }}
                  className="absolute top-20 right-0 w-screen h-screen  transition transform  md:hidden"
                >
                  <div className="shadow-md    bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="px-5 pb-10 pt-4 h-[calc(100vh-60px)] overflow-auto flex flex-col pb-10 items-center justify-between">
                      {/* Categories */}
                      <div className="w-full space-y-1 pb-10 ">
                        <h1
                          className="text-lg pb-2 font-bold
                  "
                        >
                          Categories
                        </h1>
                        {menuItems?.map((category) => {
                          return (
                            <Disclosure>
                              {({ open }) => (
                                <div className="flex flex-col ">
                                  {category.subMenu ? (
                                    <Disclosure.Button className="flex justify-between w-full  py-2 text-sm font-medium text-left text-black focus:bg-white rounded-lg   f">
                                      <span>{category.name}</span>
                                      {category.subMenu?.length > 0 && (
                                        <ChevronUpIcon
                                          className={`${
                                            open ? "rotate-180 transform" : ""
                                          } h-5 w-5 text-teal-500 `}
                                        />
                                      )}
                                    </Disclosure.Button>
                                  ) : (
                                    <Link className="py-2 " to={category.path}>
                                      {category.name}
                                    </Link>
                                  )}

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Disclosure.Panel
                                      static
                                      className="px-4 pt-2 pb-4 text-sm text-gray-500"
                                    >
                                      <div className="grid grid-cols-1 gap-3 w-full">
                                        {category?.subMenu?.map((sc) => (
                                          <div
                                            onClick={() => {
                                              navigate(`${sc.path}`, {
                                                state: {
                                                  id: sc?.id,
                                                  category: {
                                                    id: category.id,
                                                    name: category,
                                                  },
                                                },
                                              });
                                            }}
                                            className="text-sm text-start font-medium text-gray-900 hover:text-gray-700"
                                          >
                                            {sc.name}
                                          </div>
                                        ))}
                                      </div>
                                    </Disclosure.Panel>
                                  </Transition>
                                </div>
                              )}
                            </Disclosure>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
        <Link
          to="/home"
          className="flex w-[350px] j space-x-4  items-center p-4  "
        >
          <img
            src={"https://medianet.mv/img/medianet_logo_color.png"}
            alt="logo"
            className="w-8 h-8  rounded-md"
          />
          <h1 className="text-white hidden font-bold text-xl italic">
            Medianet <span className="italic font-extralight">SIMS </span>
          </h1>
        </Link>
      </div>

      <div className="w-max flex align-super">
        <p className="text-white text-sm font-medium">
          {userData?.name} {userData?.surname}
        </p>
      </div>

      <SecondaryButton
        onClick={() => {
          localStorage.removeItem("token");
          navigate("/auth/login");
        }}
        title={"Logout"}
        className="hidden md:block"
      />
    </div>
  );
}
