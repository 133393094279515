import DataGrid from "components/data-grid/DataGrid";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { columns } from "./columns";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Card from "ui/Card";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { Dialog, Transition } from "@headlessui/react";

export default function Index() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [clients, setClients] = React.useState([]);
  let [showModal, setShowModal] = React.useState(false);
  // get url params
  const urlParams = new URLSearchParams(window.location.search);
  const modal = urlParams.get("Modal");

  React.useEffect(() => {
    if (modal) {
      setShowModal(true);
    }
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Clients", link: "" },
      ])
    );
    dispatch(
      setCurrent({
        name: "Products",
      })
    );
    dispatch(
      setNavigationComponents(
        <PrimaryButton
          onClick={() => {
            let urlParams = new URLSearchParams();
            urlParams.append("Modal", true);
            navigate("/products/add" + "?" + urlParams.toString());
          }}
          title="Add New Product"
        />
      )
    );
  }, []);

  const cellFunction = (cell) => {
    if (cell.column.id == "title") {
      const urlParams = new URLSearchParams();
      urlParams.append("title", cell.row.original.title);
      return (
        <div className="flex flex-row py-1 text-sm space-x-3 items-center">
          <img
            className="h-14 w-14 border border-slate-200 rounded-md "
            src={
              cell.row.original?.image
                ? cell.row.original?.image
                : "https://www.mydthpay.com/img/review.png"
            }
          />
          <Link
            to={
              `/products/${cell.row.original.id}` + "?" + urlParams.toString()
            }
            className=" hover:cursor-pointer hover:underline"
          >
            {cell.value}
          </Link>
        </div>
      );
    }
    if (cell.column.id == "variants") {
      return (
        <div className="flex flex-row py-1 text-sm space-x-3 items-center">
          {cell.value.map((variant, i) => {
            return (
              <div className="flex flex-row space-x-2 items-center">
                {variant.title}
              </div>
            );
          })}
        </div>
      );
    }

    return cell.value;
  };

  return (
    <div className="w-full ">
      <Outlet />
    </div>
  );
}
