import React from "react";
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import { Combobox } from "@headlessui/react";
import { useSelector } from "react-redux";
import ComboBoxComponent from "components/combo-box/ComboBoxComponent";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { useDropzone } from "react-dropzone";
import DatePickerMain from "components/date-picker-v2/DatePickerMain";
import moment from "moment";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

function FormGenerator({
  formData,
  height,
  isError,
  ErrorMsg,
  setSelectedValues,
  buttonSubmit,
  modal,
  closeModal,
  log,
  grids,
}) {
  const [files, setFiles] = React.useState([]);

  const thumbs = files.map((file) => (
    <div style={{ fontSize: 10 }} className="w-fit" key={file.name}>
      <div className="p-0.5 px-2 border border-gray-300">
        {/* <img src={file.preview} className="h-10 w-10" /> */}
        <p>{file.name}</p>
      </div>
    </div>
  ));

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );

      formik.setFieldValue(`documents`, acceptedFiles);
    },
  });

  const initialValues = formData.initialValues;

  const validationSchema = formData.validationSchema;

  const onSubmit = formData.onSubmit;

  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  return (
    <section className="relative">
      <FormikProvider value={formik}>
        <div className="flex flex-col space-y-3 pb-5">
          <Form className="">
            <div className={`${height}  flex flex-col space-y-3`}>
              {formData.array?.length >= 1 ? formData.arrayRender() : ""}

              {formData?.contents?.map((content, i) => (
                <div className="flex flex-col space-y-2 " key={i}>
                  <div className={`grid grid-cols-${grids} gap-3 md:gap-6`}>
                    {content?.fields?.map((field, i) => {
                      return (
                        <div
                          className="flex flex-col space-y-2  row-span-2 justify-start items-start    text-sm"
                          key={i}
                        >
                          <label
                            className="col-span-3 text-sm text-slate-600"
                            htmlFor={field.name}
                          >
                            {field.displayName}
                            {field.required ? (
                              <span className="text-teal-700 ml-1">*</span>
                            ) : (
                              ""
                            )}
                          </label>
                          <div className="w-full flex flex-col space-y-1">
                            {field?.type == "field" && (
                              <Field
                                // required={field.required}
                                name={field.name}
                                type={field.inputType}
                                className="w-full rounded-md text-black  py-1.5  px-2  text-sm border border-gray-300 focus:ring-orange-400 focus:outline-none"
                              />
                            )}

                            {field?.type == "pictureUpload" && (
                              <div className="space-y-3 group p-5 bg-white   border border-gray-300 rounded-md relative justify-start items-center">
                                <div
                                  style={{
                                    backgroundImage: field.value(formik)
                                      ? `url(${field.value(formik)}) `
                                      : `url('')`,
                                  }}
                                  className="h-44 w-44 rounded-md  bg-cover bg-center group-hover:opacity-50"
                                />
                                <div
                                  className="absolute top-[80px] left-[75px]
                                 "
                                >
                                  <input
                                    className="bg-orange-100 sticky z-50 opacity-0 h-14 w-14 border-orange-300 text-orange-800 focus:ring-0 cursor-pointer"
                                    type="file"
                                    name="image"
                                    onChange={(event) =>
                                      formik.setFieldValue(
                                        `${field?.name}`,
                                        event.target.files[0]
                                      )
                                    }
                                  />

                                  <img
                                    className=" absolute transition-all z-10 top-0 left-2 group-hover:opacity-70 opacity-10"
                                    src="https://cdn-icons-png.flaticon.com/512/4687/4687428.png"
                                    height={50}
                                    width={50}
                                  />
                                </div>
                              </div>
                            )}
                            {field?.type == "dateSelect" && (
                              <DatePickerMain
                                selectedDateChild={moment(new Date()).format(
                                  "DD/MM/yyyy"
                                )}
                                onDateChange={(e) =>
                                  formik.setFieldValue(`${field.name}`, e)
                                }
                                value={field.value(formik)}
                              />
                            )}
                            {field?.type == "editor" && (
                              <CKEditor
                                config={{
                                  removePlugins: [
                                    "EasyImage",
                                    "ImageUpload",
                                    "MediaEmbed",
                                    "Table",
                                    "BlockQuote",
                                  ],
                                }}
                                id="inputText"
                                height={600}
                                className="bg-parrot"
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  formik.setFieldValue(`${field.name}`, data);
                                }}
                              />
                            )}
                            {field.type == "checkBox" && (
                              <input
                                type={"checkbox"}
                                className="bg-orange-100  border-orange-300 text-orange-800 focus:ring-0 "
                              />
                            )}
                            {field.type == "textArea" && (
                              <Field
                                className="text-sm rounded-md text-black p-1 focus:outline-none border border-gray-300"
                                component="textarea"
                                rows="4"
                                options={field.options}
                                placeholer={field.placeholer}
                                name={field.name}
                              ></Field>
                            )}
                            {field.type == "fileUpload" && (
                              <div className="flex flex-col space-y-2">
                                <div
                                  {...getRootProps({ className: "dropzone" })}
                                  className="border border-gray-300 bg-white space-y-3 flex flex-col items-center justify-center  p-5"
                                >
                                  <input {...getInputProps()} />
                                  <img
                                    className="h-14 w-14"
                                    src="https://static.thenounproject.com/png/3322766-200.png"
                                  />
                                  <p className="" style={{ fontSize: 10 }}>
                                    Drag 'n' drop some files here, or click to
                                    select files
                                  </p>
                                </div>
                                <aside className="flex flex-wrap space-x-1  w-full max-w-[90vw] overflow-auto">
                                  {thumbs}
                                </aside>
                              </div>
                            )}
                            {field.type == "checkbox" && (
                              <input
                                className="bg-orange-100 border-orange-300 text-orange-800 focus:ring-0 cursor-pointer"
                                type="checkbox"
                                value={true}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    `${field.name}`,
                                    e.target.value
                                  )
                                }
                              />
                            )}
                            {field.type == "comboBox" && (
                              <Combobox
                                value={field.value(formik)}
                                onChange={(e) => {
                                  formik.setFieldValue(`${field.name}`, e);
                                  if (field.name == "Department") {
                                    setSelectedValues({
                                      department: e,
                                    });
                                  }
                                }}
                              >
                                <ComboBoxComponent
                                  options={field.options}
                                  values={field.values}
                                />
                              </Combobox>
                            )}

                            {field.type == "component" && (
                              <div className="flex flex-col justify-start text-start items-start text-sm space-y-2">
                                {field.component()}
                              </div>
                            )}

                            <ErrorMessage
                              name={field.name}
                              component="div"
                              style={{ fontSize: 11 }}
                              className="text-red-700"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>

            <div
              className={`${
                buttonSubmit.position ? "block mt-10" : "fixed"
              } top-24 md:top-8 right-10  z-50 `}
            >
              <PrimaryButton type={"submit"} title={buttonSubmit.title} />
            </div>
          </Form>
        </div>
      </FormikProvider>
    </section>
  );
}

export default FormGenerator;
