import Modal from "components/modal/Modal";
import React from "react";

export default function RatingScreen() {
  let [showModal, setShowModal] = React.useState(false);
  let reactions = [
    {
      emoji: "https://cdn-icons-png.flaticon.com/512/742/742744.png",
      color: "bg-red-500 hover:bg-red-600",
      value: "very_sad",
    },
    {
      emoji:
        "https://cdn.iconscout.com/icon/free/png-256/free-sad-2387665-1991063.png?f=webp",
      color: "bg-orange-500 hover:bg-orange-600",
      value: "sad",
    },
    {
      emoji: "https://cdn-icons-png.flaticon.com/512/1933/1933511.png",
      color: "bg-yellow-500 hover:bg-yellow-600",
      value: "neutral",
    },
    {
      emoji: "https://cdn-icons-png.flaticon.com/512/742/742923.png",
      color: "bg-teal-500 hover:bg-teal-600",
      value: "happy",
    },
    {
      emoji: "https://cdn-icons-png.flaticon.com/512/2136/2136634.png",
      color: "bg-green-500 hover:bg-green-600",
      value: "very_happy",
    },
  ];

  function reactionHandler(value) {
    setShowModal(true);
    setTimeout(() => {
      setShowModal(false);
    }, 5000);

    let localStorageObject = localStorage.getItem("reaction");
    if (!localStorageObject) {
      localStorageObject = [
        {
          reaction: "very_sad",
          count: 0,
        },
        {
          reaction: "sad",
          count: 0,
        },
        {
          reaction: "neutral",
          count: 0,
        },
        {
          reaction: "happy",
          count: 0,
        },
        {
          reaction: "very_happy",
          count: 0,
        },
      ];
      localStorage.setItem("reaction", JSON.stringify(localStorageObject));
    } else {
      localStorageObject = JSON.parse(localStorageObject);
      let reactionIndex = localStorageObject.findIndex(
        (item) => item.reaction === value
      );
      localStorageObject[reactionIndex].count++;
      localStorage.setItem("reaction", JSON.stringify(localStorageObject));
    }
  }

  return (
    <div
      style={{
        backgroundImage: `url(https://wallpapers.com/images/hd/white-screen-background-46xrbxusvqmuwr2i.jpg)`,
      }}
      className="h-screen w-screen bg-center bg-cover"
    >
      <div className="flex flex-col items-center justify-center h-full">
        <div className="flex flex-col space-y-10 max-w-7xl w-full text-center items-center justify-center">
          <h1 className="text-6xl leading-tight font-bold text-gray-800">
            Please rate your experience with us at Medianet
          </h1>
          <p className="text-gray-700 text-2xl mt-2">
            We are always looking to improve our services. Please take a moment
            to rate your experience with us.
          </p>
          <div className="flex flex-row pt-10 space-x-5">
            {reactions.map((reaction) => (
              <button
                onClick={() => reactionHandler(reaction.value)}
                key={reaction.value}
                className={`p-5 rounded-full
                active:scale-90 focus:outline-none
                hover:scale-110 duration-150 shadow-lg ${reaction.color}`}
              >
                <span className="text-5xl">
                  <img
                    src={reaction.emoji}
                    alt={reaction.value}
                    className="w-32 h-32x"
                  />
                </span>
              </button>
            ))}
          </div>
        </div>
      </div>
      <Modal show={showModal}>
        <div
          className="flex flex-col
            p-10 items-center justify-center
        space-y-5"
        >
          <h1 className="text-4xl font-bold text-gray-800">
            Thank you for your feedback!
          </h1>
          <p className="text-gray-700 text-2xl">We appreciate your time. </p>
        </div>
      </Modal>
    </div>
  );
}
