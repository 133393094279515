import React from "react";
import FormGenerator from "components/form-generator/FormGenerator";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import Card from "ui/Card";
import customAxios from "middleware/customAxios";
import { useNavigate } from "react-router-dom";

export default function CategoriesAdd() {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let [trigger, setTrigger] = React.useState(Math.random());
  let [productGroups, setProductGroups] = React.useState([]);
  let [collectionType, setCollectionType] = React.useState({});

  React.useEffect(() => {
    customAxios.get("/admin/product/groups").then((res) => {
      res.data.map((item) => {
        item.label = item.name;
        item.value = item.id;
      });
      setProductGroups(res.data);
      console.log(res.data);
    });
  }, []);

  React.useEffect(() => {
    console.log("collectionType", collectionType);
  }, [collectionType]);

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "Add Collection",
      })
    );
    dispatch(setNavigationComponents(null));
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Categories",
          path: "/categories",
        },
        {
          name: "Add Category",
          path: "/categories/add",
        },
      ])
    );
  }, [productGroups]);

  const formData = {
    contents: [
      {
        fields: [
          {
            name: "collectionType",
            displayName: "Collection Type",
            type: "comboBox",
            required: true,
            values:
              [
                {
                  name: "Category",
                  id: 0,
                },
                {
                  name: "Generic",
                  id: 1,
                },
                {
                  name: "Collection",
                  id: 2,
                },
              ] ?? [],
            value: (formik) => {
              setCollectionType(formik.values.collectionType);
              return formik.values.collectionType;
            },
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },
          // {
          //   name: "parentCategory",
          //   displayName: "Parent Category",
          //   type: "comboBox",
          //   values: productGroups ?? [],
          //   value: (formik) => {
          //     return formik.values.parentCategory;
          //   },
          //   options: function (item) {
          //     return (
          //       <div className="grid grid-cols-12">
          //         <span className="col-span-12">{item.name}</span>
          //       </div>
          //     );
          //   },
          // },
          {
            name: "name",
            displayName: "Name",
            type: "field",
            inputType: "string",
            required: true,
          },
          {
            name: "description",
            displayName: "Description",
            type: "textArea",
            inputType: "string",
            required: false,
          },
          {
            name: "price",
            displayName: "Price",
            type: "field",
            inputType: "number",
            required: false,
          },
        ],
      },
    ],
    initialValues: {
      name: "",
      description: "",
      parentCategory: "",
      collectionType: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      description: Yup.string().required("Description is required"),
      collectionType: Yup.object().required("Collection Type is required"),
    }),
    onSubmit: async (values, { setErrors }) => {
      let data = {
        name: values.name,
        description: values.description,
        parentId: values.parentCategory.id,
        image: values.image || null,
      };

      let queryParams = new URLSearchParams();

      let collectionType = values.collectionType.name;

      // lowercase the first character
      collectionType =
        collectionType.charAt(0).toLowerCase() + collectionType.slice(1);

      queryParams.append("type", collectionType);

      customAxios
        .post("/admin/product-group?" + queryParams, data)
        .then((res) => {
          console.log(res.data);
          navigate("/products/categories");
          setTrigger(Math.random());
        });
    },
  };

  return (
    <div className="absolute space-y-3 rounded-xl top-0 w-full min-h-[calc(100vh-100px)] bg-slate-50">
      <Card
        heading={"Add Collection"}
        description={"Please ignore the price when adding a categories"}
        table={false}
      >
        <FormGenerator
          grids={2}
          modal={true}
          buttonSubmit={{ title: "Proceed" }}
          formData={formData}
        />
      </Card>
      {collectionType.id === 2 && (
        <Card
          heading="Add Package Inclusions"
          description="Please fill the following details"
          table={false}
        >
          Test
        </Card>
      )}
    </div>
  );
}
