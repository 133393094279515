import { useState } from "react";
import dayjs from "dayjs";
import { act } from "react-dom/test-utils";

export default function DaySelect({ weeksOfTheMonth, handleDateChange, handleDateClick, selectedDate, setSelectedDate, currentDay, actualSelectedDate }) {
    return (
        <>
            <div className={"flex justify-between"}>
                {weeksOfTheMonth[0].map((day, index) => (
                    <div className={"h-[30px] w-[30px] m-[2px] flex items-center justify-center text-[#9BA4B4]"} key={`week-day-${index}`}>
                        {dayjs(day).format("dd")}
                    </div>
                ))}
            </div>
            {weeksOfTheMonth.map((week, weekIndex) => (
                <div className={"flex"} key={`week-${weekIndex}`}>
                    {week.map((day, dayIndex) => (
                        <div
                            onClick={() => {
                                handleDateChange(day);
                                handleDateClick(true)
                            }
                            }
                            key={`day-${dayIndex}`}
                            className={
                                selectedDate.clone().toDate().getMonth() !== day.getMonth()
                                    ? `${"h-[30px] w-[30px] m-[4px] flex items-center justify-center rounded-md cursor-pointer hover:bg-orange-50"} text-[#DAE1E7]`
                                    : dayjs(day).isSame(actualSelectedDate, "date")
                                        ? `${"h-[30px] w-[30px] m-[4px] flex items-center justify-center rounded-md cursor-pointer bg-orange-400 hover:bg-orange-600"} text-white`
                                        : `${"h-[30px] w-[30px] m-[4px] flex items-center justify-center rounded-md cursor-pointer hover:bg-orange-200"} text-[#1B1B2F]`
                            }
                        >
                            {day.getDate()}
                        </div>
                    ))}
                </div>
            ))}
            <div
                className="text-center p-1 m-1 rounded-lg font-semibold text-white  bg-orange-400 hover:bg-orange-600"
                onClick={() => { const today = new Date(); today.setHours(0, 0, 0, 0); handleDateChange(today); setSelectedDate(dayjs(today)); }}
            >
                Today
            </div>
        </>
    )
}