import Notification from "components/notification/Notification";
import useDarkSide from "hooks/useDarkTheme";
import React from "react";
import { useSelector } from "react-redux";
import BreadCrumbs from "../components/navigation/bread-crumbs/BreadCrumbs";
import SideMenu from "../components/navigation/side-menu/SideMenu";
import NavBar from "./navigation/NavBar";

export default function MainLayout({ children, component }) {
  let [theme, setTheme] = useDarkSide();
  let urlParams = new URLSearchParams(window.location.search);
  let outletId = urlParams.get("modal");
  let navigationComponents = useSelector(
    (state) => state.navigation.navigationComponents
  );
  let [collapsed, setCollapsed] = React.useState(false);
  let isPOS = useSelector((state) => state.navigation.isPOS);
  let current = useSelector((state) => state.navigation.current);
  let showNotification = useSelector((state) => state.notification.show);
  return (
    <div className="w-screen relative h-screen bg-slate-50 dark:bg-slate-900 overflow-hidden ">
      {/* <div className="relative z-10 bg-black">
        <div
          aria-hidden="true"
          class="absolute -top-1/2 bg-black w-full  right-10 inset-0 grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20"
        >
          <div class="blur-[100px] -translate-y-2/4  left-2 rounded-full z-10 h-24 w-[1250px] bg-gradient-to-br from-teal-700 to-teal-400"></div>
          <div class="blur-[100px] rotate-12 z-10 h-[600px] w-[1000px]  rounded-full bg-gradient-to-r from-yellow-400 to-teal-600 0"></div>
        </div>
      </div> */}
      {/* 
      <div
        style={{
          zIndex: 100,
        }}
        className=" fixed w-full top-0"
      >
        <NavBar />
      </div> */}

      <div className="flex w-full rounded-xl z-30 h-full  text-sm   justify-center items-start ">
        <div
          className={`${
            collapsed ? "grid-cols-12" : "grid-cols-2 lg:grid-cols-6"
          } grid transition-all gap-0  w-full h-full `}
        >
          <div className="hidden md:block">
            {!isPOS && <SideMenu collapsed={collapsed} />}
          </div>
          <div className="flex col-span-6 h-fit md:hidden">
            <NavBar />
          </div>

          <div
            className={`${
              collapsed ? "col-span-11" : "col-span-5"
            }  px-0 mt-5 md:mt-8 flex flex-col space-y-3 bg-slate-50 rounded-md h-full overflow-auto`}
          >
            {!isPOS && (
              <div className="flex  bg-slate-50 pb-3 px-2 md:px-10  justify-between items-start flex-row w-full -top-2 bg-trasparent  bg-blur-[100px]  space-y-2">
                {current?.name && (
                  <div className="flex flex-col space-y-1 md:space-y-2">
                    <h1 className="text-xl md:text-xl capitalize font-medium text-slate-800 dark:text-slate-200">
                      {current?.name ?? ""}
                    </h1>
                    <BreadCrumbs />
                  </div>
                )}

                <div>{navigationComponents ?? ""}</div>
              </div>
            )}
            {/* Notification Handlers */}
            {showNotification && <Notification />}
            <div className=" z-50 h-full pt-0 relative  px-2  md:px-10  w-full text-slate-900 rounded-md ">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
