import { Transition } from "@headlessui/react";
import React from "react";

export default function Modal({
  show,
  children,
  setShowModal,
  heading,
  subHeading,
}) {
  return (
    <div
      style={{
        zIndex: 10000,
      }}
      class="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <Transition
        show={show}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          onClick={() => setShowModal()}
          class="fixed h-full w-full mx-5 inset-0 bg-blend-saturation bg-clip-padding backdrop-filter duration-0 backdrop-blur-sm bg-opacity-20 border border-gray-100 bg-gray-400  transition-opacity"
        ></div>
        <div
          style={{
            zIndex: 10000,
          }}
          class="fixed inset-0 z-50 overflow-y-auto"
        >
          <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition
              enter="ease-out duration-600"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                class={`relative transform min-w-[calc(100vh-600px)] md:min-w-[calc(100vh-400px)]  overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all `}
              >
                {heading && (
                  <div className="flex flex-col space-y-1  p-5 border-b border-slate-200">
                    <h1 className="font-heading text-base font-medium text-slate-700">
                      {heading}
                    </h1>
                    <p className="text-sm text-slate-600">{subHeading}</p>
                  </div>
                )}

                <div className="overflow-auto max-h[calc(20vh-100px)] relative transition-all">
                  {children}
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </Transition>
    </div>
  );
}
