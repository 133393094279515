import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import customAxios from "middleware/customAxios";
import { useNavigate } from "react-router-dom";
import Card from "ui/Card";
import { MultiSelect } from "react-multi-select-component";
import DataGrid from "components/data-grid/DataGrid";
import {
  CheckIcon,
  ChevronDownIcon,
  ExclamationCircleIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { Popover } from "@headlessui/react";
import PrimaryButton from "ui/buttons/PrimaryButton";
import FileUpload from "components/dropZone/dropZone";

export default function OrderRequestAdd() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let data = useSelector((state) => state.data.userData);
  let [selectedInventory, setSelectedInventory] = React.useState();
  let [outlets, setOutlets] = React.useState([]);
  let [selectedProducts, setSelectedProducts] = React.useState([]);
  let [paymentData, setPaymentData] = React.useState({});
  let [total, setTotal] = React.useState({});

  let [products, setProducts] = React.useState([]);
  let [inventory, setInventory] = React.useState([]);
  // filer central inventory from  data.outlets

  React.useEffect(() => {
    customAxios.get("/admin/product-variants").then((res) => {
      let products = res.data.map((product) => {
        return {
          label: `${product.product.name} - ${product.name} `,
          value: product.id,
          data: product,
        };
      });
      setProducts(products);
    });
    setOutlets(
      data.outlets?.filter((outlet) => {
        return outlet.name != "Central Inventory";
      })
    );
  }, [data]);

  React.useEffect(() => {
    // calculate total
    let total = 0;
    let discount = 0;

    selectedProducts.forEach((item) => {
      total += item.data.total || 0;
      discount += item.data.discount || 0;
    });

    setTotal({
      discount: discount,
      total: total,
      subTotal: total - discount,
    });
  }, [selectedProducts]);

  function currentStockRenderer(varientId) {
    let item = inventory[0]?.find((x) => x.productVariant.id == varientId);

    let stock = item?.quantity;
    let alertQuantity = item?.productVariant.alertQuantity;
    let customAlertQuantity = item?.customAlertQuantity;
    let alertQuantityToUse = customAlertQuantity || alertQuantity;

    if (stock <= alertQuantityToUse) {
      return (
        <div className="flex text-sm items-center space-x-1 text-red-500">
          <ExclamationCircleIcon className="w-5 h-5 animate-pulse text-red-500" />
          <p className="text-red-500">{stock}</p>
          <p>Low Stock</p>
        </div>
      );
    } else {
      return <p className="text-sm">{stock}</p>;
    }
  }

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "Add Order Request",
      })
    );
    dispatch(setNavigationComponents(null));
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Order Requests",
          path: "/order-requests",
        },
        {
          name: "Add Order Request",
          path: "/order-requests/add",
        },
      ])
    );
  }, [products]);

  React.useEffect(() => {
    if (selectedInventory?.id) {
      customAxios
        .get("/admin/inventory/" + selectedInventory?.id)
        .then((res) => {
          setInventory(res.data.map((x) => x.inventory));
        });
    }
    setSelectedInventory(selectedInventory);
    setPaymentData({});
  }, [selectedInventory]);

  function submitHandler() {
    let data = {
      userGroupId: selectedInventory.group.id,
      paymentData: {
        paymentMethod: paymentData.paymentMethod || "bankTransfer",
        note: paymentData.note || "none",
        attachment: paymentData.attachment || "none",
      },
      orderRequestProducts: selectedProducts.map((x) => {
        return {
          productVariantId: x.value,
          quantity: x.data.quantity,
          note: x.data.note || "none",
        };
      }),
      total: total.total,
    };

    // console.log(data);

    customAxios.post("/admin/order-request", data).then((res) => {
      console.log(res);
      navigate("/order-requests");
    });
  }

  return (
    <div className="absolute space-y-3 align-top gap-3 h-[calc(100vh-120px)] rounded-xl top-0 w-full pb-20 bg-slate-50">
      <div className="fixed top-8 right-10">
        <PrimaryButton title={"Submit Order"} onClick={() => submitHandler()} />
      </div>
      <div className="flex flex-row space-x-5">
        <div className="p-6 bg-white h-fit w-full rounded-xl border border-slate-300 ">
          <div className="grid grid-cols-2">
            <div className="space-y-2">
              <p className="text-sm font-semibold">Origin</p>
              <h1 className="text-3xl font-medium text-slate-500">
                Central Inventory
              </h1>
            </div>
            <div className="space-y-2 relative">
              <p className="text-sm font-semibold">Destination</p>
              <Popover className={"relative focus:outline-none"}>
                {({ open, close }) => (
                  <>
                    <Popover.Button
                      className={`${
                        open ? "" : "text-opacity-70"
                      } group rounded-md inline-flex items-center text-slate-500 hover:text-slate-700 outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500`}
                    >
                      <h1 className="text-3xl font-medium e focus:outline-none text-amber-400">
                        {selectedInventory
                          ? selectedInventory.group.name
                          : "Select Destination"}
                      </h1>
                      <ChevronDownIcon
                        className={`${
                          open ? "" : "text-opacity-70"
                        } ml-2 h-5 w-5  text-slate-500 group-hover:text-slate-700`}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Popover.Panel className="absolute z-10 focus:outline-none w-screen max-w-lg px-4 top-14 -left-8">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-2 bg-white px-5 py-2 sm:gap-2 sm:p-8">
                          {outlets
                            .filter((x) => x.id !== 1)
                            ?.map((outlet) => {
                              return (
                                <button
                                  onClick={() => {
                                    setSelectedInventory(outlet);
                                    close();
                                  }}
                                  className={`flex flex-row hover:bg-white items-center space-x-4 p-2 rounded-xl ${
                                    selectedInventory?.id == outlet.id
                                      ? "bg-white border-2 border-slate-400"
                                      : ""
                                  }`}
                                >
                                  {selectedInventory?.id === outlet.id ? (
                                    <CheckIcon className="h-6 w-6 text-yellow-500" />
                                  ) : (
                                    <div className="h-6 w-6"></div>
                                  )}
                                  <div className="flex justify-between items-center w-full">
                                    <p className="text-xl font-medium text-slate-900">
                                      {outlet.group.name}
                                    </p>
                                    <p className="text-sm text-slate-500">
                                      {outlet.group.groupType}
                                    </p>
                                  </div>
                                </button>
                              );
                            })}
                          <PrimaryButton
                            title={"Add Inventory Group"}
                            onClick={() => navigate("/outlets/add")}
                          />
                        </div>
                      </div>
                    </Popover.Panel>
                  </>
                )}
              </Popover>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-6 gap-3 transition-all">
        <div
          className={
            selectedInventory?.group?.groupType == "BP"
              ? `col-span-4`
              : `col-span-6`
          }
        >
          <Card
            heading={"Add Products"}
            description={`Add products to the order request`}
          >
            <div className="space-y-3">
              <MultiSelect
                options={products || []}
                value={selectedProducts}
                onChange={setSelectedProducts}
                labelledBy={"Select"}
                placeholder={"Select Users"}
              />
              <DataGrid
                cellFunction={(cell) => {
                  if (cell.column.id === "data.quantity") {
                    return (
                      <input
                        type="number"
                        className="w-full border border-slate-300 rounded-md p-2"
                        value={cell.row.original.data.quantity}
                        min={0}
                        onChange={(e) => {
                          let value = e.target.value;
                          let products = selectedProducts.map((product) => {
                            if (product.value === cell.row.original.value) {
                              product.data.quantity = value;
                              product.data.total = value * product.data.price;
                            }
                            return product;
                          });
                          setSelectedProducts(products);
                        }}
                      />
                    );
                  }
                  if (cell.column.id === "currentStock") {
                    return (
                      <div>
                        {selectedInventory.group.name == null ? (
                          <div className="flex flex-row space-x-1 text-sm text-amber-600">
                            <ExclamationCircleIcon className="h-4 w-4" />
                            <p>Select a Destination</p>
                          </div>
                        ) : (
                          <div className="flex flex-row space-x-1 text-sm text-amber-600">
                            {currentStockRenderer(cell.row.original.data.id)}
                          </div>
                        )}
                      </div>
                    );
                  }
                  if (cell.column.id === "data.name") {
                    return (
                      <div className="flex flex-row space-x-2">
                        <img
                          src={
                            process.env.REACT_APP_SERVER_URL +
                              cell.row.original.data.image ||
                            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcKGtXOa4PF2h8Gr04XXB7cwJwDBdz8ScN892tcpsi_A&s"
                          }
                          className="w-10 h-10"
                        />
                        <div>
                          <h1>{cell.row.original.data.product.name}</h1>
                          <p className="text-sm font-semibold">
                            {cell.row.original.data.name}
                          </p>
                        </div>
                      </div>
                    );
                  }
                  if (cell.column.id === "actions") {
                    return (
                      <div className="flex flex-row space-x-3 justify-start items-center ">
                        <button
                          onClick={() => {
                            let temp = [...selectedProducts];
                            temp.splice(cell.row.index, 1);
                            setSelectedProducts(temp);
                          }}
                          // disabled={userData.length === 1}
                          className="flex flex-row items-center space-x-1 p-2  rounded-full text-white bg-red-500 hover:bg-red-600"
                        >
                          <TrashIcon className="w-5 h-5" />
                        </button>
                      </div>
                    );
                  }
                  return cell.value;
                }}
                x_columns={[
                  {
                    Header: "Name",
                    accessor: "data.name",
                  },
                  {
                    Header: "Code",
                    accessor: "data.code",
                  },
                  {
                    Header: "SKU",
                    accessor: "data.sku",
                  },
                  {
                    Header: "Current Stock",
                    accessor: "currentStock",
                  },
                  {
                    Header: "Price",
                    accessor: "data.price",
                  },
                  {
                    Header: "Quantity",
                    accessor: "data.quantity",
                  },
                  {
                    Header: "Total",
                    accessor: "data.total",
                  },

                  {
                    Header: "Actions",
                    accessor: "actions",
                  },
                ]}
                testData={selectedProducts || []}
                showHeader={true}
              />
            </div>
            <div className="w-full border-t border-slate-800 py-3  flex flex-row space-x-4 items-start justify-end">
              <div className="flex items-start flex-row space-x-2">
                <label>Total</label>
                <h1 className="text-3xl font-bold">{total?.subTotal}</h1>
              </div>
            </div>
          </Card>
        </div>
        {selectedInventory?.group?.groupType == "BP" && (
          <div className="col-span-2">
            <Card
              heading={"Payment Details"}
              description={"Please provide a screenshot of the transfer slip"}
            >
              {/* note */}
              <div className="space-y-1">
                <label className="text-sm font-medium">Payment Method</label>
                <select
                  className="w-full border border-slate-300 rounded-md p-2"
                  value={paymentData?.paymentMethod}
                  onChange={(e) =>
                    setPaymentData({
                      ...paymentData,
                      paymentMethod: e.target.value,
                    })
                  }
                >
                  <option value="bank-transfer">Bank Transfer</option>
                </select>
              </div>
              <div className="space-y-1">
                <label className="text-sm font-medium">Payment Note</label>
                <textarea
                  className="w-full border border-slate-300 rounded-md p-2"
                  value={paymentData?.paymentNote}
                  onChange={(e) =>
                    setPaymentData({
                      ...paymentData,
                      paymentNote: e.target.value,
                    })
                  }
                />
              </div>
              <div className="space-y-1 w-full">
                <label className="text-sm font-medium">Attachment</label>
                <div className="w-full">
                  <FileUpload />
                </div>
              </div>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
}
