import { XIcon } from "@heroicons/react/solid";
import DataGrid from "components/data-grid/DataGrid";
import Modal from "components/modal/Modal";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import { itemRender } from "functions/ItemRender";
import customAxios from "middleware/customAxios";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";
import SecondaryButton from "ui/buttons/SecondaryButton";
import Card from "ui/Card";
import { useReactToPrint } from "react-to-print";
import Invoice from "components/printables/Invoice";
import { useRef } from "react";
import { cellFunction } from "./CellFunction";
import BillPrint from "components/pos/order/components/components/BillPrint";

export default function AssesmentView() {
  let dispatch = useDispatch();
  let [data, setData] = React.useState({});
  let [pageData, setPageData] = React.useState({});
  const componentRef = useRef();
  const componentRefBill = useRef();
  let [loading, setLoading] = React.useState(true);
  let [orderProducts, setOrderProducts] = React.useState([]);
  let userData = useSelector((state) => state.data.userData);
  let permissions = userData?.permissions;
  let [show, setShowModal] = React.useState(false);
  let [trigger, setTrigger] = React.useState(Math.random());
  let [paymentLinkSent, setPaymentLinkSent] = React.useState(false);
  let [paymentVerificationData, setPaymentVerificationData] = React.useState(
    {}
  );
  let [remark, setRemark] = React.useState("");

  let [modalContent, setModalContent] = React.useState({
    heading: "",
    subHeading: "",
  });

  function modalChange() {
    setShowModal(!show);
  }

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "View" + " " + pageData?.ticketNumber,
      })
    );

    dispatch(
      setBreadCrumbs([
        {
          name: "Assesments",
          link: "/assesments",
        },
        {
          name: "View" + " " + pageData.ticketNumber,
          link: `/assements/view/${data.id}`,
        },
      ])
    );
    if (data?.isVoid) {
      dispatch(setNavigationComponents(<></>));
    } else {
      dispatch(
        setNavigationComponents(<div className="flex flex-row space-x-2"></div>)
      );
    }
  }, [data, permissions, trigger]);

  let { id } = useParams();

  React.useEffect(() => {
    customAxios.get(`/admin/assesment/${id}`).then((res) => {
      setPageData(res);
      setLoading(false);
    });
  }, [trigger]);

  function SMSHandler() {
    customAxios
      .post("/admin/assesment/send-payment/" + id, {
        phone: pageData?.phone,
      })
      .then(
        (res) => {
          console.log(res);
          setPaymentLinkSent(true);
        },
        (err) => {
          console.log(err);
          setPaymentLinkSent(false);
        }
      );
  }

  return (
    <div className="absolute flex flex-col space-y-3  align-top  min-h-[calc(100vh-120px)]  top-0 w-full pb-10 bg-slate-50">
      <div className="grid grid-cols-1 gap-3">
        {loading ? (
          <div className="flex flex-row justify-center items-center">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-3">
            {pageData?.invoice?.payments?.status == undefined &&
              !paymentLinkSent && (
                <div className="flex flex-row bg-amber-100 border border-amber-500 rounded-xl p-5 justify-start items-start">
                  <div className="flex flex-row w-full justify-between items-center space-y-2">
                    <p className="text-base text-black font-medium">
                      Pending payment verification. Please verify the payment
                    </p>
                    <SecondaryButton
                      onClick={() => {
                        SMSHandler();
                      }}
                      width={220}
                      title={"Verify Payment"}
                    />
                  </div>
                </div>
              )}
            <div className="grid grid-cols-12 gap-3">
              <div className="col-span-12 md:col-span-8 space-y-3">
                <Card heading={"Assesment Details"}>
                  <div className="grid grid-cols-4 gap-4">
                    {itemRender("Ticket Number", pageData?.ticketNumber)}
                    {itemRender("Phone", pageData?.phone)}
                    {itemRender("Status", pageData?.status)}
                    {itemRender("Customer Name", pageData?.name)}
                    {itemRender("Customer Email", pageData?.relationToCustomer)}
                    {itemRender("ID Number", pageData?.idCardNumber)}
                    <div className="col-span-2">
                      {itemRender(
                        "Description",
                        pageData?.description ? pageData?.description : "-"
                      )}
                    </div>
                    {itemRender(
                      "Created At",
                      moment(pageData?.createdAt).format("DD MMM YYYY ")
                    )}
                    {itemRender(
                      "Created By",
                      pageData?.technician?.userInfo?.firstName
                    )}
                  </div>
                </Card>
                <div className="grid grid-cols-12 gap-3">
                  <div className={`col-span-12`}>
                    <Card heading={"Assesment Services Request"}>
                      {pageData?.assesmentItems?.length > 0 && (
                        <>
                          <DataGrid
                            cellFunction={(cell) => {
                              if (cell.column.id === "quantity") {
                                return cell.value;
                              }

                              return cell.value;
                            }}
                            x_columns={[
                              {
                                Header: "Service name",
                                accessor: "serviceItem.name",
                              },
                              {
                                Header: "Price",
                                accessor: "serviceItem.price",
                              },
                              {
                                Header: "Quantity",
                                accessor: "quantity",
                              },
                              {
                                Header: "Total",
                                accessor: "total",
                                render: (cell) => {
                                  return (
                                    <div className="flex flex-row space-x-2">
                                      <p className="text-sm font-semibold">
                                        {cell.value}
                                      </p>
                                    </div>
                                  );
                                },
                              },
                            ]}
                            testData={pageData?.assesmentItems || []}
                            showHeader={true}
                          />
                        </>
                      )}
                    </Card>
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-4 space-y-3">
                <Card heading={"Invoice Details"}>
                  <div className="grid grid-cols-2 gap-8 pb-3">
                    {itemRender(
                      "Invoice Number",
                      pageData?.invoice?.invoiceNumber
                    )}
                    {itemRender(
                      "Payment Status",
                      pageData?.invoice?.payment?.status
                    )}
                    {itemRender(
                      "Invoice Date",
                      moment(pageData?.createdAt).format("DD MMM YYYY ")
                    )}
                    {itemRender(
                      "Payment Type",
                      // capitalize the first letter
                      "Online"
                    )}
                    {itemRender(
                      "Total Amount",
                      `MVR ${pageData?.invoice?.totalAmount}`
                    )}
                    {itemRender(
                      "Customer Address",
                      `${pageData?.customer?.address}`
                    )}
                    {itemRender(
                      "Customer Name",
                      `MVR ${pageData?.customer?.name}`
                    )}
                    {itemRender(
                      "Account Number",
                      `MVR ${pageData?.customer?.accountNumber}`
                    )}
                  </div>
                </Card>
                <Card heading={"Payment Details"}>
                  <div className="grid grid-cols-2 gap-8 pb-3">
                    {itemRender(
                      "Payment Number",
                      pageData?.invoice?.payments?.reference
                    )}
                    {itemRender(
                      "Payment Status",
                      pageData?.invoice?.payments?.status
                    )}
                    {itemRender(
                      "Payment Method",
                      pageData?.invoice?.payments?.paymentMethod
                    )}
                    {itemRender(
                      "Payment Note",
                      // capitalize the first letter
                      pageData?.invoice?.payments?.note
                    )}
                    {itemRender(
                      "Payment Initiated On",
                      // capitalize the first letter
                      moment(pageData?.invoice?.payments?.createdAt).format(
                        "DD MMM YYYY "
                      )
                    )}
                    <PrimaryButton title={"Mark as Paid"} />
                  </div>
                </Card>
              </div>
            </div>
          </div>
        )}
        <Modal
          heading={modalContent.heading}
          subHeading={modalContent.subHeading}
          show={show}
          setShowModal={modalChange}
        >
          <div>{modalContent?.content ? modalContent.content : ""}</div>
          <div>
            {modalContent?.remark && (
              <div className="space-y-1 p-5">
                <h1 className="text-sm font-medium">Remark</h1>
                <textarea
                  onChange={(e) => setRemark(e.target.value)}
                  className="w-full border border-gray-300 rounded-md p-2"
                  rows={3}
                />
              </div>
            )}
          </div>

          <div class="bg-gray-50 sticky bottom-0 w-full px-4 py-3 md:flex grid grid-cols-2 gap-3  justify-end items-center flex-row space-x-3">
            {modalContent?.secondary && (
              <SecondaryButton
                onClick={modalContent?.secondary?.onClick}
                title={modalContent?.secondary?.title}
              />
            )}

            {modalContent?.primary && (
              <PrimaryButton
                onClick={() => {
                  customAxios
                    .put(`/admin/sale/void/${data.id}`, {
                      remark,
                    })
                    .then((res) => {
                      setTrigger(Math.random());
                      modalChange();
                    });
                }}
                title={modalContent?.primary?.title}
              />
            )}

            <button
              onClick={modalChange}
              className="fixed top-8 hover:bg-slate-50 p-1 right-5"
            >
              <XIcon className="h-6 w-6" />
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
}
