import DataGrid from "components/data-grid/DataGrid";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Card from "ui/Card";
import PrimaryButton from "ui/buttons/PrimaryButton";
import DropDownButton from "ui/buttons/DropDownButton";
import { EyeIcon, MenuIcon, PencilAltIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import customAxios from "middleware/customAxios";

export default function ProductsIndex() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let [showModal, setShowModal] = React.useState(false);
  // get url params
  const urlParams = new URLSearchParams(window.location.search);
  const modal = urlParams.get("Modal");
  const [products, setProducts] = React.useState([]);

  let userData = useSelector((state) => state.data.userData);
  let permissions = userData.permissions;

  React.useEffect(() => {
    if (permissions && permissions[0].length > 0) {
      if (!permissions[0].includes("r-product")) {
        navigate("/401");
      }
    }
  }, []);

  React.useEffect(() => {
    if (modal) {
      setShowModal(true);
    }
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Clients", link: "" },
      ])
    );
    dispatch(
      setCurrent({
        name: "Products",
      })
    );
    dispatch(
      setNavigationComponents(
        <PrimaryButton
          onClick={() => {
            let urlParams = new URLSearchParams();
            urlParams.append("Modal", true);
            navigate("/products/add" + "?" + urlParams.toString());
          }}
          title="Add New Product"
        />
      )
    );
  });

  React.useEffect(() => {
    customAxios.get("/admin/products").then((res) => {
      setProducts(res.data);
    });
  }, []);

  const cellFunction = (cell) => {
    if (cell.column.id == "name") {
      return (
        <Link
          to={"/products/view/" + cell.row.original.id}
          className="text-slate-900 hover:underline"
        >
          {cell.row.original.name}
        </Link>
      );
    }
    if (cell.column.id == "variants") {
      return (
        <div className="flex flex-col justify-start items-start  space-y-1">
          {cell.row.original.variants.map((variant) => {
            return (
              <div className="w-full py-1 mr-0 grid grid-cols-5 align-middle text-start">
                <span className="col-span-2 flex flex-row font-medium items-center space-x-2">
                  <img
                    className="w-10 h-10 object-cover "
                    src={process.env.REACT_APP_SERVER_URL + variant.image}
                    alt={variant.image}
                  />
                  <span>{variant.name}</span>
                </span>

                <span className="flex items-center">{variant.code}</span>
                <span className="flex items-center">{variant.sku}</span>
                <span className="flex items-center">{variant.price}</span>
              </div>
            );
          })}
        </div>
      );
    }

    if (cell.column.id == "actions") {
      return (
        <div className="flex flex-row space-x-2 items-center">
          <DropDownButton
            title="Actions"
            items={[
              {
                title: "Edit",
                icon: <PencilAltIcon className="h-5 w-5" />,
                path: "/products/edit/" + cell.row.original.id,
                state: cell.row.original,
              },
              {
                title: "Delete",
                icon: <XIcon className="h-5 w-5" />,

                path: "/products/delete/" + cell.row.original.id,
                state: cell.row.original,
              },
              {
                title: "View",
                icon: <EyeIcon className="h-5 w-5" />,
                path: "/products/view/" + cell.row.original.id,
                state: cell.row.original,
              },
              {
                title: "History",
                icon: <MenuIcon className="h-5 w-5" />,
                path: "/products/history/" + cell.row.original.id,
                state: cell.row.original,
              },
            ]}
          />
        </div>
      );
    }
    if (cell.column.id == "createdAt") {
      return new Date(cell.value).toLocaleDateString();
    }

    return cell.value;
  };

  return (
    <div className="w-full ">
      <Card heading={"All Products"} description={"Here are all the products"}>
        <DataGrid
          testData={products || []}
          cellFunction={cellFunction}
          height={"h-[calc(100vh-230px)]"}
          x_columns={[
            {
              Header: "Name",
              accessor: "name",
            },
            {
              Header: "Description",
              accessor: "description",
            },
            {
              Header: (
                <div className="flex flex-col justify-start items-start  space-y-1">
                  <div className="border-b w-full text-center">Variants</div>
                  <div className="w-full mr-0 grid grid-cols-5 text-start">
                    <span className="col-span-2 flex flex-row justify-start items-start space-x-2">
                      Name
                    </span>

                    <span>Code</span>
                    <span>SKU</span>
                    <span>Price</span>
                  </div>
                </div>
              ),
              accessor: "variants",
            },
            {
              Header: "CreatedBy",
              accessor: "createdByUser.username",
            },
            {
              Header: "Created On",
              accessor: "createdAt",
            },
            {
              Header: "Actions",
              accessor: "actions",
            },
          ]}
          showHeader={true}
          pagination={true}
        />
      </Card>
    </div>
  );
}
