const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  show: false,
  notifications: [],
  current: "hello",
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotification: (state, action) => {
      state.current = action.payload;
      state.notifications.push(action.payload);
    },
    setShow: (state, action) => {
      state.show = action.payload;
    },
  },
});

export const { addNotification, setShow } = notificationSlice.actions;

export default notificationSlice.reducer;
