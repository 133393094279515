import React from "react";
import FormGenerator from "components/form-generator/FormGenerator";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";

export default function CategoriesEdit() {
  let dispatch = useDispatch();

  let data = {
    name: "Category 1",
    description: "This is a description",
    parentCategory: {
      name: "Category 2",
      id: 2,
    },
    image: "https://m.media-amazon.com/images/I/61HwpDbhcBL.jpg",
  };

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "Add Product",
      })
    );
    dispatch(setNavigationComponents(null));
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Products",
          path: "/products",
        },
        {
          name: "Add Product",
          path: "/products/add",
        },
      ])
    );
  }, []);

  const formData = {
    contents: [
      {
        fields: [
          {
            name: "name",
            displayName: "Name",
            type: "field",
            inputType: "string",
            required: true,
          },

          {
            name: "parentCategory",
            displayName: "Parent Category",
            type: "comboBox",
            required: true,
            values:
              [
                {
                  name: "None",
                  id: 0,
                },
                {
                  name: "Category 1",
                  id: 1,
                },
                {
                  name: "Category 2",
                  id: 2,
                },
              ] ?? [],
            value: (formik) => {
              return formik.values.parentCategory;
            },
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },
          {
            name: "description",
            displayName: "Description",
            type: "textArea",
            inputType: "string",
            required: false,
          },
          {
            name: "image",
            displayName: "Image",
            type: "pictureUpload",
            value: (formik) => {
              return formik.values.image;
            },
            required: false,
          },
        ],
      },
    ],
    initialValues: {
      name: data.name ?? "",
      description: data.description ?? "",
      parentCategory: data.parentCategory ?? "",
      image: data.image ?? "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().max(20).min(8).required("Password is required"),
      confirmPassword: Yup.string().required("Please confirm your password"),
    }),
    onSubmit: async (values, { setErrors }) => {
      console.log(values);
    },
  };

  return (
    <div className="absolute  rounded-xl p-5 top-0 w-full bg-white">
      <FormGenerator
        grids={2}
        modal={true}
        buttonSubmit={{ title: "Proceed" }}
        formData={formData}
      />
    </div>
  );
}
