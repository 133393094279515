import DataGrid from "components/data-grid/DataGrid";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { columns } from "./columns";
import { useNavigate } from "react-router-dom";
import DropDownButton from "ui/buttons/DropDownButton";
import { FaEdit } from "react-icons/fa";
import { PencilAltIcon, XIcon } from "@heroicons/react/outline";
import Card from "ui/Card";
import moment from "moment";
import { UserAddIcon } from "@heroicons/react/solid";
import customAxios from "middleware/customAxios";
import SecondaryButton from "ui/buttons/SecondaryButton";
import Modal from "components/modal/Modal";
import { itemRender } from "functions/ItemRender";

export default function Migrations() {
  let navigate = useNavigate();
  let [data, setData] = React.useState([]);
  let dispatch = useDispatch();
  let [showConfirmModal, setShowConfirmModal] = React.useState(false);
  let [selectedRow, setSelectedRow] = React.useState(null);
  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "Migrations",
      })
    );
    dispatch(setNavigationComponents(null));
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Products",
          path: "/products",
        },
        {
          name: "Migrations",
          path: "/products/migrations",
        },
      ])
    );
  }, []);

  const cellFunction = (cell) => {
    if (cell.column.id === "name") {
      return (
        <div
          onClick={() => {
            setSelectedRow(cell.row.original);
            navigate("/products/categories/view/" + cell.row.original.id, {
              state: cell.row.original,
            });
          }}
          className="flex flex-row items-center space-x-3 cursor-pointer"
        >
          <p className="hover:underline font-bold">{cell.value}</p>
        </div>
      );
    }
    if (cell.column.id === "customer?.name") {
      return cell.row.original.customer?.name ?? "N/A";
    }
    if (cell.column.id === "status") {
      switch (cell.value) {
        case "available":
          return (
            <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-green-100 text-green-800">
              Available
            </span>
          );
        case "sold":
          return (
            <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-red-100 text-red-800">
              Sold
            </span>
          );
        default:
          return (
            <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-gray-100 text-gray-800">
              N/A
            </span>
          );
          break;
      }
    }
    if (cell.column.id === "isAssigned") {
      return cell.value ? (
        <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-green-100 text-green-800">
          Yes
        </span>
      ) : (
        <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-red-100 text-red-800">
          No
        </span>
      );
    }
    if (cell.column.id === "assignType") {
      if (cell.row.original.isNewConnection) {
        return (
          <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-blue-100 text-blue-800">
            New
          </span>
        );
      } else if (cell.row.original.isReplacement) {
        return (
          <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-yellow-100 text-yellow-800">
            Replacement
          </span>
        );
      } else if (cell.row.original.isMigration) {
        return (
          <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-pink-100 text-pink-800">
            Migration
          </span>
        );
      } else {
        return (
          <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-gray-100 text-gray-800">
            N/A
          </span>
        );
      }
    }
    if (cell.column.id === "actions") {
      return (
        <SecondaryButton
          title="Assign"
          onClick={() => {
            setSelectedRow(cell.row.original);
            setShowConfirmModal(true);
          }}
          icon={<FaEdit />}
        />
      );
    }
    if (cell.column.id === "createdAt" || cell.column.id === "updatedAt") {
      return moment(cell.value).format("DD MMMM YYYY");
    }
    return cell.value;
  };

  React.useEffect(() => {
    customAxios.get("/admin/serialized-products").then((res) => {
      setData(
        res.data.filter((item) => {
          return item.isMigration === true;
        })
      );
    });
  }, []);

  return (
    <div className="w-full relative ">
      <Card
        heading={"All Available Products"}
        description={"This is a list of all serialized products in the system."}
      >
        <DataGrid
          search={true}
          x_columns={columns}
          testData={data || []}
          showHeader={true}
          cellFunction={(cell) => cellFunction(cell)}
          height={"h-[calc(100vh-230px)]"}
        />
      </Card>

      <Modal
        heading={`Are you sure you want to assign the device to ${
          selectedRow?.customer?.name ?? "N/a"
        }?`}
        show={showConfirmModal}
        setShow={setShowConfirmModal}
      >
        <div className="w-full bg-slate-50 p-5 grid grid-cols-5 gap-3 rounded-xl">
          <img
            src={
              process.env.REACT_APP_SERVER_URL +
              selectedRow?.productVariant?.image
            }
            className="w-20 object-contain"
          />
          <div className="grid grid-cols-3 col-span-4 gap-3">
            {itemRender("Product", selectedRow?.productVariant?.name)}
            {itemRender("Full Box", selectedRow?.isFullBox ? "Yes" : "No")}
            {itemRender("STB Serial Number", selectedRow?.stbSerialNumber)}
            {itemRender("Type", selectedRow?.type)}
            {itemRender("SNC Serial Number", selectedRow?.sncSerialNumber)}
          </div>
        </div>

        <div className="flex flex-row justify-end space-x-3 p-5">
          <SecondaryButton
            title="Cancel"
            onClick={() => {
              setSelectedRow(null);
              setShowConfirmModal(false);
            }}
          />
          <PrimaryButton
            title="Assign"
            onClick={() => {
              customAxios
                .put(
                  "/admin/serialized-products/" +
                    selectedRow?.id +
                    "/assign-customer",
                  {
                    accountNumber: selectedRow?.customer?.accountNumber,
                  }
                )
                .then((res) => {
                  setSelectedRow(null);
                  setShowConfirmModal(false);
                  customAxios.get("/admin/serialized-products").then((res) => {
                    setData(
                      res.data.filter((item) => {
                        return item.isMigration === true;
                      })
                    );
                  });
                });
            }}
          />
        </div>
      </Modal>

      <Outlet />
    </div>
  );
}
