import moment from "moment";
import React from "react";

export default function Invoice({ data }) {
  return (
    <div className="w-full h-full flex flex-col space-y-4">
      <div className="flex bg-slate-800 j space-x-4  items-center p-4 w-full ">
        <img
          src={"https://medianet.mv/img/medianet_logo_color.png"}
          alt="logo"
          className="w-8 h-8  rounded-md"
        />
        <h1 className="text-white font-bold text-xl italic">
          Medianet <span className="italic font-extralight">IMS </span>
        </h1>
      </div>
      <div className="flex flex-col space-y-2 p-5">
        <div className="text-xl font-bold">{data?.userGroup?.name}</div>
        <div className="text-sm max-w-sm ">
          {data?.userGroup?.address}, {data?.userGroup?.state}.
          {data?.userGroup?.city} , Maldives
        </div>
        <div className="text-sm max-w-sm ">Phone: {data?.userGroup?.phone}</div>
      </div>
      <div className="grid grid-cols-4 px-5 pb-5">
        {/* Billed to */}
        <div className="flex flex-col space-y-2 ">
          <div className="text-sm font-bold">Billed to</div>
          <div className="text-sm max-w-sm ">{data?.customer[0]?.name}</div>
          <div className="text-sm max-w-sm ">{data?.customer[0]?.address}</div>
          <div className="text-sm max-w-sm ">
            {data?.customer[0]?.phone ?? "-"}
          </div>
          <div className="text-sm max-w-sm ">
            {data?.customer[0]?.email ?? "-"}
          </div>
        </div>
        <div className="flex flex-col space-y-2 ">
          <div className="text-sm font-bold">Billed to</div>
          <div className="text-sm max-w-sm ">{data?.customer[0]?.name}</div>
          <div className="text-sm max-w-sm ">{data?.customer[0]?.address}</div>
          <div className="text-sm max-w-sm ">
            {data?.customer[0]?.phone ?? "-"}
          </div>
          <div className="text-sm max-w-sm ">
            {data?.customer[0]?.email ?? "-"}
          </div>
        </div>
        <div className="col-span-2 text-sm flex justify-between items-center px-5">
          <div className="flex flex-col font-bold space-y-2 ">
            <p>Invoice Number</p>
            <p>Invoice Date</p>
            <p>Due Date</p>
          </div>
          <div className="flex flex-col space-y-2 ">
            <p>{data?.invoice?.invoiceNumber}</p>
            <p>{data?.invoice?.createdAt}</p>
            <p>-</p>
          </div>
        </div>
      </div>
      {/* Table */}
      <table className=" text-sm m-5 pt-10">
        <thead>
          <tr className="bg-slate-800 text-white ">
            <th className="p-2 text-start">Product</th>
            <th className="p-2 text-start">Description</th>
            <th className="p-2 text-start">Quantity</th>
            <th className="p-2 text-start">Unit Price</th>
            <th className="p-2 text-start">Total</th>
          </tr>
        </thead>
        <tbody>
          {data?.saleProducts?.map((item, index) => {
            return (
              <tr
                className={`
                ${index % 2 === 0 ? "bg-gray-100" : "bg-white"}
              
              `}
                key={index}
              >
                <td className="p-2">
                  {item.productVariant?.product?.name}
                  {" - "}
                  {item.productVariant?.name}
                </td>
                <td className="p-2">{item?.product?.description ?? "-"}</td>
                <td className="p-2">{item?.quantity}</td>
                <td className="p-2">{item?.unitPrice}</td>
                <td className="p-2">{item?.total}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot className="">
          <tr>
            <td className="p-2"></td>
            <td className="p-2"></td>
            <td className="p-2"></td>
            <td className="p-2">Sub Total</td>
            <td className="p-2 items-end text-right">
              {(data?.invoice?.totalAmount - data?.invoice?.discount).toFixed(
                2
              )}
            </td>
          </tr>
          <tr>
            <td className="p-2"></td>
            <td className="p-2"></td>
            <td className="p-2"></td>
            <td className="p-2">Discount</td>
            <td className="p-2 items-end text-right">
              {data?.invoice?.discount}
            </td>
          </tr>
          <tr className="font-bold tex-lg">
            <td className="p-2"></td>
            <td className="p-2"></td>
            <td className="p-2"></td>
            <td className="p-2">Total</td>
            <td className="p-2 items-end text-right">
              {/* change to currency format */}
              {data?.invoice?.totalAmount}
            </td>
          </tr>
        </tfoot>
      </table>
      {/* Terms and Conditions */}
      <div className="flex flex-col space-y-2 p-5 fixed bottom-3">
        <div className="text-sm font-bold">Terms and Conditions</div>
        <p className="text-sm">The billed customer is required to </p>
      </div>
    </div>
  );
}
