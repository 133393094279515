import { Popover } from "@headlessui/react";
import DataGrid from "components/data-grid/DataGrid";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import { itemRender } from "functions/ItemRender";
import customAxios from "middleware/customAxios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";
import SecondaryButton from "ui/buttons/SecondaryButton";
import Card from "ui/Card";

export default function OutletView() {
  let dispatch = useDispatch();
  let { id } = useParams();
  let [data, setData] = React.useState([]);
  let [loading, setLoading] = React.useState(true);
  let [inventory, setInventory] = React.useState([]);
  let [trigger, setTrigger] = React.useState(Math.random());
  let [adjustmentData, setAdjustmentData] = React.useState({
    inventoryId: "",
    quantity: "",
    note: "",
  });

  let navigate = useNavigate();

  let userData = useSelector((state) => state.data.userData);
  let permissions = userData.permissions;

  React.useEffect(() => {
    if (permissions && permissions[0].length > 0) {
      if (!permissions[0].includes("r-inventory")) {
        navigate("/401");
      }
    }
  }, []);

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: data?.name,
      })
    );
    dispatch(
      setNavigationComponents(<PrimaryButton title={"Request for Stock"} />)
    );
    dispatch(
      setBreadCrumbs([
        {
          name: "Outlets",
          link: "/outlets",
        },
        {
          name: data?.name,
          link: `/products/${data.id}`,
        },
      ])
    );
    dispatch(
      setNavigationComponents()
      // <div className="flex flex-row space-x-3">
      //   <PrimaryButton title={"Edit"} />
      //   <SecondaryButton title={"Delete"} />
      // </div>
    );
  }, [data]);

  React.useEffect(() => {
    customAxios.get("/admin/user-group/" + id).then((res) => {
      setData(res.data);
    });
    customAxios.get("/admin/inventory/" + id).then((res) => {
      setInventory(res.data);
    });
  }, [trigger]);

  const groupTypeRender = (type) => {
    switch (type) {
      case "BP":
        return (
          <p>
            <span className="font-bold px-4  text-[10px]  text-white bg-red-500 p-2 rounded-xl">
              Business Partner
            </span>
          </p>
        );
      case "SP":
        return (
          <p>
            <span className="font-bold px-4  text-[10px]  text-white bg-indigo-500 p-2 rounded-xl">
              Supervisor Group
            </span>
          </p>
        );
      default:
        return (
          <p>
            <span className="font-bold px-4  text-[10px]  text-white bg-green-500 p-2 rounded-xl">
              Outlet
            </span>
          </p>
        );
    }
  };

  return (
    <div className="absolute bg-slate-50 pt-2 space-y-6 min-h-[80vh] rounded-xl top-0 w-full ">
      <div className="flex flex-row space-x-3">
        {data.isCentral && (
          <p>
            <span className="font-bold text-white bg-slate-800 text-[10px] p-2 px-4 rounded-xl">
              Central Outlet
            </span>
          </p>
        )}
        {groupTypeRender(data?.groupType)}
      </div>

      <div className="grid grid-cols-1 gap-3">
        <Card heading={"Outlet Information"}>
          <div className="grid grid-cols-4 gap-3">
            {itemRender("ID", data.groupType)}
            {itemRender("Name", data.name)}
            {itemRender("Phone", data.phone)}
            {itemRender("Address", data.address)}
            {itemRender("City", data.city)}
            {itemRender("State", data.state)}
            {itemRender("Country", data.country)}
          </div>
        </Card>
        <Card heading={"Outlet Inventory"}>
          <DataGrid
            testData={inventory || []}
            cellFunction={(cell) => {
              if (cell.column.id == "product.name") {
                return (
                  <div className="flex flex-row py-1 text-sm space-x-3 items-center">
                    <Link
                      to={`/products/${cell.row.original.product.id}`}
                      className=" hover:cursor-pointer hover:underline"
                    >
                      {cell.value}
                    </Link>
                  </div>
                );
              }
              if (cell.column.id == "inventory") {
                return (
                  <div>
                    {cell.value.map((variant, i) => {
                      return (
                        <div className=" w-[800px] grid grid-cols-7 text-start mr-0  py-2 border-slate-200">
                          <span className="font-bold">
                            {variant.productVariant?.name}
                          </span>
                          <span>{variant.productVariant?.code}</span>
                          <span>{variant.productVariant?.sku}</span>
                          <span>{variant.productVariant?.price}</span>
                          <span>
                            {variant.quantity <=
                            variant.productVariant.alertQuantity ? (
                              <div
                                onClick={() => {
                                  navigate("/order-requests/add");
                                }}
                                className="flex animate-pulse flex-col justify-start items-start"
                              >
                                <p className="text-[10px] text-red-600">
                                  {variant.quantity}
                                </p>
                                <p className="text-[10px] text-red-600">
                                  Request Stock Immediately
                                </p>
                              </div>
                            ) : (
                              <p className="text-green-600 text-[10px]">
                                {variant.quantity} Avaliable
                              </p>
                            )}
                          </span>
                          <span>
                            {variant.productVariant.alertQuantity || 0}
                          </span>
                          <div className="">
                            <Popover
                              onAbort={() =>
                                setAdjustmentData({
                                  ...adjustmentData,
                                  quantity: 0,
                                  variantId: null,
                                  inventoryId: null,
                                })
                              }
                            >
                              <Popover.Button>
                                <button className="bg-gradient-to-b px-4 font-medium border border-slate-200 from-white to-slate-100 text-black p-2 rounded-md">
                                  Adjust
                                </button>
                              </Popover.Button>
                              <Popover.Panel>
                                {({ close }) => (
                                  <div className="absolute z-50  border border-slate-200  bg-white rounded-md shadow-lg">
                                    <div className="flex w-full flex-col -space-y-1 p-4">
                                      <div className="flex flex-col w-full justify-between items-start">
                                        <div className="text-sm w-full font-bold pb-2 border-b border-slate-300">
                                          Bring Adjustments
                                        </div>
                                        <div className="space-y-2">
                                          <div className="py-2 space-y-1">
                                            <label className="flex items-center">
                                              Quantity
                                            </label>
                                            <input
                                              type="number"
                                              className="w-full border border-slate-300 rounded-md p-2"
                                              autoFocus={true}
                                              // value={adjustmentData.quantity}
                                              onChange={(e) =>
                                                setAdjustmentData({
                                                  ...adjustmentData,
                                                  quantity: parseInt(
                                                    e.target.value
                                                  ),
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      {/* notes */}
                                      <div className="flex flex-col w-full justify-between items-start">
                                        <div className="space-y-2">
                                          <div className="py-2 space-y-1">
                                            <label className="flex items-center">
                                              Notes
                                            </label>
                                            <textarea
                                              className="w-full border border-slate-300 rounded-md p-2"
                                              // value={adjustmentData.notes}
                                              onChange={(e) =>
                                                setAdjustmentData({
                                                  ...adjustmentData,
                                                  note: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div>
                                        <PrimaryButton
                                          title={"Save"}
                                          onClick={() => {
                                            let urlParams =
                                              new URLSearchParams();
                                            urlParams.append(
                                              "type",
                                              adjustmentData.quantity > 0
                                                ? "add"
                                                : "remove"
                                            );
                                            let data = {
                                              quantity: adjustmentData.quantity,
                                              note: adjustmentData.note,
                                            };

                                            customAxios
                                              .put(
                                                "/admin/inventory/adjustment/" +
                                                  variant.id +
                                                  "?" +
                                                  urlParams,
                                                data
                                              )
                                              .then((res) => {
                                                console.log(res);
                                                setAdjustmentData({
                                                  ...adjustmentData,
                                                  quantity: 0,
                                                  variantId: null,
                                                  inventoryId: null,
                                                });
                                                close();
                                                setTrigger(
                                                  Math.random() * 1000
                                                );
                                              });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Popover.Panel>
                            </Popover>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              }
              return cell.value;
            }}
            x_columns={[
              {
                Header: "Name",
                accessor: "product.name",
              },
              {
                Header: "Description",
                accessor: "product.description",
              },
              {
                Header: (
                  <div className="flex flex-col justify-start items-start  space-y-1">
                    <div className="border-b w-full text-center">Variants</div>
                    <div className="w-[800px] mr-0 grid grid-cols-7 text-start">
                      <span>Name</span>
                      <span>Code</span>
                      <span>SKU</span>
                      <span>Price</span>
                      <span>Quantity</span>
                      <span>Alert Stock</span>
                      <span>Actions</span>
                    </div>
                  </div>
                ),
                accessor: "inventory",
              },
              {
                Header: "Updated On",
                accessor: "product.updatedAt",
              },
            ]}
            showHeader={true}
            pagination={true}
          />
        </Card>
      </div>
    </div>
  );
}
