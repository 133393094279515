import Modal from "components/modal/Modal";
import React from "react";
import PrimaryButton from "ui/buttons/PrimaryButton";
import SecondaryButton from "ui/buttons/SecondaryButton";
import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import customAxios from "middleware/customAxios";
import LoadingPrimaryButton from "ui/buttons/LoadingPrimaryButton";

export default function SyncCard() {
  let navigate = useNavigate();
  let [show, setShow] = React.useState(true);
  const [selected, setSelected] = useState({});
  let [completed, setCompleted] = React.useState(false);
  const [query, setQuery] = useState("");
  let [cards, setCards] = React.useState([]);

  React.useEffect(() => {
    customAxios.get("/admin/serialized-products/all").then((res) => {
      let temp_cards = res.data.filter((card) => {
        if (card.productVariantId == 20) {
          card.name = card.sncSerialNumber;
          card.id = card.id;
          return card;
        }
      });
      setCards(temp_cards);
    });
  }, []);

  const filteredCards =
    query === ""
      ? cards
      : query.length >= 3
      ? cards.filter((card) =>
          card.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        )
      : [];

  function modalChange() {
    setShow(!show);
    navigate("/products/serialized-products");
  }

  return (
    <div className="  ">
      <Modal
        show={show}
        setShowModal={modalChange}
        heading="Sync a Card to STB"
        subHeading="Please fill the form below to sync a card to STB."
      >
        <div className="p-5 space-y-2">
          {/* Assign Card */}
          <div className="flex flex-col space-y-2">
            <label className="text-sm font-medium">Card Number</label>
            <Combobox value={selected} onChange={setSelected}>
              <div className="relative mt-1">
                <div className=" w-full flex justify-between items-center cursor-default border border-slate-200 sm:text-sm">
                  <Combobox.Input
                    className="w-full focus:outline-none border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                    displayValue={(person) => person.name}
                    onChange={(event) => setQuery(event.target.value)}
                  />
                  <Combobox.Button className=" inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Combobox.Button>
                </div>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  afterLeave={() => setQuery("")}
                >
                  <Combobox.Options className=" mt-1 max-h-60 w-full border border-slate-200 overflow-auto rounded-md bg-white py-1 text-base  ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {filteredCards.length === 0 && query !== "" ? (
                      <div className=" cursor-default select-none py-2 px-4 text-gray-700">
                        Nothing found.
                      </div>
                    ) : (
                      filteredCards.map((card) => (
                        <Combobox.Option
                          key={card.id}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active
                                ? "bg-slate-600 text-white"
                                : "text-gray-900"
                            }`
                          }
                          value={card}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                Card - {card.name}
                              </span>
                              {selected ? (
                                <span
                                  className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                    active ? "text-white" : "text-slate-600"
                                  }`}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      ))
                    )}
                  </Combobox.Options>
                </Transition>
              </div>
            </Combobox>
          </div>
        </div>
        <div className="flex flex-row bg-slate-50 justify-end space-x-2 p-3">
          <SecondaryButton
            title={"Cancel"}
            className="bg-slate-300 text-white rounded-md px-3 py-2"
            onClick={modalChange}
          ></SecondaryButton>
          <LoadingPrimaryButton
            log={"Syncing..."}
            submitted={completed}
            onClick={() => {
              setCompleted(true);
              setTimeout(() => {
                setCompleted(false);
                modalChange();
              }, 2000);
            }}
            title={"Sync"}
            className="bg-slate-300 text-white rounded-md px-3 py-2"
          ></LoadingPrimaryButton>
        </div>
      </Modal>
    </div>
  );
}
