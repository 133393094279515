export const columns = [
  {
    Header: "Order Request ID",
    accessor: "referenceNumber",
  },
  {
    Header: "Date",
    accessor: "createdAt",
  },
  {
    Header: "Inventory Location",
    accessor: "userGroup.name",
  },
  {
    Header: "Requested By",
    accessor: "createdByUser.username",
  },
  {
    Header: "Status",
    accessor: "status",
  },
];
