import { currencyFormatter } from "functions/currencyFormatter";
import customAxios from "middleware/customAxios";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

export default function BillPrint({
  data,
  checkoutData,
  outlet,
  customer,
  collectiveData,
}) {
  const user = useSelector((state) => state.data.userData);
  let currentSale = useSelector((state) => state.cart.currentSale);
  let [inventory, setInventory] = React.useState({});
  React.useEffect(() => {
    customAxios.get("/admin/user-group/" + data?.id).then((res) => {
      setInventory(res);
    });
  }, []);
  return (
    <code className="p-20">
      <div className="p-10 flex justify-center items-center flex-col">
        <img
          src={"https://medianet.mv/img/medianet_logo.png"}
          alt="logo"
          className="w-20 h-20"
        />
        <div className="mt-5 text-center">
          <h1 className="text-base font-bold">Medianet Pvt Ltd</h1>
          <h2 className="text-sm font-bold">H. Zoneyria, 5/FL C.349/2005</h2>
          <h2 className="text-sm font-bold">TIN: 1000935GST501</h2>
        </div>
      </div>
      <div className="text-left text-sm p-1">
        <h1>
          Reciept No :{" "}
          {currentSale.referenceNumber ?? collectiveData?.referenceNumber}
        </h1>
        <h2>
          Date:{" "}
          {moment().format("DD MMMM yyyy HH:mm:ss") ??
            moment(collectiveData.createdAt).format("DD-MM-YYYY")}
        </h2>
        <h2>
          Customer : {customer?.name ?? collectiveData?.customer[0]?.name}
        </h2>
        <h2>
          Account Number :{" "}
          {customer?.accountNumber ??
            collectiveData?.customer[0]?.accountNumber}
        </h2>
        {checkoutData?.customerTin && (
          <h2 className="">TIN: {checkoutData?.customerTin}</h2>
        )}
        <h3>
          Payment Method:{" "}
          {checkoutData?.paymentMethod?.name ??
            collectiveData?.payment?.paymentMethod}
        </h3>
        <h3>
          {" "}
          Cashier : {user?.name ?? collectiveData?.createdByUser?.username}
        </h3>
      </div>
      <div className="border-t-2 text-sm border-black border-dashed p-1">
        {data?.items?.map((item) => (
          <div className="flex flex-col py-2 justify-between text-start">
            <p>{item.productName ?? item.name}</p>
            <div className="justify-between flex flex-row">
              <p>
                {item.quantity} x {item.unitPrice} (
                {item.discount
                  ? item.discount +
                      "@" +
                      item.appliedDiscount?.percentage +
                      "%" ?? item.fixedAmount
                  : 0}
                )
              </p>
              <p>
                {currencyFormatter(
                  item?.price -
                    0.08 * item?.price -
                    (item?.discount - 0.08 * item?.price)
                )}
              </p>
            </div>
          </div>
        )) ??
          collectiveData?.saleProducts?.map((item) => (
            <div className="flex flex-col py-2 justify-between text-start">
              <p>{item.productVariant?.name}</p>
              <div className="justify-between flex flex-row">
                <p>
                  {item.quantity} x {item.unitPrice}
                </p>
                <p>
                  {currencyFormatter(
                    item?.unitPrice -
                      0.08 * item?.unitPrice -
                      (item?.discount - 0.08 * item?.unitPrice)
                  )}
                </p>
              </div>
            </div>
          ))}
      </div>
      <div className="border-t-2 space-y-1 text-start  border-black border-dashed py-2">
        <p>
          Item Count :{" "}
          {data?.items?.length ?? collectiveData?.saleProducts?.length}
        </p>
      </div>
      <div className="py-1 w-full flex flex-col ">
        <div className="justify-between text-sm flex flex-row">
          <p>Discount</p>
          <p>
            {currencyFormatter(
              data?.totalDiscount ?? collectiveData?.invoice?.discount
            )}
          </p>
        </div>
        <div className="justify-between flex flex-row text-sm ">
          <p>Sub Total</p>
          <p>
            {currencyFormatter(
              collectiveData
                ? collectiveData?.invoice?.totalAmount -
                    collectiveData?.invoice?.totalAmount * 0.08
                : data?.total -
                    0.08 * data?.total -
                    (data?.totalDiscount - 0.08 * data?.totalDiscount)
            )}
          </p>
        </div>
        <div className="justify-between flex flex-row text-sm ">
          <p>Taxes</p>
          <p>
            {currencyFormatter(
              collectiveData
                ? collectiveData?.invoice?.totalAmount * 0.08
                : 0.08 * data?.total
            )}
          </p>
        </div>
        <div className="justify-between py-2 font-bold text-base flex flex-row">
          <p>Total</p>
          <p>
            {currencyFormatter(
              collectiveData
                ? collectiveData?.invoice.totalAmount
                : data?.total - data?.totalDiscount
            )}
          </p>
        </div>
        {checkoutData?.paymentMethod?.name === "Cash" && (
          <div className="justify-between flex flex-row text-sm mt-10 ">
            <div className="justify-between flex flex-col">
              <p>Cash</p>
              <p>{currencyFormatter(checkoutData?.recieved ?? "N/A")}</p>
            </div>
            <div className="justify-between flex flex-col">
              <p>Paid Amount</p>
              <p>
                {currencyFormatter(
                  data.total - data.totalDiscount ??
                    collectiveData?.invoice?.totalAmount -
                      collectiveData?.invoice?.discount
                )}
              </p>
            </div>
            <div className="justify-between flex flex-col">
              <p>Change</p>
              <p>
                {currencyFormatter(
                  data.total + data?.totalDiscount - checkoutData?.recieved ??
                    "N/A"
                )}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="border-t-2 space-y-2 text-start border-black border-dashed py-2">
        <div className="border-t-2 space-y-2 text-start border-black border-dashed py-2"></div>
      </div>
      <p>Thank you for shopping with us</p>
      <p>Medianet, Maldives</p>
      <p className="text-sm pt-5">
        If you have any questions about your purchase, please call us at +960
        3320800
      </p>
      <p>
        <div>
          {data?.items?.find((item) => item.name == "CP Device (ONT)") ? (
            <div className="text-sm font-bold mt-5 ">
              The customer has been informed that the ONT device is the property
              of Medianet Pvt Ltd and must be returned to Medianet Pvt Ltd if
              the customer terminates the service. The Refund will be processed
              only after the device is returned to Medianet Pvt Ltd in good
              condition.
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </p>

      {/* <pre>
        <code>{JSON.stringify(outlet, null, 2)}</code>
        <code>{JSON.stringify(customer)}</code>
        <code>{JSON.stringify(data, null, 2)}</code>
      </pre> */}
    </code>
  );
}
