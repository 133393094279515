export const columns = [
  // ticketNumber
  // "ticketNumber": "TEST12233",
  // 	"phone": "9993529",
  // 	"technicianId": 1,
  // 	"customerId": 1,
  // 	"createdAt": "2023-11-14T05:25:45.000Z",
  // 	"updatedAt": "2023-11-14T05:25:45.000Z",
  // 	"assesmentItems": []
  {
    Header: "Ticket Number",
    accessor: "ticketNumber",
  },
  {
    Header: "Total Amount",
    accessor: "invoice.totalAmount",
  },
  // phone
  {
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Created By",
    accessor: "technician.userInfo.firstName",
  },
  {
    id: "status",
    Header: "Payment Status",
    accessor: "invoice.payments.status",
    render: (cell) => {
      return (
        <div className="flex flex-row space-x-2">
          <p className="text-sm ">{cell.value}</p>
        </div>
      );
    },
  },
];
