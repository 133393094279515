import React from "react";
import CurrentOrder from "./components/CurrentOrder";

export default function Order() {
  return (
    <div className="  rounded-xl  ">
      <CurrentOrder />
    </div>
  );
}
