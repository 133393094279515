import React from "react";
import Card from "ui/Card";
import FormGenerator from "components/form-generator/FormGenerator";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import { MultiSelect } from "react-multi-select-component";
import DataGrid from "components/data-grid/DataGrid";
import customAxios from "middleware/customAxios";
import { useNavigate } from "react-router-dom";
import { Popover } from "@headlessui/react";
import { FaCheck } from "react-icons/fa";

export default function CreateAssesment() {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let [selectedProducts, setSelectedProducts] = React.useState([]);
  let [customerQuery, setCustomerQuery] = React.useState("");
  let [customers, setCustomers] = React.useState([]);
  let [products, setProducts] = React.useState([]);
  let [data, setData] = React.useState([]);
  let [total, setTotal] = React.useState(0);
  let [selectedCustomer, setSelectedCustomer] = React.useState(null);
  let [globalSelectedCustomer, setGlobalSelectedCustomer] =
    React.useState(null);

  React.useEffect(() => {
    let urlParams = new URLSearchParams();
    urlParams.append("accountNumber", customerQuery);

    if (customerQuery.length > 3) {
      customAxios.get("/admin/customers?" + urlParams).then((res) => {
        setCustomers(res);
      });
    }
  }, [customerQuery]);

  React.useEffect(() => {
    setTotal(
      selectedProducts.reduce((acc, curr) => {
        return parseInt(acc) + parseInt(curr.price) * curr?.quantity ?? 1;
      }, 0)
    );
  }, [selectedProducts]);

  async function fetchSelectableProducts() {
    customAxios
      .get("/admin/assesment/fetch-services")
      .then((res) => {
        let data = res;
        setData(data);
        let products = data[0].productVariants?.map((product) => {
          return {
            price: product.price,
            value: product.id,
            label: product.name,
          };
        });
        setProducts(products);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  React.useEffect(() => {
    fetchSelectableProducts();
  }, []);

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "Create a new assesment",
      })
    );
    dispatch(setNavigationComponents(null));
    dispatch(
      setBreadCrumbs([
        {
          name: "Assesments",
          path: "/assements",
        },
        {
          name: "My Assesments",
          path: "/assements",
        },
      ])
    );
  });

  let serviceTypes = [{}];

  const formData = {
    contents: [
      {
        fields: [
          // Ticket Number
          {
            name: "ticketNumber",
            displayName: "Ticket Number",
            type: "field",
            placeholder: "Ticket Number",
            required: true,
            span: 1,
          },
          // Name
          {
            name: "name",
            displayName: "Name",
            type: "field",
            placeholder: "Name",
            required: true,
          },
          //   phone number
          {
            name: "phoneNumber",
            displayName: "Phone Number",
            type: "field",
            placeholder: "Phone Number",
            required: true,
          },
          {
            name: "idCardNumber",
            displayName: "ID Card Number",
            type: "field",
            placeholder: "ID Card Number",
            required: true,
          },
          {
            name: "relationToCustomer",
            displayName: "Relationship to Customer",
            type: "field",
            placeholder: "Relationship to Customer",
            required: true,
          },
          {
            name: "description",
            displayName: "Description",
            type: "editor",
            placeholder: "Description",
            required: false,
          },
        ],
      },
    ],
    initialValues: {
      ticketNumber: "",
      phoneNumber: "",
      idCardNumber: "",
      description: "",
      name: "",
      relationToCustomer: "",
    },
    validationSchema: Yup.object().shape({
      ticketNumber: Yup.string().required("Required"),
      phoneNumber: Yup.string().required("Required").min(7).max(7),
      idCardNumber: Yup.string().required("Required").min(7).max(7),
      description: Yup.string(),
      name: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      let data = {
        ticketNumber: values.ticketNumber,
        phoneNumber: values.phoneNumber,
        idCardNumber: values.idCardNumber,
        description: values.description,
        relationToCustomer: values.relationToCustomer,
        name: values.name,
        assesmentItems: selectedProducts.map((product) => {
          let productQuantity = product.quantity ?? 1;
          return {
            serviceId: product.value,
            quantity: product.quantity ?? 1,
            total: product.price * productQuantity,
          };
        }),
      };

      if (selectedCustomer) {
        data.customerId = selectedCustomer.id;
      } else {
        alert("Please select a customer");
        return;
      }

      customAxios
        .post("/admin/assesment/", data)
        .then((res) => {
          alert("Assesment created successfully");
          setSelectedProducts([]);
          navigate("/assesments/view/" + res.id);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  };

  return (
    <div className="absolute flex flex-col space-y-3  align-top  min-h-[calc(100vh-120px)] rounded-xl top-0 w-full pb-10 bg-slate-50">
      <Card heading="Create Assesment" description={"Create a new assesment"}>
        <Popover className={"relative focus:outline-none "}>
          {({ open, close }) => (
            <>
              <Popover.Button
                className={`w-full ${
                  open ? "" : "text-opacity-70"
                } flex flex-row py-2`}
              >
                <div className="flex flex-row space-x-3 items-center w-full">
                  <div className="flex flex-col border bg-white border-slate-300 w-full p-2 rounded-md space-y-1">
                    <h1 className="text-sm text-start ">
                      {selectedCustomer?.name ? (
                        <div className=" space-x-3 text-sm px-2 flex flex-row items-center ">
                          <p className=" ">Customer:</p>
                          <p className="text-slate-600">
                            {selectedCustomer?.name}
                          </p>
                        </div>
                      ) : (
                        <p className="text-slate-600 text-sm px-4 ">
                          Please Select a Customer
                        </p>
                      )}
                    </h1>
                  </div>
                </div>
              </Popover.Button>
              <Popover.Panel className="absolute z-10 w-full px-4 top-14 -right-0">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-2 w-inherit bg-white  p-2 ">
                    <div className="flex flex-row space-x-3 items-center">
                      <div className="flex text-sm flex-col space-y-2 w-full">
                        <input
                          className="w-full border border-slate-300 rounded-md p-2"
                          autoFocus={true}
                          placeholder="Search Customer"
                          value={customerQuery}
                          type="number"
                          onChange={(e) => {
                            if (e.target.value.length > 6) return;
                            setCustomerQuery(e.target.value);
                          }}
                          maxLength={6}
                        />
                        <div className="flex flex-col text-sm space-y-2">
                          <p>Results</p>
                          {customers.length === 0 ? (
                            <p className="text-yellow-600">No Customer Found</p>
                          ) : (
                            <>
                              {customers?.map((customer) => {
                                return (
                                  <button
                                    onClick={() => {
                                      setSelectedCustomer(customer);
                                      dispatch(
                                        setGlobalSelectedCustomer(customer)
                                      );
                                      close();
                                    }}
                                    className={`flex flex-row  hover:bg-white items-center space-x-4 p-2 rounded-xl ${
                                      selectedCustomer?.id == customer?.id
                                        ? "bg-white border-2 border-slate-400"
                                        : ""
                                    }`}
                                  >
                                    {selectedCustomer?.id === customer?.id ? (
                                      <FaCheck className="h-6 w-6 text-yellow-500" />
                                    ) : (
                                      <div className="h-6 w-6"></div>
                                    )}

                                    <p className="text-sm font-medium text-slate-900">
                                      {customer?.name} -{" "}
                                      {customer?.accountNumber}
                                    </p>
                                  </button>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </>
          )}
        </Popover>
        <FormGenerator
          heading="Add Product"
          grids={1}
          modal={true}
          buttonSubmit={{ title: "Submit" }}
          formData={formData}
        />
      </Card>
      <Card
        heading="Selected Services"
        description={"Please select the services provided to the customer"}
      >
        <div className="space-y-3">
          <h1 className="text-sm text-slate-600">Select Services</h1>
          <div className="space-y-3">
            <MultiSelect
              options={products || []}
              value={selectedProducts}
              onChange={setSelectedProducts}
              labelledBy={"Select"}
              placeholder={"Select Users"}
            />
            <DataGrid
              cellFunction={(cell) => {
                if (cell.column.id === "quantity") {
                  return (
                    <input
                      type="number"
                      min={0}
                      defaultValue={1}
                      className="w-min p-1 rounded-xl border"
                      value={cell.value}
                      onChange={(e) => {
                        let value = e.target.value;
                        let newSelectedProducts = [...selectedProducts];
                        newSelectedProducts[cell.row.index].quantity = value;
                        setSelectedProducts(newSelectedProducts);
                        if (value < 0) {
                          alert("Quantity cannot be less than 0");
                          return;
                        } else if (value === 0) {
                          newSelectedProducts.splice(cell.row.index, 1);
                          setSelectedProducts(newSelectedProducts);
                        }
                      }}
                    />
                  );
                }

                return cell.value;
              }}
              x_columns={[
                {
                  Header: "Service name",
                  accessor: "label",
                },
                {
                  Header: "Price",
                  accessor: "price",
                },
                // {
                //   Header: "Quantity",
                //   accessor: "quantity",
                // },
              ]}
              testData={selectedProducts || []}
              showHeader={true}
            />
          </div>
        </div>
      </Card>
    </div>
  );
}
