import { Line } from "react-chartjs-2";
import ChartContainer from "../charts-container/ChartsContainer";
import { normaliseDatasets } from "../HelperFunctions";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default function LineChart({ title, labels, datasets, className }) {
  const normalisedDatasets = normaliseDatasets(datasets);

  const dataConfig = {
    labels: labels,
    datasets: normalisedDatasets,
    borderColor: "#34d399",
    backgroundColor: "#0d9488",
    fill: true,
  };

  const options = {
    plugins: {
      legend: datasets.length > 1,
    },
    title: {
      display: true,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  return <Line data={dataConfig} options={options} />;
}

LineChart.defaultProps = {
  title: "",
  labels: [],
  datasets: [],
  className: "",
};
