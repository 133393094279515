import { Disclosure, Popover, Tab, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { IconContext } from "react-icons";
import {
  FaDashcube,
  FaUserTie,
  FaSearch,
  FaUser,
  FaSearchLocation,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  LogoutIcon,
} from "@heroicons/react/solid";
import { CogIcon, CubeIcon, RssIcon } from "@heroicons/react/outline";
import {
  FilmIcon,
  UserGroupIcon,
  UserIcon,
  ChartBarIcon,
} from "@heroicons/react/solid";
import { HomeIcon, TagIcon, ViewListIcon } from "@heroicons/react/solid";
import { FaWarehouse } from "react-icons/fa";
import { MdHelp, MdWarehouse } from "react-icons/md";
import PrimaryButton from "ui/buttons/PrimaryButton";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SideMenu() {
  let open = false;
  let navigate = useNavigate();
  let current = useSelector((state) => state.navigation.current);
  let dispatch = useDispatch();
  let userData = useSelector((state) => state.data.userData);
  let permissions = userData.permissions;

  let path = window.location.pathname;

  const menuItems = [
    {
      name: "Home",
      icon: <HomeIcon className="w-4 h-4" />,
      path: "/home",
    },
    {
      name: "Warehouses",
      icon: <FaWarehouse className="w-4 h-4" />,
      path: "/outlets",
      permissions: ["r-inventory"],
    },
    {
      name: "Assesments",
      icon: <FaSearchLocation className="w-4 h-4" />,
      path: "/assesments",
      permissions: ["r-asses"],
    },
    {
      name: "Products",
      icon: <ViewListIcon className="w-4 h-4" />,
      permissions: ["r-product"],
      subMenu: [
        {
          id: 1,
          name: "All Products",
          path: "/products/all",
          permissions: ["r-product"],
        },
        {
          id: 2,
          name: "Serialized Products",
          path: "/products/serialized-products",
          permissions: ["r-product"],
        },
        {
          id: 3,
          name: "Collections",
          path: "/products/categories",
          permissions: ["r-product"],
        },
        {
          id: 4,
          name: "Stock Adjustments",
          path: "/products/adjustments",
          permissions: ["r-inventory"],
        },
        {
          id: 5,
          name: "Migrations",
          path: "/products/migrations",
          permissions: ["w-inventory"],
        },
      ],
    },
    {
      name: "Order Requests",
      icon: <CubeIcon className="w-4 h-4" />,
      path: "/order-requests",
      permissions: ["r-order-request"],
    },
    {
      name: "Sales",
      icon: <TagIcon className="w-4 h-4" />,
      path: "/sales",
      permissions: ["r-sales"],
    },
    {
      name: "Discounts",
      icon: <FilmIcon className="w-4 h-4" />,
      path: "/discounts",
      permissions: ["r-discount"],
    },
    {
      name: "Users",
      icon: <UserIcon className="w-4 h-4" />,
      path: "/users",
      permissions: ["r-usr"],
    },
    {
      name: "Analytics",
      icon: <ChartBarIcon className="w-4 h-4" />,
      path: "/analytics",
      permissions: ["r-report"],
    },
    {
      name: "Settings",
      icon: <CogIcon className="w-4 h-4" />,
      permissions: ["r-role", "w-role"],
      subMenu: [
        {
          id: 1,
          name: "General",
          path: "/settings/general",
          permissions: ["w-role"],
        },
        {
          id: 2,
          name: "Role Management",
          path: "/settings/role-management",
          permissions: ["r-role"],
        },
      ],
    },
  ];

  async function checkOpen(item) {
    if (current?.name === item?.name) {
      return true;
    } else {
      return false;
    }
  }

  React.useEffect(() => {
    console.log(current);
  }, [permissions]);
  return (
    <div className="h-full  justify-between  relative  bg-gradient-to-b from-slate-100 to-slate-300  py-  border-r border-slate-300  flex flex-col bg-sla  space-y-6  items-start">
      {/* Menu Items */}
      <div className="flex  flex-col relative justify-between  w-full h-fit mb-10 p-5">
        <Link
          to="/home"
          className="flex  space-x-4 justify-start items-center p-4  "
        >
          <h1 className="text-slate-800 font-bold text-xl italic">
            Medianet <span className="italic font-extralight">SIMS </span>
          </h1>
        </Link>
        <div className="py-2">
          {permissions?.length > 0 && (
            <div>
              {permissions[0]?.includes("r-sales") && (
                <div
                  onClick={() => {
                    navigate("/pos");
                  }}
                  className="w-full  hover:brightness-110 cursor-pointer bg-gradient-to-r transition-all duration-100 from-blue-500 to-orange-400 via-red-500  rounded-lg flex flex-row space-x-3 items-center text-white font-bold justify-center p-1.5 mb-5"
                >
                  <CubeIcon className="w-5 text-sm h-5 font-medium  " />
                  <p className="text-[10px]">MDN POS</p>
                </div>
              )}
            </div>
          )}

          <div className="flex flex-col space-y-2 mt-5">
            {menuItems.map((item, i) => {
              return (
                <div>
                  {permissions?.length > 0 && (
                    <div key={i}>
                      {item.permissions?.length > 0 ? (
                        <div className=" ">
                          {item.permissions?.some((r) =>
                            permissions[0]?.includes(r)
                          ) && (
                            <Disclosure key={i}>
                              {({ open }) => (
                                <>
                                  <Disclosure.Button
                                    onClick={() =>
                                      item.path ? navigate(item.path) : ""
                                    }
                                    className={`${
                                      path == item.path
                                        ? "bg-slate-800 text-white"
                                        : "text-slate-800 hover:bg-slate-300/20 "
                                    } flex  flex-row my-0  justify-between rounded-md  text-md focus:outline-none w-full items-center space-x-4 p-2  hover:cursor-pointer `}
                                  >
                                    <div className="flex flex-row justify-start items-center pr-4 space-x-3 0">
                                      <div className="p-1  rounded-lg   0">
                                        {item.icon}
                                      </div>
                                      <p
                                        className={`text-sm ${
                                          open ? "font-normal" : ""
                                        }`}
                                      >
                                        {item.name}
                                      </p>
                                    </div>
                                    {item.subMenu && (
                                      <div className="flex flex-row  justify-end items-center space-x-2">
                                        <IconContext.Provider
                                          value={{
                                            className:
                                              "text-slate-400 dark:text-slate-100",
                                          }}
                                        >
                                          <div className="flex justify-center items-center">
                                            <ChevronUpIcon
                                              className={classNames(
                                                open
                                                  ? "transform rotate-180"
                                                  : "",
                                                "w-5 h-5"
                                              )}
                                              aria-hidden="true"
                                            />
                                          </div>
                                        </IconContext.Provider>
                                      </div>
                                    )}
                                  </Disclosure.Button>
                                  <Disclosure.Panel className="space-y-2">
                                    {item.subMenu?.map((subItem, i) => {
                                      return (
                                        <Link
                                          to={subItem.path}
                                          key={i}
                                          className="w-full pt-3 "
                                        >
                                          <Tab.Group>
                                            <Tab
                                              className={({ selected }) =>
                                                classNames(
                                                  "flex flex-row pt-2 justify-start pl-10 py-1 rounded-md text-slate-800 text-md focus:outline-none w-full items-center space-x-4  hover:cursor-pointer hover:text-yellow-500"
                                                )
                                              }
                                              key={i}
                                              onClick={() =>
                                                navigate(subItem.path ?? "#")
                                              }
                                            >
                                              <p className="text-xs">
                                                {subItem?.name}
                                              </p>
                                            </Tab>
                                          </Tab.Group>
                                        </Link>
                                      );
                                    })}
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          )}
                        </div>
                      ) : (
                        <Disclosure defaultOpen={checkOpen(item)} key={i}>
                          {({ open }) => (
                            <div className=" ">
                              <Disclosure.Button
                                onClick={() =>
                                  item.path ? navigate(item.path) : ""
                                }
                                className={`${
                                  path == item.path
                                    ? "bg-slate-800 text-white"
                                    : "text-slate-800 hover:bg-slate-300/20 "
                                } flex  flex-row my-0  justify-between rounded-md  text-md focus:outline-none w-full items-center space-x-4 p-1  hover:cursor-pointer `}
                              >
                                <div className="flex flex-row justify-start items-center pr-4 space-x-3 ">
                                  <div className="p-1  rounded-lg    b">
                                    {item.icon}
                                  </div>
                                  <p
                                    className={`text-sm ${
                                      open ? "font-bold" : ""
                                    }`}
                                  >
                                    {item.name}
                                  </p>
                                </div>
                                {item.subMenu && (
                                  <div className="flex flex-row justify-end items-center space-x-2">
                                    <IconContext.Provider
                                      value={{
                                        className: "text-slate-800 ",
                                      }}
                                    >
                                      <div className="flex justify-center items-center">
                                        <ChevronUpIcon
                                          className={classNames(
                                            open ? "transform rotate-180" : "",
                                            "w-5 h-5"
                                          )}
                                          aria-hidden="true"
                                        />
                                      </div>
                                    </IconContext.Provider>
                                  </div>
                                )}
                              </Disclosure.Button>
                              <Disclosure.Panel className=" ">
                                {item.subMenu?.map((subItem, i) => {
                                  return (
                                    <Link
                                      to={subItem.path}
                                      key={i}
                                      className="w-full "
                                    >
                                      <Tab.Group>
                                        <Tab
                                          className={({ selected }) =>
                                            classNames(
                                              "flex flex-row justify-start py-3 pl-12 rounded-md text-slate-800 text-md focus:outline-none w-full items-center space-x-4  hover:cursor-pointer hover:text-yellow-500"
                                            )
                                          }
                                          key={i}
                                          onClick={() =>
                                            navigate(subItem.path ?? "#")
                                          }
                                        >
                                          <p className="text-sm">
                                            {subItem?.name}
                                          </p>
                                        </Tab>
                                      </Tab.Group>
                                    </Link>
                                  );
                                })}
                              </Disclosure.Panel>
                            </div>
                          )}
                        </Disclosure>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex w-full justify-between items-center bg-blend-saturation bg-clip-padding backdrop-filter duration-0 backdrop-blur-sm bg-opacity-50 border border-slate-300 bg-slate-200  transition-opacity p-5">
        <p className="font-medium">
          {userData.name} {userData.surname}
        </p>
        <div>
          {/* logout button */}
          <button
            onClick={() => {
              localStorage.clear();
              navigate("/auth/login");
            }}
            className="px-4 bg-gradient-to-b from-white to-slate-100 border border-slate-200   p-1.5 w-fit text-sm flex items-center justify-center text-slate-800 dark:text-slate-700 font-medium rounded-xl  hover:brightness-95"
          >
            <p>Log out</p>
          </button>
        </div>
      </div>
    </div>
  );
}
