import PermissionError from "components/permission-error/PermissionError";
import MainLayout from "layout/MainLayout";
import React from "react";

export default function NoAccess() {
  return (
    <div>
      <PermissionError />
    </div>
  );
}
