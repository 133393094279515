import DataGrid from "components/data-grid/DataGrid";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { columns } from "./columns";
import { useNavigate } from "react-router-dom";
import DropDownButton from "ui/buttons/DropDownButton";
import { FaEdit } from "react-icons/fa";
import { PencilAltIcon, XIcon } from "@heroicons/react/outline";
import Card from "ui/Card";

export default function UsersIndex() {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "Users",
      })
    );
    dispatch(
      setNavigationComponents(
        <PrimaryButton
          title="Add User"
          onClick={() => navigate("/users/add")}
        />
      )
    );
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/home",
        },
        {
          name: "Users",
          path: "/users",
        },
      ])
    );
  }, []);

  const cellFunction = (cell) => {
    if (cell.column.id === "name") {
      return (
        cell.row.original.userInfo?.firstName +
        " " +
        cell.row.original.userInfo?.lastName
      );
    } else if (cell.column.id === "username") {
      return (
        <div
          onClick={() => {
            navigate("/products/categories/view/" + cell.row.original.id, {
              state: cell.row.original,
            });
          }}
          className="flex flex-row items-center space-x-3 cursor-pointer"
        >
          <p className="hover:underline">{cell.value}</p>
        </div>
      );
    }
    if (cell.column.id === "actions") {
      return (
        <DropDownButton
          items={[
            {
              title: "Edit",
              icon: <PencilAltIcon className="h-5 w-5" />,
              path: "/users/edit/" + cell.row.original.id,
              state: cell.row.original,
            },
            {
              title: "Delete",
              icon: <XIcon className="h-5 w-5" />,
              path: "/users/delete/" + cell.row.original.id,
              state: cell.row.original,
            },
          ]}
          title="Actions"
          icon={<i className="fas fa-ellipsis-v"></i>}
          className=""
          setSelected={(item) => {
            console.log(item);
          }}
          path="/users/"
        />
      );
    }
    if (cell.column.id === "roles") {
      return (
        <div className="max-w-sm">
          {cell.row.original.roles.map((role) => {
            return (
              <Link className="inline-flex items-center px-2.5 py-1 m-1 rounded-full text-[10px] font-medium bg-gray-100 text-gray-800 mr-1">
                {role?.role.name}
              </Link>
            );
          })}
        </div>
      );
    } else if (cell.column.id === "userGroups") {
      return (
        <div className="w-sm max-w-sm">
          {cell.row.original.userGroups.map((ug) => {
            return (
              <Link
                to={"/outlets/view/" + ug?.group?.id}
                className="inline-flex border hover:border-slate-500 items-center px-2.5 py-1 m-1 rounded-full text-[10px] font-medium bg-gray-100 text-gray-800 mr-1"
              >
                {ug?.group?.name}
              </Link>
            );
          })}
        </div>
      );
    }
    return cell.value;
  };

  return (
    <div className="relative">
      <Card
        heading={"All Users"}
        description={"Each user has multiple roles and Inventories"}
      >
        <DataGrid
          searchable={true}
          x_columns={columns}
          api="/admin/users"
          showHeader={true}
          cellFunction={cellFunction}
        />
      </Card>

      <Outlet />
    </div>
  );
}
