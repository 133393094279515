import DataGrid from "components/data-grid/DataGrid";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { columns } from "./columns";
import { useNavigate } from "react-router-dom";
import DropDownButton from "ui/buttons/DropDownButton";
import { FaEdit, FaHistory, FaSyncAlt, FaUserCog } from "react-icons/fa";
import { PencilAltIcon, XIcon } from "@heroicons/react/outline";
import Card from "ui/Card";
import moment from "moment";
import { CogIcon, UserAddIcon } from "@heroicons/react/solid";
import {
  MdCrisisAlert,
  MdHeartBroken,
  MdHistory,
  MdSyncAlt,
} from "react-icons/md";

export default function SerializedProducts() {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "Serialized Products",
      })
    );
    dispatch(
      setNavigationComponents(
        <PrimaryButton
          title="Add Serialized Product"
          onClick={() => navigate("/products/serialized-products/add")}
        />
      )
    );
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Products",
          path: "/products",
        },
        {
          name: "Serialized Products",
          path: "/products/serialized-products/add",
        },
      ])
    );
  }, []);

  const cellFunction = (cell) => {
    if (cell.column.id === "name") {
      return (
        <div
          onClick={() => {
            navigate("/products/categories/view/" + cell.row.original.id, {
              state: cell.row.original,
            });
          }}
          className="flex flex-row items-center space-x-3 cursor-pointer"
        >
          <p className="hover:underline font-bold">{cell.value}</p>
        </div>
      );
    }
    if (cell.column.id === "customer?.name") {
      return cell.row.original.customer?.name ?? "N/A";
    }
    if (cell.column.id === "status") {
      switch (cell.value) {
        case "available":
          return (
            <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-green-100 text-green-800">
              Available
            </span>
          );
        case "sold":
          return (
            <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-red-100 text-red-800">
              Sold
            </span>
          );
        default:
          return (
            <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-fuchsia-200 text-fuchsia-800">
              Returned
            </span>
          );
          break;
      }
    }
    if (cell.column.id === "isAssigned") {
      return cell.value ? (
        <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-green-100 text-green-800">
          Yes
        </span>
      ) : (
        <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-red-100 text-red-800">
          No
        </span>
      );
    }
    if (cell.column.id === "assignType") {
      if (cell.row.original.isNewConnection) {
        return (
          <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-blue-100 text-blue-800">
            New
          </span>
        );
      } else if (cell.row.original.isReplacement) {
        return (
          <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-yellow-100 text-yellow-800">
            Replacement
          </span>
        );
      } else if (cell.row.original.isMigration) {
        return (
          <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-pink-100 text-pink-800">
            Migration
          </span>
        );
      } else {
        return (
          <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-gray-100 text-gray-800">
            N/A
          </span>
        );
      }
    }
    if (cell.column.id === "actions") {
      return (
        <DropDownButton
          items={[
            {
              title: "View History",
              icon: <MdHistory className="h-5 w-5" />,
              path:
                "/products/serialized-products/view/" + cell.row.original.id,
              state: cell.row.original,
            },
            {
              title: "Sync Card",
              icon: <MdSyncAlt className="h-5 w-5" />,
              path:
                "/products/serialized-products/sync-card/" +
                cell.row.original.id,
              state: cell.row.original,
            },
            {
              title: "Report Faulty",
              icon: <MdCrisisAlert className="h-5 w-5" />,
              disabled: true,
              path:
                "/products/serialized-products/assign-customer/" +
                cell.row.original.id,
              state: cell.row.original,
            },
            {
              title: "Assign to Technician",
              icon: <FaUserCog className="h-5 w-5" />,
              path:
                "/products/serialized-products/assign-technician/" +
                cell.row.original.id,
              state: cell.row.original,
            },
            {
              title: "Refurbish",
              icon: <CogIcon className="h-5 w-5" />,
              path:
                "/products/serialized-products/refurbish/" +
                cell.row.original.id,

              state: cell.row.original,
            },
          ]}
          title="Actions"
          icon={<i className="fas fa-ellipsis-v"></i>}
          className=""
          setSelected={(item) => {
            console.log(item);
          }}
          path="/products/categories/"
        />
      );
    }
    if (cell.column.id === "createdAt" || cell.column.id === "updatedAt") {
      return moment(cell.value).format("DD MMMM YYYY");
    }
    return cell.value;
  };

  return (
    <div className="w-full relative ">
      <Card
        heading={"All Serialized Products"}
        description={"This is a list of all serialized products in the system."}
      >
        <DataGrid
          searchable={true}
          search={true}
          x_columns={columns}
          api="/admin/serialized-products"
          showHeader={true}
          cellFunction={(cell) => cellFunction(cell)}
          height={"h-[calc(100vh-230px)]"}
        />
      </Card>
      <Outlet />
    </div>
  );
}
