import dayjs from "dayjs"

export default function YearSelect({handleYearSelect, changeYear, selectedDate}) {
    let year = selectedDate.year();
    let years = []
    for (let i = 0; i < 9; i++) {
        years.push(year)
        year = year + 1
    }
    return (
        <>
            <div className="grid grid-cols-3 grid-rows-3 gap-x-1 gap-y-1">
                {
                    years.map((year) =>
                        <div onClick={ () => {
                            handleYearSelect();
                            changeYear(year)
                        }} className="text-center hover:bg-orange-50 cursor-pointer p-3">{year}</div>
                    )
                }
            </div>
        </>
    )
}

YearSelect.defaultProps = {
    selectedDate: dayjs()
}