import DataGrid from "components/data-grid/DataGrid";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import { itemRender } from "functions/ItemRender";
import customAxios from "middleware/customAxios";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";
import SecondaryButton from "ui/buttons/SecondaryButton";
import Card from "ui/Card";

let productData = {
  id: 1,
  name: "HDMI Cable",
  description: "HDMI Cable",
  image: "https://m.media-amazon.com/images/I/61HwpDbhcBL.jpg",
  variants: [
    {
      id: 1,
      title: "HDMI Cable 1",
      price: 100,
      stock: 10,
    },
    {
      id: 2,
      title: "HDMI Cable 2",
      price: 200,
      stock: 20,
    },
  ],
};

export default function ProductView() {
  let dispatch = useDispatch();
  let { id } = useParams();
  let [data, setData] = React.useState([]);
  let navigate = useNavigate();

  let userData = useSelector((state) => state.data.userData);
  let permissions = userData.permissions;

  React.useEffect(() => {
    if (permissions && permissions[0].length > 0) {
      if (!permissions[0].includes("r-product")) {
        navigate("/401");
      }
    }
  }, []);

  React.useEffect(() => {
    customAxios.get(`/admin/product/${id}`).then((res) => {
      setData(res.data);
    });
  }, []);

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: data?.name,
      })
    );

    dispatch(
      setBreadCrumbs([
        {
          name: "Products",
          link: "/products/all",
        },
        {
          name: data?.name,
          link: `/products/${data.id}`,
        },
      ])
    );
    dispatch(
      setNavigationComponents(
        <div className="flex space-x-2">
          {permissions && permissions[0].includes("u-product") && (
            <PrimaryButton
              title="Edit"
              onClick={() => {
                navigate(`/products/edit/${id}`);
              }}
            />
          )}
        </div>
      )
    );
  }, [data]);

  let cellFunction = (cell) => {
    if (cell.column.id == "name") {
      return (
        <div
          className="flex flex-row space-x-4 items-center"
          onClick={() => {
            console.log(cell.row.original);
          }}
        >
          <img
            src={process.env.REACT_APP_SERVER_URL + cell.row.original.image}
            className="w-10 h-10 object-cover"
          />
          <div className="text-sm text-slate-800 dark:text-slate-200">
            {cell.value}
          </div>
        </div>
      );
    }
    return (
      <div
        className="flex flex-col space-y-2"
        onClick={() => {
          console.log(cell.row.original);
        }}
      >
        <div className="text-sm text-slate-800 dark:text-slate-200">
          {cell.value}
        </div>
      </div>
    );
  };

  return (
    <div className="grid grid-cols-1 gap-3">
      <Card heading={"Product Information"}>
        <div className="grid grid-cols-4 gap-3">
          {itemRender("Name", data?.name)}
          {itemRender("Description", data?.description)}
          {itemRender("Added On", moment(data?.created_at).format("lll"))}
          {itemRender("Added By", data?.createdByUser?.username ?? "-")}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Categories
          </label>
          <div className="mt-2">
            <div className="flex flex-wrap">
              {data?.categories?.map((category) => {
                return (
                  <span
                    key={category.id}
                    className="inline-flex items-center px-2.5 py-1 rounded-full text-sm font-medium bg-blue-100 text-slate-800 mr-2"
                  >
                    {category?.ProductGroup?.name}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </Card>
      <Card heading={"Product Variants"}>
        <DataGrid
          x_columns={[
            {
              Header: "Name",
              accessor: "name",
            },
            {
              Header: "Code",
              accessor: "code",
            },
            {
              Header: "SKU",
              accessor: "sku",
            },
            {
              Header: "Price",
              accessor: "price",
            },
            {
              Header: "Default Alert Quantity",
              accessor: "alertQuantity",
            },
          ]}
          testData={data?.variants ?? []}
          showHeader={true}
          cellFunction={(cell) => cellFunction(cell)}
        />
      </Card>
    </div>
  );
}
