import { Bar } from "react-chartjs-2";
import ChartContainer from "../charts-container/ChartsContainer";
import { normaliseDatasets } from "../HelperFunctions";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables)

export default function BarChart ({title, labels, datasets, className}) {
    const normalisedDatasets = normaliseDatasets(datasets);

    const dataConfig = {
        datasets: normalisedDatasets,
        labels: labels
    }

    const options = {
        plugins: {
            legend: datasets.length > 1
        },
        title: {
            display: true,
        },
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: false
                }
            }
        }
    };

    return (
        <Bar data={dataConfig} options={options} />
    )
}

BarChart.defaultProps = {
    label: "",
    labels: [], 
    title: "",
    className: "",
    datasets: []
}