const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  items: [],
  totalDiscount: 0,
  total: 0,
  totalItems: 0,
  isCartOpen: false,
  selectedCustomer: {},
  assignType: "",
  selectedSerializedProduct: {},
  currentSale: {},
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addDiscountToItem: (state, action) => {
      let item = action.payload;
      console.log(item);
    },
    setCurrentSale: (state, action) => {
      state.currentSale = action.payload;
    },

    addToCart: (state, action) => {
      let item = action.payload;

      let existingItem = state.items.find(
        (cartItem) => cartItem.id === item.id
      );

      if (existingItem) {
        existingItem.quantity += 1;
        existingItem.price = existingItem.unitPrice * existingItem.quantity;

        if (existingItem.appliedDiscount) {
          let discountType = existingItem.appliedDiscount.fixedAmount
            ? "fixed"
            : "percentage";
          let discount = 0;

          if (discountType === "fixed") {
            discount = existingItem.appliedDiscount.fixedAmount;
          }

          if (discountType === "percentage") {
            discount =
              (existingItem.appliedDiscount.percentage / 100) *
              existingItem.unitPrice;
          }
          existingItem.price = existingItem.quantity * existingItem.unitPrice;

          existingItem.discount += discount;

          existingItem.discountPrice =
            existingItem.quantity * existingItem.unitPrice -
            existingItem.discount;
        }
      } else {
        state.items.push({
          ...item,
          quantity: 1,
          price: item.price,
        });
      }

      // calculate total items
      state.totalItems = state.items.reduce((total, item) => {
        return total + item.quantity;
      }, 0);

      // calculate total price
      state.total = state.items.reduce((total, item) => {
        return total + parseInt(item.price);
      }, 0);

      state.totalDiscount = state.items.reduce((total, item) => {
        return total + parseInt(item.discount ?? 0);
      }, 0);

      // rearrange items in cart newest first
      state.items = state.items.sort((a, b) => {
        return b.id - a.id;
      });

      localStorage.setItem("cart", JSON.stringify(state));
    },
    removeFromCart: (state, action) => {
      let item = action.payload;

      console.log(item);
      // Check if item is already in cart
      let existingItem = state.items.find(
        (cartItem) => cartItem.id === item.id
      );

      if (existingItem) {
        existingItem.quantity -= 1;
        existingItem.price = existingItem.unitPrice * existingItem.quantity;
      } else {
        state.items.push({
          ...item,
          quantity: 1,
          price: item.price,
        });
      }

      // remove if quantity is 0
      if (existingItem.quantity === 0) {
        state.items = state.items.filter((cartItem) => {
          return cartItem.id !== item.id;
        });
      }

      // calculate total items
      state.totalItems = state.items.reduce((total, item) => {
        return total + item.quantity;
      }, 0);

      // calculate total price
      state.total = state.items.reduce((total, item) => {
        return total + parseInt(item.price);
      }, 0);

      state.totalDiscount = state.items.reduce((total, item) => {
        return total + parseInt(item.discount ?? 0);
      }, 0);

      // rearrange items in cart newest first
      state.items = state.items.sort((a, b) => {
        return b.id - a.id;
      });

      localStorage.setItem("cart", JSON.stringify(state));
    },
    addDiscountToItem: (state, action) => {
      let item = action.payload;
      // Check if item is already in cart
      let existingItem = state.items.find(
        (cartItem) => cartItem.id === item.id
      );

      let discountType = item.appliedDiscount.fixedAmount
        ? "fixed"
        : "percentage";

      let discount = 0;

      if (discountType === "fixed") {
        discount = item.appliedDiscount.fixedAmount * item.quantity;
      }

      if (discountType === "percentage") {
        discount =
          (item.appliedDiscount.percentage / 100) *
          (item.quantity * item.unitPrice);
      }

      item.price = item.quantity * item.unitPrice;
      item.discountPrice = item.quantity * item.unitPrice - discount;
      item.discount = discount;
      item.isSerialized = existingItem.isSerialized;

      // replace item with new item
      state.items = state.items.map((cartItem) => {
        if (cartItem.id === item.id) {
          return item;
        }
        return cartItem;
      });

      state.total = state.items.reduce((total, item) => {
        return total + parseInt(item.price);
      }, 0);

      state.totalDiscount = state.items.reduce((total, item) => {
        return total + parseInt(item.discount);
      }, 0);
    },
    clearCart: (state, action) => {
      state.items = [];
      state.totalItems = 0;
      state.total = 0;
      state.totalDiscount = 0;

      localStorage.setItem("cart", JSON.stringify(state));
    },
    setGlobalSelectedCustomer: (state, action) => {
      state.selectedCustomer = action.payload;
    },
    setGlobalAssignType: (state, action) => {
      state.assignType = action.payload;
    },
    setGlobalSelectedSerializedProduct: (state, action) => {
      state.selectedSerializedProduct = action.payload;
    },
  },
});

export const {
  addToCart,
  clearCart,
  removeFromCart,
  addDiscountToItem,
  setGlobalSelectedCustomer,
  setGlobalAssignType,
  setGlobalSelectedSerializedProduct,
  setCurrentSale,
} = cartSlice.actions;

export default cartSlice.reducer;
