import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import jwtDecode from "jwt-decode";
import axios from "axios";
import customAxios from "middleware/customAxios";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedInventory } from "features/data/DataSlice";

export default function Login() {
  let navigate = useNavigate();

  const [submitted, setSubmitted] = React.useState(false);
  const [error, setError] = React.useState();
  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    setSubmitted(true);
    try {
      axios.post(process.env.REACT_APP_AXIOS_URL + "/auth/login", values).then(
        (res) => {
          localStorage.setItem("token", res.data.token);
          setSubmitted(false);

          resetForm();
          navigate("/home");
        },
        (err) => {
          console.log(err);
          setError(err.response.data.message);
          setSubmitted(false);
        }
      );
    } catch (error) {
      console.log(error);
      setError(error.data.message);
    }
  };

  React.useEffect(() => {
    // checkAuthStatus();
  }, []);

  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center bg-gradient-to-tr from-blue-300 to-orange-200 via-red-300 ">
      <div className=" flex max-w-xl md:max-w-2xl w-full  justify-center items-center ">
        <div className="max-w-lg w-full flex flex-col space-y-3 justify-center  items-center  p-5 shadow-xl rounded-xl bg-white py-10">
          <Link
            to="/home"
            className="flex space-x-4 justify-center flex-col space-y-3 w-full items-center p-4  "
          >
            <img
              src={"https://medianet.mv/img/medianet_logo.png"}
              alt="logo"
              className="w-12 h-12  rounded-md"
            />
            <h1 className="text-black font-bold text-2xl italic">
              Medianet <span className="italic font-extralight">SIMS </span>
            </h1>
          </Link>

          <h1 className="text-2xl font-semibold text-center">Login</h1>
          {/* Lottie files */}
          {/* <div className="flex justify-center items-center">
            <Lottie
              loop
              animationData={OfficeAnimation}
              play
              style={{ width: 200, height: 200 }}
            />
          </div> */}

          <h5 className="text-sm text-gray-600 text-center">
            Please enter the following information to login
          </h5>
          {/* Error and Success Server Message */}
          {error && (
            <div className="w-full pb-2">
              {/* Error and Success Server Message */}
              {error && (
                <div
                  className={`w-full ${
                    error == null ? "bg-success" : "bg-red-100"
                  } p-2 rounded-xl`}
                >
                  <div className="flex flex-row space-x-3 justify-start items-center">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-10 h-10 text-red-800"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                    <div className="flex-col justify-start items-start text-left">
                      <h1 className="text-sm font-medium">
                        {error ? "Error" : "Successful"}
                      </h1>
                      <div className="text-sm text-grey-900">{error}</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <FormikProvider value={formik}>
            <Form className="flex flex-col space-y-3 w-full md:w-[23vw]">
              <div className="flex flex-col space-y-3 px-10">
                {/* <div className="flex flex-col space-y-1 w-1/3">
                  <label style={{ fontSize: 10 }}>Tenant ID</label>
                  <Field
                    name="tenantId"
                    type="text"
                    className="form-control text-md focus:outline-none  border p-1 px-2 border-gray-200 "
                  />
                  <ErrorMessage
                    name="tenantId"
                    component="div"
                    className="text-red-600 text-md"
                  />
                </div> */}
                <div className="flex flex-col space-y-2 w-full">
                  <label style={{ fontSize: 10 }}>Username</label>
                  <Field
                    name="username"
                    type="text"
                    className="form-control text-sm focus:outline-none rounded-md my-1  border p-1 px-2 border-gray-200 "
                  />
                  <ErrorMessage
                    name="username"
                    component="div"
                    className="text-red-600 text-sm capitalize"
                  />
                </div>
                <div className="flex flex-col space-y-2 w-full">
                  <label style={{ fontSize: 10 }}>Password</label>
                  <Field
                    name="password"
                    type="password"
                    className="form-control text-sm focus:outline-none rounded-md my-1  border p-1 px-2 border-gray-200 "
                  />
                  {/* show password */}

                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-red-600 text-sm capitalize"
                  />
                </div>
              </div>
              <div className="flex items-center justify-center">
                {submitted ? (
                  <button
                    type="button"
                    className=" flex flex-row justify-between text-slate-600 text-md font-medium p-2 px-6 rounded-md cursor-not-allowed"
                  >
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-slate-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v1a7 7 0 00-7 7h1z"
                      ></path>
                    </svg>
                    Signing you in...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="bg-slate-600 text-white text-sm font-medium p-2 px-10 rounded-full hover:bg-slate-800"
                  >
                    Login
                  </button>
                )}
              </div>
            </Form>
          </FormikProvider>
        </div>
      </div>
      <p className=" text-sm md:text-base text-center p-10 text-slate-900 ">
        If you face any technical difficulties please email dev@medianet.mv or
        visit our chat services here
      </p>
    </div>
  );
}
