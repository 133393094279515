import HeadCard from "components/head-card/HeadCard";
import React from "react";

export default function Card({
  children,
  heading,
  modal,
  table,
  component,
  description,
}) {
  return (
    <div
      className={` h-fit bg-opacity-30   ${
        modal ? "w-full h-full bg-white dark:bg-slate-700 absolute top-0" : ""
      } flex relative flex-col p-3 border border-slate-300  dark:border-slate-700 bg-white dark:bg-slate-700/40 rounded-xl bg-opacity-[20] bg-blur-[130px] space-y-2 w-full`}
    >
      <div
        className={`flex w-full flex-row items-center justify-between ${
          table ? "" : "border-b border-slate-300 dark:border-slate-700 pb-1 "
        } `}
      >
        <HeadCard title={heading} description={description} />
        <div className="w-1/4 flex justify-end items-end">{component}</div>
      </div>

      <div className="text-md  flex flex-col space-y-3 py-1">{children}</div>
    </div>
  );
}
