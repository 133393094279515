import React from "react";
import { useDispatch } from "react-redux";

import { MultiSelect } from "react-multi-select-component";

import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import Card from "ui/Card";
import customAxios from "middleware/customAxios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline";
import DataGrid from "components/data-grid/DataGrid";
import PrimaryButton from "ui/buttons/PrimaryButton";

export default function RoleEdit() {
  let id = useParams().id;
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let [outlets, setOutlets] = React.useState([]);
  let [username, setUsername] = React.useState("");
  let [uniqueLog, setUniqueLog] = React.useState("None");
  let [submitData, setSubmitData] = React.useState({
    name: "",
    permissions: [],
    code: "",
    description: "",
  });
  let [data, setData] = React.useState({});
  let [selectectedPermissions, setSelectedPermissions] = React.useState([]);
  let [permissions, setPermissions] = React.useState([]);
  let [inputErrors, setInputErrors] = React.useState({});

  const submitHandler = () => {
    if (submitData.name === "") {
      setInputErrors({ name: "Name is required" });
      return;
    } else if (submitData.code === "") {
      setInputErrors({ code: "Code is required" });
      return;
    } else if (submitData.description === "") {
      setInputErrors({ description: "Description is required" });
      return;
    } else if (submitData.permissions.length === 0) {
      setInputErrors({ permissions: "Permissions are required" });
      return;
    } else {
      setInputErrors({});
    }
    customAxios.put("/admin/role/" + id, submitData).then((res) => {
      navigate("/settings/role-management");
    });
  };

  React.useEffect(() => {
    customAxios.get("/admin/permissions").then((res) => {
      setPermissions(
        res.data.map((role) => ({
          label: role.name,
          value: role.id,
          data: role,
        }))
      );
    });
    customAxios.get("/admin/role/" + id).then((res) => {
      setData(res.data);
      setSubmitData({
        name: res.data.name,
        permissions: res.data.permissions.map((perm) => perm.id),
        code: res.data.code,
        description: res.data.description,
      });
      setSelectedPermissions(
        res.data.permissions.map((perm) => ({
          label: perm?.permission?.name,
          value: perm?.permission?.id,
          data: perm?.permission,
        }))
      );
    });
    customAxios.get("/admin/user-groups").then((res) => {
      setOutlets(
        res.data.map((outlet) => ({ label: outlet.name, value: outlet.id }))
      );
    });
  }, []);

  React.useEffect(() => {
    let perm = [];
    selectectedPermissions.forEach((item) => {
      perm.push(item.data.id);
    });
    setSubmitData({ ...submitData, permissions: perm });
  }, [selectectedPermissions]);

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "Add Role",
      })
    );
    dispatch(
      setNavigationComponents(
        <div className="flex space-x-2">
          <PrimaryButton
            title="Apply Changes"
            onClick={() => submitHandler()}
          />
        </div>
      )
    );
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Roles",
          path: "/role-management",
        },
        {
          name: "Add Role",
          path: "/role-management/add",
        },
      ])
    );
  });

  React.useEffect(() => {
    customAxios.get("/admin/user/check-uniqueness/" + username).then((res) => {
      setUniqueLog(res.data);
    });
  }, [username]);

  return (
    <div className="absolute flex flex-col pb-10 gap-3 bg-slate-50 min-h-[calc(100vh-110px)]  rounded-xl top-0 w-full ">
      <Card
        heading={"Role Details"}
        description={"Please fill the following information"}
      >
        <div className="grid grid-cols-3 gap-3">
          <div className="flex flex-col space-y-2">
            <p className="text-[12px]">Name</p>
            <input
              type="text"
              className="border border-gray-300 rounded-md p-2"
              value={submitData.name}
              onChange={(e) =>
                setSubmitData({ ...submitData, name: e.target.value })
              }
            />
            <div className="text-sm text-red-500">{inputErrors.name}</div>
          </div>
          <div className="flex flex-col space-y-2">
            <p className="text-[12px]">Code</p>
            <input
              type="text"
              className="border border-gray-300 rounded-md p-2"
              value={submitData.code}
              onChange={(e) =>
                setSubmitData({ ...submitData, code: e.target.value })
              }
            />
            <div className="text-sm text-red-500">{inputErrors.code}</div>
            {
              // check character length of username
              submitData?.code?.length > 3 && (
                <div>
                  {uniqueLog ? (
                    <div className="flex flex-row items-center space-x-1">
                      <CheckCircleIcon className="h-5 w-5 text-green-500" />
                      <span className="text-green-500">
                        {`'${username}' is available`}
                      </span>
                    </div>
                  ) : (
                    <div className="flex flex-row animate-bounce items-center space-x-1">
                      <XCircleIcon className="h-5 w-5 text-red-500" />
                      <span className="text-red-500">
                        {`'${username}' is taken`}
                      </span>
                    </div>
                  )}
                </div>
              )
            }
          </div>
          <div className="flex flex-col space-y-2">
            <p className="text-[12px]">Description</p>
            <textarea
              className="border border-gray-300 rounded-md p-2"
              value={submitData.description}
              onChange={(e) =>
                setSubmitData({ ...submitData, description: e.target.value })
              }
            />
            <div className="text-sm text-red-500">
              {inputErrors.description}
            </div>
          </div>
        </div>
      </Card>

      <Card
        heading={"Add Permissions"}
        description={`Please select the permissions for the role`}
      >
        <div className="space-y-3">
          <MultiSelect
            options={permissions || []}
            value={selectectedPermissions}
            onChange={setSelectedPermissions}
            labelledBy={"Select Permissions"}
            placeholder={"Select Permissions"}
          />
          <DataGrid
            cellFunction={(cell) => {
              return cell.value;
            }}
            x_columns={[
              {
                Header: "Name",
                accessor: "data.name",
              },
              {
                Header: "Code",
                accessor: "data.code",
              },
              {
                Header: "Description",
                accessor: "data.description",
              },
            ]}
            testData={selectectedPermissions || []}
            showHeader={true}
            height={`h-[calc(100vh-500px))]`}
          />
          <div className="text-sm text-red-500">{inputErrors.permissions}</div>
        </div>
      </Card>
    </div>
  );
}
