import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExclamationCircleIcon, TrashIcon } from "@heroicons/react/outline";
import { MultiSelect } from "react-multi-select-component";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import Card from "ui/Card";
import DataGrid from "components/data-grid/DataGrid";
import { columns } from "./columns";
import { XIcon } from "@heroicons/react/outline";
import { Combobox, Popover } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import customAxios from "middleware/customAxios";
import PrimaryButton from "ui/buttons/PrimaryButton";
import Modal from "components/modal/Modal";
import FileUpload from "components/dropZone/dropZone";
import { useNavigate } from "react-router-dom";
import FormGenerator from "components/form-generator/FormGenerator";
import * as Yup from "yup";
import SecondaryButton from "ui/buttons/SecondaryButton";
import ComboBoxComponent from "components/combo-box/ComboBoxComponent";
import { itemRender } from "functions/ItemRender";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SaleAdd() {
  let dispatch = useDispatch();
  let [total, setTotal] = React.useState(0);
  let navigate = useNavigate();
  let [selectedInventory, setSelectedInventory] = React.useState({});
  let data = useSelector((state) => state.data.userData);
  let [products, setProducts] = React.useState([]);
  let [outlets, setOutlets] = React.useState([]);
  let [selectedProducts, setSelectedProducts] = React.useState([]);
  let [inventory, setInventory] = React.useState([]);
  let [discountModal, setDiscountModal] = React.useState(false);
  let [discounts, setDiscounts] = React.useState([]);
  let [paymentData, setPaymentData] = React.useState({});
  let [show, setShow] = React.useState(false);
  let [assignType, setAssignType] = React.useState("");
  let [customers, setCustomers] = React.useState([]);
  let [customerQuery, setCustomerQuery] = React.useState("");
  let [selectedCustomer, setSelectedCustomer] = React.useState({});
  let [serializedProducts, setSerializedProducts] = React.useState([]);
  let [attachment, setAttachment] = React.useState("");
  let [selectedSerializedProduct, setSelectedSerializedProduct] =
    React.useState({});
  let [values, setValues] = React.useState({
    note: "",
    date: Date.now(),
    customer: "",
  });

  function modalChange() {
    setShow(!show);
  }

  React.useEffect(() => {
    let urlParams = new URLSearchParams();
    urlParams.append("accountNumber", customerQuery);

    if (customerQuery.length > 3) {
      customAxios.get("/admin/customers?" + urlParams).then((res) => {
        setCustomers(res);
      });
    }
  }, [customerQuery]);

  // filer central inventory from  data.outlets

  React.useEffect(() => {
    customAxios.get("/admin/discounts").then((res) => {
      setDiscounts(res.data);
    });
  }, [customers]);

  React.useEffect(() => {
    customAxios.get("/admin/product-variants").then((res) => {
      let products = res.data.map((product) => {
        return {
          label: `${product.product.name} - ${product.name} `,
          value: product.id,
          data: product,
        };
      });
      setProducts(products);
    });
    setOutlets(
      data.outlets?.filter((outlet) => {
        return outlet.name != "Central Inventory";
      })
    );
  }, [data]);

  React.useEffect(() => {
    customAxios
      .get("/admin/serialized-products/warehouse/" + selectedInventory?.id)
      .then((res) => {
        let temp = res.map((x) => {
          return {
            id: x.id,
            label: x.stbSerialNumber,
            name: x.stbSerialNumber,
            productVariant: x.productVariant,
            type: x.type,
            sncSerialNumber: x.sncSerialNumber,
            stbSerialNumber: x.stbSerialNumber,
          };
        });
        setSerializedProducts(temp);
      });
  }, [assignType]);

  function renderConnectionType() {
    return (
      <>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            STB Serial Number
          </label>
          <Combobox
            onChange={(e) => {
              setSelectedSerializedProduct(e);
            }}
          >
            <ComboBoxComponent
              options={function (item) {
                return (
                  <div className="grid grid-cols-12">
                    <span className="col-span-12">{item.name}</span>
                  </div>
                );
              }}
              values={serializedProducts}
            />
          </Combobox>
        </div>
        {selectedSerializedProduct?.name && (
          <div className="space-y-3 flex justify-end flex-col items-end">
            <div className="w-full bg-slate-50 p-5 grid grid-cols-5 gap-3 rounded-xl">
              <img
                src={
                  process.env.REACT_APP_SERVER_URL +
                  selectedSerializedProduct?.productVariant?.image
                }
                className="w-20 object-contain"
              />
              <div className="grid grid-cols-3 col-span-4 gap-3">
                {itemRender(
                  "Product",
                  selectedSerializedProduct?.productVariant?.name
                )}
                {itemRender(
                  "Full Box",
                  selectedSerializedProduct?.isFullBox ? "Yes" : "No"
                )}
                {itemRender(
                  "STB Serial Number",
                  selectedSerializedProduct?.name
                )}
                {itemRender("Type", selectedSerializedProduct?.type)}
                {itemRender(
                  "SNC Serial Number",
                  selectedSerializedProduct?.sncSerialNumber
                )}
              </div>
            </div>
            <div className="flex flex-row  items-center space-x-3">
              <SecondaryButton
                title={"Go Back"}
                onClick={() => {
                  setAssignType("");
                  setSelectedSerializedProduct({});
                }}
              />
              <PrimaryButton
                title={"Assign"}
                onClick={() => {
                  console.log("e");
                  setShow(false);
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  }

  function renderAssignType() {
    switch (assignType) {
      case "migration":
        return (
          <div className="space-y-3">
            <p>Are you sure you want to set this connection as migration?</p>
            <div className="flex justify-end space-x-2">
              <PrimaryButton title={"Yes"} />
              <SecondaryButton title={"No"} onClick={() => setAssignType("")} />
            </div>
          </div>
        );
      case "new connection":
        return (
          <div className="  space-y-3 overflow-auto ">
            {renderConnectionType()}
          </div>
        );
      case "replacement":
        return (
          <div className="  space-y-3 overflow-auto ">
            {renderConnectionType()}
          </div>
        );
      default:
        return <p>jdj</p>;
    }
  }

  React.useEffect(() => {
    if (selectedInventory?.id) {
      customAxios
        .get("/admin/inventory/" + selectedInventory?.id)
        .then((res) => {
          setInventory(res.data.map((x) => x.inventory));
        });
    }
    setSelectedInventory(selectedInventory);
  }, [selectedInventory]);

  function currentStockRenderer(varientId) {
    let item = inventory[0]?.find((x) => x.productVariant.id == varientId);

    let stock = item?.quantity;
    let alertQuantity = item?.productVariant.alertQuantity;
    let customAlertQuantity = item?.customAlertQuantity;
    let alertQuantityToUse = customAlertQuantity || alertQuantity;

    if (stock <= alertQuantityToUse) {
      return (
        <div className="flex items-center space-x-1 text-sm text-red-500">
          <ExclamationCircleIcon className="w-5 h-5 animate-pulse  text-red-500" />
          <p className="text-red-500">{stock}</p>
          <p>Request Stock Immediately</p>
        </div>
      );
    } else if (stock > alertQuantityToUse + 20) {
      return (
        <div className="flex items-center space-x-1  text-sm text-green-500">
          <CheckIcon className="w-5 h-5 animate-pulse text-green-500" />
          <p className="text-green-500">{stock}</p>
          <p>Available</p>
        </div>
      );
    } else {
      return (
        <div className="flex items-center space-x-1 text-yellow-500">
          <ExclamationCircleIcon className="w-5 h-5 animate-pulse text-yellow-500" />
          <p className="text-yellow-500">{stock}</p>
          <p>Low Stock</p>
        </div>
      );
    }
  }

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "Add Sale",
      })
    );
    dispatch(setNavigationComponents(null));
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Sales",
          path: "/sales",
        },
        {
          name: "Add Sale",
          path: "/sales/add",
        },
      ])
    );
  });

  React.useEffect(() => {
    customAxios.get("/admin/product-variants").then((res) => {
      let products = res.data.map((product) => {
        return {
          label: `${product.product.name} - ${product.name} `,
          value: product.id,
          data: product,
        };
      });
      setProducts(products);
    });
    setOutlets(
      data.outlets?.filter((outlet) => {
        return outlet.name != "Central Inventory";
      })
    );
  }, [data]);

  React.useEffect(() => {
    // calculate total
    let total = 0;
    let discount = 0;

    selectedProducts.forEach((item) => {
      total += item.data.total || 0;
      discount += item.data.discount || 0;
    });

    setTotal({
      discount: discount,
      total: total,
      subTotal: total - discount,
    });
  }, [selectedProducts]);

  function cellFunction(cell) {
    if (cell.column.id === "data.quantity") {
      return (
        <input
          type="number"
          className="w-full border border-slate-300 rounded-md p-2"
          value={cell.row.original.data.quantity}
          // prevent negative values
          min={0}
          max={cell.row.original.data.isSerialized ? 1 : 50}
          onChange={(e) => {
            let value = e.target.value;
            let products = selectedProducts.map((product) => {
              if (product.value === cell.row.original.value) {
                if (cell.row.original.data.appliedDiscount == null) {
                  product.data.appliedDiscount = {
                    name: "No Discount",
                    description: "No Discount",
                    fixedAmount: 0,
                    percentage: 0,
                  };
                }

                let discountType = cell.row.original.data.appliedDiscount
                  .fixedAmount
                  ? "fixed"
                  : "percentage";

                let discount = 0;

                if (discountType === "fixed") {
                  discount =
                    cell.row.original.data.appliedDiscount.fixedAmount * value;
                  product.data.total = value * product.data.price - discount;
                  product.data.discount = discount || 0;
                }

                if (discountType === "percentage") {
                  discount =
                    (cell.row.original.data.appliedDiscount.percentage / 100) *
                    (value * cell.row.original.data.price);
                  product.data.total = value * product.data.price - discount;
                  product.data.discount = discount || 0;
                }

                product.data.quantity = value;
              }
              return product;
            });
            setSelectedProducts(products);
          }}
        />
      );
    }
    if (cell.column.id === "currentStock") {
      return (
        <div>
          {selectedInventory == null ? (
            <div className="flex flex-row space-x-1 text-sm text-slate-600">
              <ExclamationCircleIcon className="h-4 w-4" />
              <p>Select a Destination</p>
            </div>
          ) : (
            <div className="flex flex-row space-x-1 text-sm text-slate-600">
              {currentStockRenderer(cell.row.original.data.id)}
            </div>
          )}
        </div>
      );
    }
    if (cell.column.id === "data.name") {
      return (
        <div className="flex flex-row space-x-2">
          <img
            src={
              process.env.REACT_APP_SERVER_URL + cell.row.original.data.image ||
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcKGtXOa4PF2h8Gr04XXB7cwJwDBdz8ScN892tcpsi_A&s"
            }
            className="w-10 h-10"
          />
          <div>
            <h1>{cell.row.original.data.product.name}</h1>
            <p className="text-sm font-semibold">
              {cell.row.original.data.name}
            </p>
          </div>
        </div>
      );
    }
    if (cell.column.id === "data.appliedDiscount") {
      return (
        <div className="flex flex-row space-x-2">
          <div>
            <h1>
              <Popover className="">
                {({ open, close }) => (
                  <>
                    <Popover.Button
                      onClick={() => {
                        setDiscountModal(true);
                      }}
                      className={classNames(
                        open ? "text-slate-900" : "text-slate-600",
                        "group px-3 bg-slate-50 rounded-md inline-flex active:outline-none items-center text-sm font-medium hover:text-slate-900 focus:outline-none focus:ring-2 focus:ring-offset-4"
                      )}
                    >
                      <span>
                        {cell.row.original.data.appliedDiscount?.name ||
                          "Apply Discount"}
                      </span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-slate-600" : "text-slate-400",
                          "ml-2 h-5 w-5 group-hover:text-slate-500"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>
                    <Modal heading={"Apply Discount"}>
                      <div
                        className={`grid-cols-${
                          discounts.length + 1
                        } grid gap-3 p-5`}
                      >
                        <div
                          onClick={() => {
                            let products = selectedProducts.map((product) => {
                              if (product.value === cell.row.original.value) {
                                product.data.appliedDiscount = {
                                  name: "No Discount",
                                  description: "No Discount",
                                  fixedAmount: 0,
                                  percentage: 0,
                                };
                                let discountType = cell.row.original.data
                                  .appliedDiscount.fixedAmount
                                  ? "fixed"
                                  : "percentage";

                                let discount = 0;

                                if (discountType === "fixed") {
                                  discount =
                                    cell.row.original.data.appliedDiscount
                                      .fixedAmount *
                                    cell.row.original.data.quantity;
                                }

                                if (discountType === "percentage") {
                                  discount =
                                    (cell.row.original.data.appliedDiscount
                                      .percentage /
                                      100) *
                                    (cell.row.original.data.quantity *
                                      cell.row.original.data.price);
                                }

                                product.data.total =
                                  cell.row.original.data.quantity *
                                    product.data.price -
                                  discount;
                                product.data.discount = discount;
                              }
                              return product;
                            });
                            setSelectedProducts(products);
                          }}
                          className={`p-5 rounded-xl border hover:shadow-xl hover:bg-white border-slate-400 ${
                            cell.row.original.data.appliedDiscount?.name ===
                            "No Discount"
                              ? "bg-slate-800 text-white"
                              : "bg-white"
                          }`}
                        >
                          <h1 className="text-lg font-medium">No Discount</h1>
                          <p className="text-sm font-semibold">No Discount</p>
                        </div>
                        {discounts.map((discount) => {
                          return (
                            <div
                              onClick={() => {
                                let products = selectedProducts.map(
                                  (product) => {
                                    if (
                                      product.value === cell.row.original.value
                                    ) {
                                      product.data.appliedDiscount = discount;
                                    }

                                    return product;
                                  }
                                );

                                setSelectedProducts(products);
                              }}
                              className={`p-5 rounded-xl border hover:shadow-xl hover:bg-white border-slate-400 ${
                                cell.row.original.data.appliedDiscount ==
                                discount
                                  ? "bg-slate-800 text-white"
                                  : "bg-white"
                              }`}
                            >
                              <h1 className="text-lg font-medium">
                                {discount.name}
                              </h1>
                              <p className="text-sm font-semibold">
                                {discount.description}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                      <button
                        className="fixed top-5 right-3 p-2"
                        onClick={() => {
                          setDiscountModal(false);
                        }}
                      >
                        <XIcon className="h-5 w-5" />
                      </button>
                    </Modal>
                  </>
                )}
              </Popover>
            </h1>
          </div>
        </div>
      );
    }
    if (cell.column.id === "data.discount") {
      return <div>{cell.value}</div>;
    }
    if (cell.column.id === "actions") {
      return (
        <div className="flex flex-row space-x-3 justify-start items-center ">
          <button
            onClick={() => {
              let temp = [...selectedProducts];
              temp.splice(cell.row.index, 1);
              setSelectedProducts(temp);
            }}
            // disabled={userData.length === 1}
            className="flex flex-row items-center space-x-1 p-2  rounded-full text-white bg-red-500 hover:bg-red-600"
          >
            <TrashIcon className="w-5 h-5" />
          </button>
        </div>
      );
    }

    return cell.value;
  }

  let saleData = {
    userGroupId: selectedInventory?.group?.id,
    customerReference: selectedCustomer?.accountNumber ?? "none",
    note: values?.note,
    isDraft: false,
    discount: total?.discount,
    collectiveDiscount: total?.discount,
    discountId: null,
    attachment: attachment ?? null,
    totalTax: 0,
    totalAmount: total?.total,
    paymentMethod: paymentData?.paymentMethod ?? "cash",
    paymentNote: paymentData?.paymentNote,
    saleProducts: selectedProducts?.map((product) => {
      return {
        productVariantId: product?.data?.id,
        quantity: parseInt(product?.data?.quantity),
        discount: product?.data?.discount,
        discountId: product?.data?.appliedDiscount?.id,
        tax: 0,
        total: product?.data?.total,
        unitPrice: parseInt(product?.data?.price),
      };
    }),
  };

  function buttonSubmitHandler() {
    if (selectedProducts.find((product) => product.data.isSerialized)) {
      customAxios.post("/admin/sale", saleData).then((res) => {
        let data = {
          type: assignType,
          saleId: res.sale.id,
          customerId: selectedCustomer.id,
        };
        customAxios
          .put(
            "/admin/serialized-products/" + selectedSerializedProduct.id,
            data
          )
          .then((res) => {
            console.log(res);
            navigate("/sales");
          });
      });
    } else {
      customAxios.post("/admin/sale", saleData).then((res) => {
        navigate("/sales");
      });
    }
  }

  let serializedOptions = [
    {
      type: "new connection",
      icon: "https://www.svgrepo.com/show/478228/connection-2.svg",
    },
    {
      type: "migration",
      icon: "https://www.svgrepo.com/show/182863/exchange-change.svg",
    },
    {
      type: "replacement",
      icon: "https://www.svgrepo.com/show/478228/connection-2.svg",
    },
    {
      type: "card assignment",
      icon: "https://www.svgrepo.com/show/478228/card.svg",
    },
  ];

  function productSelectHandler(e) {
    // check if selected products has serialized product
    // get last object of e array
    let recentProduct = e[e.length - 1];

    if (recentProduct.data.isSerialized) {
      setSelectedSerializedProduct(recentProduct.data);
      setShow(true);
    }
    setSelectedProducts(e);
  }

  return (
    <div className="absolute top-0 w-full pb-10 min-h-[calc(100vh-130px)] bg-slate-50 space-y-3 gap-3">
      <div className=" grid grid-cols-3 gap-3  rounded-xl ">
        <div className="grid col-span-2 gap-3">
          <div className="p-6 bg-white h-full col-span-2   rounded-xl border border-slate-300 ">
            {/* Select outlet */}
            <div className="grid grid-cols-1 mb-10">
              <div className="space-y-2 relative">
                <p className="text-sm font-semibold">Destination</p>
                <Popover className={"relative focus:outline-none"}>
                  {({ open, close }) => (
                    <>
                      <Popover.Button
                        className={`${
                          open ? "" : "text-opacity-70"
                        } group rounded-md inline-flex items-center text-slate-500 hover:text-slate-700 outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500`}
                      >
                        <h1 className="text-4xl font-medium e focus:outline-none text-slate-400">
                          {selectedInventory.group
                            ? selectedInventory.group?.name
                            : "Select Destination"}
                        </h1>
                        <ChevronDownIcon
                          className={`${
                            open ? "" : "text-opacity-70"
                          } ml-2 h-5 w-5  text-slate-500 group-hover:text-slate-700`}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Popover.Panel className="absolute z-10 w-screen max-w-lg px-4 top-14 -left-8">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative grid gap-2 bg-white px-5 py-2 sm:gap-2 sm:p-8">
                            {outlets?.map((outlet) => {
                              return (
                                <button
                                  onClick={() => {
                                    setSelectedInventory(outlet);
                                    close();
                                  }}
                                  className={`flex flex-row  hover:bg-white items-center space-x-4 p-2 rounded-xl ${
                                    selectedInventory?.id == outlet.id
                                      ? "bg-white border-2 border-slate-400"
                                      : ""
                                  }`}
                                >
                                  {selectedInventory?.id === outlet.id ? (
                                    <CheckIcon className="h-6 w-6 text-yellow-500" />
                                  ) : (
                                    <div className="h-6 w-6"></div>
                                  )}

                                  <p className="text-xl font-medium text-slate-900">
                                    {outlet.group?.name}
                                  </p>
                                </button>
                              );
                            })}
                            <PrimaryButton
                              title={"Add Inventory Group"}
                              onClick={() => navigate("/outlets/add")}
                            />
                          </div>
                        </div>
                      </Popover.Panel>
                    </>
                  )}
                </Popover>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-3">
              {/* Select Customer */}
              <div>
                <div className="grid grid-cols-1 font-bold gap-3">Customer</div>
                <Popover className={"relative focus:outline-none "}>
                  {({ open, close }) => (
                    <>
                      <Popover.Button
                        className={`w-full ${
                          open ? "" : "text-opacity-70"
                        } flex flex-row py-2`}
                      >
                        <div className="flex flex-row space-x-3 items-center w-full">
                          <div className="flex flex-col border border-slate-300 w-full p-2 rounded-md space-y-1">
                            <h1 className="text-sm text-start ">
                              {selectedCustomer?.name ?? (
                                <p className="text-yellow-600">
                                  Please Select a Customer
                                </p>
                              )}
                            </h1>
                          </div>
                        </div>
                      </Popover.Button>
                      <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 top-1 -left-4">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative grid gap-2 bg-white  p-2 ">
                            <div className="flex flex-row space-x-3 items-center">
                              <div className="flex flex-col space-y-2 w-full">
                                <input
                                  className="w-full border border-slate-300 rounded-md p-2"
                                  placeholder="Search Customer"
                                  value={customerQuery}
                                  onChange={(e) => {
                                    if (e.target.value.length > 6) return;
                                    setCustomerQuery(e.target.value);
                                  }}
                                  maxLength={6}
                                />
                                <div className="flex flex-col text-sm space-y-2">
                                  <p>Results</p>
                                  {customers.length === 0 ? (
                                    <p className="text-yellow-600">
                                      No Customer Found
                                    </p>
                                  ) : (
                                    <>
                                      {customers?.map((customer) => {
                                        return (
                                          <button
                                            onClick={() => {
                                              setSelectedCustomer(customer);
                                              close();
                                            }}
                                            className={`flex flex-row  hover:bg-white items-center space-x-4 p-2 rounded-xl ${
                                              selectedCustomer?.id ==
                                              customer?.id
                                                ? "bg-white border-2 border-slate-400"
                                                : ""
                                            }`}
                                          >
                                            {selectedCustomer?.id ===
                                            customer?.id ? (
                                              <CheckIcon className="h-6 w-6 text-yellow-500" />
                                            ) : (
                                              <div className="h-6 w-6"></div>
                                            )}

                                            <p className="text-sm font-medium text-slate-900">
                                              {customer?.name} -{" "}
                                              {customer?.accountNumber}
                                            </p>
                                          </button>
                                        );
                                      })}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </>
                  )}
                </Popover>
              </div>
              {/* Select Date */}
              <div className="grid grid-cols-1 gap-3">
                <div className="space-y-1">
                  <label className="text-sm font-medium">Date</label>

                  <input
                    type="date"
                    className="w-full border border-slate-300 rounded-md p-2"
                    value={values.date}
                    defaultValue={new Date()}
                    onChange={(e) => {
                      setValues({ ...values, date: e.target.value });
                    }}
                  />
                </div>
              </div>
              {/* Sales Note */}
              <div className="grid grid-cols-1 gap-3">
                <div className="space-y-1">
                  <label className="text-sm font-medium">Sales Note</label>
                  <textarea className="w-full border border-slate-300 rounded-md p-2"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Card heading={"Payment Details"} description={"Payment details"}>
          <div className="grid grid-cols-1 gap-1">
            <div className="space-y-1">
              <label className="text-sm font-medium">Payment Method</label>
              <select
                className="w-full border border-slate-300 rounded-md p-2"
                value={paymentData?.paymentMethod}
                onChange={(e) =>
                  setPaymentData({
                    ...paymentData,
                    paymentMethod: e.target.value,
                  })
                }
              >
                <option value="bank-transfer">Bank Transfer</option>
                <option value="cash">Cash</option>
                <option value="card">Card</option>
                <option value="cheque">Cheque</option>
              </select>
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium">Payment Note</label>
              <textarea
                className="w-full border border-slate-300 rounded-md p-2"
                value={paymentData?.paymentNote}
                onChange={(e) =>
                  setPaymentData({
                    ...paymentData,
                    paymentNote: e.target.value,
                  })
                }
              />
            </div>
            <div className="space-y-1 w-full">
              <label className="text-sm font-medium">Attachment</label>
              <div className="w-full">
                <FileUpload setFieldValue={setAttachment} />
              </div>
            </div>
          </div>
        </Card>
      </div>
      <Card
        heading={"Add Products"}
        description={"Add products to cart from here"}
      >
        <MultiSelect
          options={products || []}
          value={selectedProducts}
          onChange={(e) => productSelectHandler(e)}
          labelledBy={"Select"}
          placeholder={"Select Users"}
        />
        <div className="w-full">
          <DataGrid
            showHeader={true}
            x_columns={columns}
            testData={selectedProducts ?? []}
            cellFunction={cellFunction}
          />
          <div className="w-full border-t border-slate-800 py-3  flex flex-row space-x-4 items-start justify-end">
            <div className="">
              <label>Total</label>
              <h1 className="text-2xl font-semibold">{total?.total}</h1>
            </div>
            <div>
              <label>Total Discount</label>
              <h1 className="text-2xl font-semibold text-green-700">
                {total?.discount}
              </h1>
            </div>
            <div className="">
              <label>Sub Total</label>
              <h1 className="text-2xl font-bold">{total?.subTotal}</h1>
            </div>
          </div>
        </div>
      </Card>

      <div className="fixed -top-[78px] right-14  z-50 ">
        <PrimaryButton
          type={"submit"}
          title={"Submit"}
          onClick={() => {
            buttonSubmitHandler();
          }}
        />
      </div>

      {/* Serialized Product Assign Modal */}
      <Modal
        show={show}
        setShowModal={modalChange}
        heading={"Please Assign the Serialized Products"}
        subHeading={"Please proceed with one of the options below"}
      >
        <div
          className={`grid ${
            assignType == "" ? "grid-cols-4" : "grid-cols-1"
          } gap-3 p-5`}
        >
          {assignType == "" ? (
            <>
              {serializedOptions.map((option) => (
                <div
                  onClick={() => {
                    setAssignType(option.type);
                    // if assign type is migration
                  }}
                  className="flex flex-col border-slate-100 p-5 capitalize border hover:bg-slate-50 cursor-pointer  items-center space-y-2"
                >
                  <img src={option.icon} className="h-20 w-20" />
                  <p>{option.type}</p>
                </div>
              ))}
            </>
          ) : (
            <div className="w-full">{renderAssignType()}</div>
          )}
        </div>
      </Modal>
    </div>
  );
}
