import DataGrid from "components/data-grid/DataGrid";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Card from "ui/Card";

export default function Analytics() {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "Analytics",
      })
    );
    dispatch(setNavigationComponents(null));
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/home",
        },
        {
          name: "Analytics",
          path: "/analytics",
        },
      ])
    );
  }, []);

  const data = React.useMemo(() => [
    {
      id: 4,
      name: "POS Sales Report",
      description: "View sales report for your store",
      category: "Sales",
      by: "Medianet",
      link: "/analytics/view/pos-sales",
    },
    {
      id: 5,
      name: "Inventory Report",
      description: "View Inventory report for your store",
      category: "Inventory",
      by: "Medianet",
      link: "/analytics/view/inventory",
    },
    {
      id: 6,
      name: "Set Top Box Report",
      description: "View Set Top Box report for your store",
      category: "Set Top Box",
      by: "Medianet",
      link: "/analytics/view/stb-report",
    },
    {
      id: 1,
      name: "Sales (Beta)",
      description: "View sales reports",
      category: "Sales",
      by: "Medianet",
      link: "/analytics/view/sales",
    },
    {
      id: 2,
      name: "Sales Expanded (Beta)",
      description: "View sales report with expanded data (Each product)",
      category: "Sales",
      by: "Medianet",
      link: "/analytics/view/sales-expanded",
    },
    {
      id: 3,
      name: "My Sales (Beta)",
      description: "View sales report for your store",
      category: "Sales",
      by: "Medianet",
      link: "/analytics/view/my-sales",
    },
    {
      id: 4,
      name: "Assesments Report (Beta)",
      description: "View assesments report for your store",
      category: "Assesments",
      by: "Medianet",
      link: "/analytics/view/assesments",
    },
    {
      id: 5,
      name: "Assesments Detail Report (Beta)",
      description: "View assesments report in detail for your store",
      category: "Assesments",
      by: "Medianet",
      link: "/analytics/view/assesments-detail",
    },
  ]);

  return (
    <div className="relative">
      <Card
        heading={"Available Reports"}
        description={"Select a report to view"}
      >
        <DataGrid
          searchable={true}
          x_columns={[
            {
              Header: "Name",
              accessor: "name",
            },
            {
              Header: "Description",
              accessor: "description",
            },
            {
              Header: "Category",
              accessor: "category",
            },
            {
              Header: "By",
              accessor: "by",
            },
          ]}
          testData={data}
          cellFunction={(cell) => {
            if (cell.column.id === "name") {
              return (
                <Link className="hover:underline" to={cell.row.original.link}>
                  <p>{cell.value}</p>
                </Link>
              );
            }
            return cell.value;
          }}
          showHeader
        />
      </Card>
      <Outlet />
    </div>
  );
}
