import React from "react";
import FormGenerator from "components/form-generator/FormGenerator";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import { MultiSelect } from "react-multi-select-component";

import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import Card from "ui/Card";
import DataGrid from "components/data-grid/DataGrid";
import { columns } from "./columns";
import { MinusIcon, PlusIcon, XIcon } from "@heroicons/react/outline";
import { Popover } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import customAxios from "middleware/customAxios";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import Checkbox from "components/checkbox/Checkbox";
import { itemRender } from "functions/ItemRender";
import DatePickerMain from "components/date-picker-v2/DatePickerMain";

export default function DiscountAdd() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let [discountData, setDiscountData] = React.useState({});
  let data = useSelector((state) => state.data.userData);
  let [products, setProducts] = React.useState([]);
  let [outlets, setOutlets] = React.useState([]);
  let [startDate, setStartDate] = React.useState(new Date());
  let [endDate, setEndDate] = React.useState(new Date());

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "Create Product Discount",
      })
    );
    dispatch(setNavigationComponents(null));
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Discount",
          path: "/discounts",
        },
        {
          name: "Add Product Discount",
          path: "/discounts/add",
        },
      ])
    );
  });

  React.useEffect(() => {
    customAxios.get("/admin/product-variants").then((res) => {
      let products = res.data.map((product) => {
        return {
          label: `${product.product.name} - ${product.name} `,
          value: product.id,
          data: product,
        };
      });
      setProducts(products);
    });
    setOutlets(
      data.outlets?.filter((outlet) => {
        return outlet.name != "Central Inventory";
      })
    );
  }, [data]);

  return (
    <div className="absolute top-0 w-full  bg-slate-50  grid grid-cols-1  md:grid-cols-3 gap-3">
      <div className="col-span-2 grid grid-cols-1 gap-3 pb-20">
        <Card
          heading={"Amount of Products"}
          description={
            "Add the amount of products you want to add to the discount"
          }
        >
          <div className="flex flex-col h-fit items-start space-y-6 p-1">
            <div className="flex flex-col space-y-2">
              <label className="text-lg font-medium">Method</label>
              {/* radio buttons */}
              <div className="flex flex-col  space-y-2">
                <div className="flex flex-row items-center space-x-2">
                  <Checkbox
                    onClick={() => {
                      setDiscountData({
                        ...discountData,
                        method: "code",
                      });
                    }}
                    checked={discountData?.method == "code"}
                  />
                  <label>Discount Code</label>
                </div>
                <div className="flex flex-row items-center space-x-2">
                  <Checkbox
                    onClick={() => {
                      setDiscountData({
                        ...discountData,
                        method: "auto",
                      });
                    }}
                    checked={discountData?.method == "auto"}
                  />
                  <label>Automatic Discount</label>
                </div>
              </div>
            </div>
            {discountData?.method == "code" ? (
              <div className="flex w-full flex-col space-y-2">
                <label className="text-sm font-medium">Discount Code</label>
                <div className="flex flex-row space-x-3">
                  <input
                    type="text"
                    className="w-full border uppercase border-slate-300 rounded-md p-2"
                    value={discountData.code}
                    onChange={(e) => {
                      setDiscountData({
                        ...discountData,
                        code: e.target.value,
                      });
                    }}
                  />
                  {/* Generate random */}
                  <button
                    onClick={() => {
                      setDiscountData({
                        ...discountData,
                        code: Math.random().toString(36).substring(7),
                      });
                    }}
                    className="bg-slate-200 p-2 rounded-md"
                  >
                    Generate
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex w-full flex-col space-y-2">
                <label className="text-sm font-medium">Title</label>
                <div className="flex flex-row space-x-3">
                  <input
                    type="text"
                    className="w-full border uppercase border-slate-300 rounded-md p-2"
                    value={discountData.title}
                    onChange={(e) => {
                      setDiscountData({
                        ...discountData,
                        code: null,
                        title: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </Card>
        <Card
          heading={"Value"}
          description={
            "Add the amount of products you want to add to the discount"
          }
        >
          <div className="flex flex-row space-x-10 w-full">
            <div className="w-1/3 flex flex-row text-sm ">
              <div
                onClick={() => {
                  setDiscountData({
                    ...discountData,
                    type: "percentage",
                  });
                }}
                className="flex flex-col w-full space-y-2 border"
              >
                <label
                  className={`${
                    discountData.type == "percentage"
                      ? "bg-slate-300"
                      : "bg-white"
                  } font-medium px-4 p-2 hover:bg-white `}
                >
                  Percentage
                </label>
              </div>
              <div
                onClick={() => {
                  setDiscountData({
                    ...discountData,
                    type: "fixed",
                  });
                }}
                className="flex flex-col w-full space-y-2 border"
              >
                <label
                  className={`${
                    discountData.type == "fixed" ? "bg-slate-300" : "bg-white"
                  } font-medium px-4 p-2 hover:bg-white  `}
                >
                  Fixed Amount
                </label>
              </div>
            </div>
            <div className="flex w-2/3 flex-col space-y-2">
              {discountData?.type === "percentage" ? (
                <div className="flex flex-row items-center  border uppercase border-slate-300 rounded-md p-2 space-x-3">
                  <input
                    type="number"
                    className="w-full h-full focus:outline-none"
                    value={discountData.percentage}
                    onChange={(e) => {
                      setDiscountData({
                        ...discountData,
                        percentage: e.target.value,
                      });
                    }}
                  />
                  <label className="text-sm font-medium">%</label>
                </div>
              ) : (
                <div className="flex flex-row items-center  border uppercase border-slate-300 rounded-md p-2 space-x-3">
                  <label className="text-sm font-medium">MVR</label>
                  <input
                    type="number"
                    className="w-full h-full focus:outline-none"
                    value={discountData.fixedAmount}
                    onChange={(e) => {
                      setDiscountData({
                        ...discountData,
                        fixedAmount: e.target.value,
                      });
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </Card>
        <Card
          heading={"Minimum Purchase Requirements"}
          description={
            "Add the amount of products you want to add to the discount"
          }
        >
          <div className="grid grid-cols-2 gap-3">
            <div className="space-y-1">
              <label className="text-sm font-medium">Minimum Purchase</label>
              <div className="flex flex-row items-center  border uppercase border-slate-300 rounded-md p-2 space-x-3">
                <label className="text-sm font-medium">MVR</label>
                <input
                  type="number"
                  className="w-full h-full focus:outline-none"
                  value={discountData.minimumPurchase}
                  onChange={(e) => {
                    setDiscountData({
                      ...discountData,
                      minimumPurchase: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium">Minimum Quantity</label>
              <div className="flex flex-row items-center  border uppercase border-slate-300 rounded-md p-2 space-x-3">
                <input
                  type="number"
                  className="w-full h-full focus:outline-none"
                  value={discountData.minQuantity}
                  onChange={(e) => {
                    setDiscountData({
                      ...discountData,
                      minQuantity: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row items-center space-x-2">
              <Checkbox
                onClick={() => {
                  setDiscountData({
                    ...discountData,
                    allowCombinations: discountData?.allowCombinations
                      ? !discountData.allowCombinations
                      : true,
                  });
                }}
                checked={discountData?.allowCombinations == true}
              />
              <label>Allow Combinations</label>
            </div>
          </div>
        </Card>
        <Card heading={"Products"} description={"Add the products"}>
          <div className="flex flex-row items-center space-x-2">
            <Checkbox
              onClick={() => {
                setDiscountData({
                  ...discountData,
                  isApplicableToAllProducts:
                    discountData?.isApplicableToAllProducts
                      ? !discountData.isApplicableToAllProducts
                      : true,
                });
              }}
              checked={discountData?.isApplicableToAllProducts == true}
            />
            <label>Applicable to all products</label>
          </div>
        </Card>
      </div>
      <div className="col-span-1 relative w-full">
        <div className="bg-white w-[400px] fixed space-y-3 p-5 border border-slate-300 rounded-xl ">
          <h1 className="text-xl font-medium">Summary</h1>
          <div className="flex flex-row space-x-3 text-xl uppercase">
            {discountData?.code
              ? discountData?.code
              : discountData?.title
              ? discountData?.title
              : ""}
          </div>
          {discountData.type && (
            <>
              {itemRender(
                "Method",
                discountData?.method ? discountData?.method : "Method"
              )}
              {discountData?.type === "percentage" ? (
                <div>
                  {itemRender(
                    "percentage",
                    `${discountData?.percentage} % OFF`
                  )}
                </div>
              ) : (
                <div>
                  {itemRender("Amount", `${discountData?.fixedAmount} MVR OFF`)}
                </div>
              )}
            </>
          )}

          {itemRender(
            "Min. Purchase Amount",
            `${
              discountData?.minimumPurchase
                ? `${discountData?.minimumPurchase} MVR`
                : "No Minimum Purchase"
            }`
          )}
          {itemRender(
            "Min. Quantity",
            `${
              discountData?.minQuantity
                ? discountData?.minQuantity
                : "No Minimum Quantity"
            }`
          )}
          {itemRender(
            "Allow Combinations",
            `${discountData?.allowCombinations}`
          )}
          <div className="space-y-1">
            <h1
              className="text-sm text-slate-700 font-medium
            "
            >
              Applicable Products
            </h1>
            {discountData.isApplicableToAllProducts ? (
              <div>All Products</div>
            ) : (
              <div>Selected Products</div>
            )}
          </div>
          <div className="flex w-full space-x-3 pt-6">
            <button
              onClick={() => {
                console.log(discountData);

                //                method: req.body.method,
                // percentage: req.body.percentage,
                // fixedAmount: req.body.fixedAmount,
                // minPurchaseAmount: req.body.minPurchaseAmount,
                // minQuantity: req.body.minQuantity,
                // allowCombinations: req.body.allowCombinations,
                // isApplicableToAllProducts: req.body.isApplicableToAllProducts,
                // startDate: req.body.startDate,
                // endDate: req.body.endDate,

                let data = {
                  name: discountData?.title || discountData?.code,
                  method: discountData?.method,
                  percentage: discountData?.percentage,
                  fixedAmount: discountData?.fixedAmount,
                  minPurchaseAmount: discountData?.minimumPurchase,
                  minQuantity: discountData?.minQuantity,
                  allowCombinations: discountData?.allowCombinations,
                  isApplicableToAllProducts:
                    discountData?.isApplicableToAllProducts,
                  startDate: discountData?.startDate || new Date(),
                  endDate:
                    discountData?.endDate ||
                    new Date() + 1 * 24 * 60 * 60 * 1000,
                };
                customAxios.post("/admin/discount", data).then((res) => {
                  console.log(res);
                  navigate("/discounts");
                });
              }}
              className="bg-slate-800 w-full text-white hover:ring-1 ring-offset-1 font-medium  p-2 rounded-md"
            >
              Submit
            </button>
            <button
              onClick={() => {
                setDiscountData({
                  ...discountData,
                  isApplicableToAllProducts: true,
                });
              }}
              className="bg-white text-slate-800 w-full hover:ring-1 ring-offset-1   font-medium  p-2 rounded-md"
            >
              Discard Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
