import DataGrid from "components/data-grid/DataGrid";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { columns } from "./columns";
import { useNavigate } from "react-router-dom";
import DropDownButton from "ui/buttons/DropDownButton";
import { FaEdit } from "react-icons/fa";
import { PencilAltIcon, XIcon } from "@heroicons/react/outline";
import moment, { Moment } from "moment";
import Card from "ui/Card";
import customAxios from "middleware/customAxios";

export default function DiscountsIndex() {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [discounts, setDiscounts] = React.useState([]);

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "Discounts",
      })
    );
    dispatch(
      setNavigationComponents(
        <PrimaryButton
          title="Add Discounts"
          onClick={() => navigate("/discounts/add")}
        />
      )
    );
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/home",
        },
        {
          name: "Sales",
          path: "/sales",
        },
      ])
    );
  });

  React.useEffect(() => {
    customAxios.get("/admin/discounts").then((res) => {
      setDiscounts(res.data);
    });
  }, []);

  const cellFunction = (cell) => {
    if (cell.column.id === "products") {
      return <div>All Products</div>;
    }
    if (cell.column.id === "name") {
      return (
        <div
          onClick={() => {
            navigate("/sales/view/" + cell.row.original.id, {
              state: cell.row.original,
            });
          }}
          className="flex flex-row items-center uppercase space-x-3 cursor-pointer"
        >
          <p className="hover:underline">{cell.value}</p>
        </div>
      );
    }
    if (cell.column.id === "actions") {
      return (
        <DropDownButton
          items={[
            {
              title: "Edit",
              icon: <PencilAltIcon className="h-5 w-5" />,
              path: "/sales/edit/" + cell.row.original.id,
              state: cell.row.original,
            },
            {
              title: "Delete",
              icon: <XIcon className="h-5 w-5" />,
              path: "/sales/delete/" + cell.row.original.id,
              state: cell.row.original,
            },
          ]}
          title="Actions"
          icon={<i className="fas fa-ellipsis-v"></i>}
          className=""
          setSelected={(item) => {
            console.log(item);
          }}
          path="/sales/"
        />
      );
    }
    return cell.value;
  };

  return (
    <div className="relative overflow-scroll min-h-[80vh] ">
      <Card
        heading={"All Discounts"}
        description={`You can find all the discounts here`}
      >
        <div className="relative flex space-y-3 flex-col w-full">
          <DataGrid
            searchable={true}
            x_columns={columns}
            api={"/admin/discounts"}
            showHeader={true}
            cellFunction={cellFunction}
          />
        </div>
      </Card>
      <Outlet />
    </div>
  );
}
