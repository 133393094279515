import DataGrid from "components/data-grid/DataGrid";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { columns } from "./columns";
import { useNavigate } from "react-router-dom";
import DropDownButton from "ui/buttons/DropDownButton";
import { FaEdit } from "react-icons/fa";
import { PencilAltIcon, XIcon } from "@heroicons/react/outline";
import Card from "ui/Card";
import moment from "moment";

export default function CategoriesIndex() {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "Collections",
      })
    );
    dispatch(
      setNavigationComponents(
        <PrimaryButton
          title="Add Collection"
          onClick={() => navigate("/products/categories/add")}
        />
      )
    );
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Products",
          path: "/products",
        },
        {
          name: "Collections",
          path: "/products/collections",
        },
      ])
    );
  }, []);

  const cellFunction = (cell) => {
    if (cell.column.id === "name") {
      return (
        <div
          onClick={() => {
            navigate("/products/categories/view/" + cell.row.original.id, {
              state: cell.row.original,
            });
          }}
          className="flex flex-row items-center space-x-3 cursor-pointer"
        >
          <p className="hover:underline font-bold">{cell.value}</p>
        </div>
      );
    }
    if (cell.column.id === "actions") {
      return (
        <DropDownButton
          items={[
            {
              title: "Edit",
              icon: <PencilAltIcon className="h-5 w-5" />,
              path: "/products/categories/edit/" + cell.row.original.id,
              state: cell.row.original,
            },
            {
              title: "Delete",
              icon: <XIcon className="h-5 w-5" />,
              path: "/products/categories/delete/" + cell.row.original.id,
              state: cell.row.original,
            },
          ]}
          title="Actions"
          icon={<i className="fas fa-ellipsis-v"></i>}
          className=""
          setSelected={(item) => {
            console.log(item);
          }}
          path="/products/categories/"
        />
      );
    }
    if (cell.column.id === "createdAt" || cell.column.id === "updatedAt") {
      return moment(cell.value).format("DD MMMM YYYY");
    }
    return cell.value;
  };

  return (
    <div className="w-full relative ">
      <Card
        heading={"All Collections"}
        description={
          "You can group your products into collections to make it easier for customers to find them by category"
        }
      >
        <DataGrid
          search={true}
          x_columns={columns}
          api="/admin/product-groups"
          showHeader={true}
          cellFunction={(cell) => cellFunction(cell)}
          height={"h-[calc(100vh-230px)]"}
        />
      </Card>
      <Outlet />
    </div>
  );
}
