import React from "react";
import { useDispatch, useSelector } from "react-redux";

export default function Notification() {
  let [show, setShow] = React.useState(true);
  let notification = useSelector((state) => state.notification);
  let dispatch = useDispatch();
  React.useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
    dispatch(setShow(false));
  }, []);

  return (
    <div>
      {show && (
        <div className="w-full bg-emerald-600 p-2 px-6">
          <p className="text-white">{JSON.stringify(notification ?? "")}</p>
        </div>
      )}
    </div>
  );
}
