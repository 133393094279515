import { TagIcon, HomeIcon } from "@heroicons/react/solid";
import Order from "components/pos/order/Order";
import React from "react";
import { FaCartPlus } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NavBar from "./navigation/NavBar";
import { Tab } from "@headlessui/react";

export default function POSLayout({ children }) {
  let navigate = useNavigate();
  let { modal } = useLocation().state ?? false;

  let menuItems = [
    {
      name: "Home",
      path: "/home",
      icon: <HomeIcon className="w-6 h-6" />,
    },
  ];

  return (
    <div className=" h-screen overflow-hidden w-screen bg-slate-200  ">
      <div className="grid grid-cols-12 h-screen overflow-hidden w-screen  ">
        <div className="relative  ">
          <div className="w-full absolute z-10 top-2    flex items-center justify-center">
            <img
              src="https://marketplace.canva.com/EAFEits4-uw/1/0/1600w/canva-boy-cartoon-gamer-animated-twitch-profile-photo-oEqs2yqaL8s.jpg"
              alt=""
              className="rounded-full h-[70px] w-[70px] border-8 border-slate-200 object-cover"
            />
          </div>
          <div className="relative mt-14 pt-10 h-[calc(100vh-80px)] flex flex-col space-y-4  p-2 col-span-1 m-3 rounded-xl bg-white">
            <Tab.Group>
              <Tab.List className="flex p-1 space-x-1 bg-white rounded-md">
                {menuItems.map((item, i) => (
                  <Tab
                    key={i}
                    onClick={() => {
                      navigate(item.path);
                    }}
                    className={({ selected }) =>
                      `${
                        selected
                          ? "bg-slate-800 text-slate-100"
                          : "text-slate-400 bg-white"
                      }
                          relative z-10 flex-1 rounded-md p-1 text-center text-sm font-medium`
                    }
                  >
                    <div
                      on
                      key={i}
                      className="flex flex-col items-center justify-center   w-full space-y-1 p-2 rounded-md hover:bg-slate-200"
                    >
                      {item.icon}
                      <p className="text-sm">{item.name}</p>
                    </div>
                  </Tab>
                ))}
              </Tab.List>
            </Tab.Group>
          </div>
        </div>

        <div className={" col-span-7 "}>
          <div className="flex  w-full  overflow-auto h-[calc(100vh-100px)]justify-start text-start flex-col space-y-3  p-2">
            {children}
          </div>
        </div>
        <div className="col-span-4 p-3 h-[calc(100vh-160px)] rounded-xl">
          <Order />
        </div>
      </div>
    </div>
  );
}
