import axios from "axios";

export const customAxios = axios.create({
  baseURL: `${process.env.REACT_APP_AXIOS_URL}`,
});

// Add a request interceptor
customAxios.interceptors.request.use(
  function (config) {
    // token
    let token = localStorage.getItem("token");

    if (!token) {
      window.location.href = "/auth/login";
    }

    if (config.method === "post" || config.method === "put") {
      config.headers["Content-Type"] = "application/json";
    }

    config.headers["authorization"] = `Bearer ${token}`;

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
customAxios.interceptors.response.use(
  function (response) {
    // Do something with response data

    return response.data;
  },
  function (error) {
    if (error.response.status === 401) {
      window.location.href = "/auth/login";
    }
    return Promise.reject(error);
  }
);

export default customAxios;
