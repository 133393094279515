import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import App from "App";

import CategoriesIndex from "pages/products/categories/CategoriesIndex";
import ProductInventory from "pages/products/inventory/ProductInventory";
import POSIndex from "pages/products/pos/POSIndex";
import ProdIndex from "pages/products/index";
import ProductView from "pages/products/view/ProductView";

import ProductsIndex from "pages/products/ProductsIndex";
import CategoriesAdd from "pages/products/categories/add/CategoriesAdd";
import ProductAdd from "pages/products/add-product/ProductAdd";
import CategoriesView from "pages/products/categories/view/CategoriesView";
import CategoriesEdit from "pages/products/categories/edit/CategoriesEdit";
import CategoriesDelete from "pages/products/categories/delete/CategoriesDelete";
import HomeIndex from "pages/home/HomeIndex";
import UsersIndex from "pages/users/UsersIndex";
import UsersAdd from "pages/users/add/UsersAdd";
import UsersView from "pages/users/view/UsersView";
import UsersEdit from "pages/users/edit/UsersEdit";
import UsersDelete from "pages/users/delete/UsersDelete";
import SalesIndex from "pages/sales/SalesIndex";
import SaleAdd from "pages/sales/add/SaleAdd";
import OrderRequests from "pages/order-requests/OrderRequests";
import Outlets from "pages/outlets/Oultets";
import OutletAdd from "pages/outlets/add/OutletAdd";
import OutletEdit from "pages/outlets/edit/OutletEdit";
import OutletDelete from "pages/outlets/delete/OutletDelete";
import OutletView from "pages/outlets/view/OutletView";
import Settings from "pages/settings/Settings";
import SettingsGeneral from "pages/settings/general/SettingsGeneral";
import RoleManagement from "pages/settings/role-management/RoleManagement";
import OrderRequestView from "pages/order-requests/view/OrderRequestView";
import StockAdjustments from "pages/products/stock-adjustments/StockAdjustments";
import AdjustmentAdd from "pages/products/stock-adjustments/add/AdjustmentAdd";
import OrderRequestAdd from "pages/order-requests/add/OrderRequestAdd";
import Login from "pages/auth/Login";
import ProductEdit from "pages/products/edit-product/ProductEdit";
import ProductDelete from "pages/products/delete/ProductDelete";
import RoleAdd from "pages/settings/role-management/add/RoleAdd";
import DiscountsIndex from "pages/discounts/DiscountsIndex";
import DiscountAdd from "pages/discounts/add/DiscountAdd";
import Analytics from "pages/analytics/Analytics";
import Reports from "pages/analytics/Reports";
import SalesView from "pages/sales/view/SalesView";
import SerializedProducts from "pages/products/serialized-products/SerializedProducts";
import SerializedProductsAdd from "pages/products/serialized-products/add/SerializedProductAdd";
import AssignCustomer from "pages/products/serialized-products/assign-customer/AssignCustomer";
import Migrations from "pages/products/migrations/Migrations";
import RoleEdit from "pages/settings/role-management/edit/RoleEdit";
import NoAccess from "pages/static/NoAccess";
import AssignTechnician from "pages/products/serialized-products/assign-technician/AssignTechnician";
import SyncCard from "pages/products/serialized-products/sync-card/SyncCard";
import Refurbish from "pages/products/serialized-products/refurbish/Refurbish";
import CreateAssesment from "pages/assesments/create/CreateAssesment";
import Assesments from "pages/assesments/Assesments";
import AssesmentView from "pages/assesments/view/AssesmentView";
import CustomerInvoicePage from "pages/customer-invoices/customer-invoice-page";
import PaymentsPage from "pages/payments/page";
import RatingScreen from "pages/rating/rating";
import ReviewPage from "pages/reviews/Review";

export const PageRoutes = () => {
  let location = useLocation();

  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/payments/:paymentNumber" element={<PaymentsPage />} />
      <Route path="/reviews/:ticketId" element={<ReviewPage />} />
      <Route path="/ratings" element={<RatingScreen />} />
      <Route path="/" element={<App />}>
        <Route path="/home" element={<HomeIndex />} />
        <Route path="assesments/" element={<Assesments />}>
          <Route path="view/:id" element={<AssesmentView />} />
          <Route path="create" element={<CreateAssesment />} />
        </Route>

        {/* Outlets */}
        <Route path="outlets/" element={<Outlets />}>
          <Route path="add" element={<OutletAdd />} />
          <Route path="view/:id" element={<OutletView />} />
          <Route path="edit/:id" element={<OutletEdit />} />
          <Route path="delete/:id" element={<OutletDelete />} />
        </Route>

        <Route path="products/" element={<ProdIndex />}>
          <Route path="all" element={<ProductsIndex />} />
          <Route path="add" element={<ProductAdd />} />
          <Route path="view/:id" element={<ProductView />} />
          <Route path="edit/:id" element={<ProductEdit />} />
          <Route path="delete/:id" element={<ProductDelete />} />
        </Route>

        <Route path="products/categories/" element={<CategoriesIndex />}>
          <Route path="add" element={<CategoriesAdd />} />
          <Route path="view/:id" element={<CategoriesView />} />
          <Route path="edit/:id" element={<CategoriesEdit />} />
          <Route path="delete/:id" element={<CategoriesDelete />} />
        </Route>

        <Route path="products/adjustments/" element={<StockAdjustments />}>
          <Route path="add" element={<AdjustmentAdd />} />
        </Route>

        <Route
          path="products/serialized-products/"
          element={<SerializedProducts />}
        >
          <Route path="add" element={<SerializedProductsAdd />} />
          <Route path="assign-customer/:id" element={<AssignCustomer />} />
          <Route path="assign-technician/:id" element={<AssignTechnician />} />
          <Route path="sync-card/:id" element={<SyncCard />} />
          <Route path="refurbish/:id" element={<Refurbish />} />
        </Route>

        <Route path="products/migrations/" element={<Migrations />}>
          <Route path="assign-customer/:id" element={<AssignCustomer />} />
        </Route>

        {/* POS */}
        <Route path="pos" element={<POSIndex />} />

        {/* Users */}
        <Route path="users/" element={<UsersIndex />}>
          <Route path="add" element={<UsersAdd />} />
          <Route path="view/:id" element={<UsersView />} />
          <Route path="edit/:id" element={<UsersEdit />} />
          <Route path="delete/:id" element={<UsersDelete />} />
        </Route>

        <Route path="sales/" element={<SalesIndex />}>
          <Route path="add" element={<SaleAdd />} />
          <Route path="view/:id" element={<SalesView />} />
        </Route>

        <Route path="order-requests/" element={<OrderRequests />}>
          <Route path="add" element={<OrderRequestAdd />} />
          <Route path="view/:id" element={<OrderRequestView />} />
        </Route>

        <Route path="discounts/" element={<DiscountsIndex />}>
          <Route path="add" element={<DiscountAdd />} />
        </Route>

        <Route path="settings/" element={<Settings />}>
          <Route path="general" element={<SettingsGeneral />} />
          <Route path="role-management/" element={<RoleManagement />}>
            <Route path="add" element={<RoleAdd />} />
            <Route path="edit/:id" element={<RoleEdit />} />
          </Route>
        </Route>

        <Route path="analytics/" element={<Analytics />}>
          <Route path="view/:report" element={<Reports />} />
        </Route>

        <Route path="*" element={<div>Not Found</div>} />
        <Route path="401" element={<NoAccess />} />
      </Route>
      <Route path="/auth/login" element={<Login />} />
    </Routes>
  );
};
