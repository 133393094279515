import { PencilAltIcon, XIcon } from "@heroicons/react/solid";
import DataGrid from "components/data-grid/DataGrid";
import {
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";
import DropDownButton from "ui/buttons/DropDownButton";
import PrimaryButton from "ui/buttons/PrimaryButton";
import Card from "ui/Card";

export default function RoleManagement() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let userData = useSelector((state) => state.data.userData);
  let permissions = userData?.permissions;

  React.useEffect(() => {
    if (!userData) {
      return;
    }

    if (permissions?.length > 0 && permissions[0]?.includes("r-sales")) {
      dispatch(
        setCurrent({
          name: "Role Management",
        })
      );
      dispatch(
        setNavigationComponents(
          <div className="flex space-x-2">
            <PrimaryButton title="Add Role" onClick={() => navigate("add")} />
          </div>
        )
      );
    } else {
      navigate("/401");
    }
  }, [userData]);

  function cellFunction(cell) {
    switch (cell.column.id) {
      case "actions":
        return (
          <DropDownButton
            items={[
              {
                title: "Edit",
                icon: <PencilAltIcon className="h-5 w-5" />,
                path: "edit/" + cell.row.original.id,
                state: cell.row.original,
              },
            ]}
            title="Actions"
            icon={<i className="fas fa-ellipsis-v"></i>}
            className=""
            setSelected={(item) => {
              console.log(item);
            }}
            path="/users/"
          />
        );
      case "permissions":
        return (
          <div className="">
            {cell.row.original.permissions.map((p) => {
              return (
                <p className="inline-flex border hover:border-slate-500 items-center px-2.5 py-1 m-1 rounded-full text-[10px] font-medium bg-gray-100 text-gray-800 mr-1">
                  {p.permission?.name}
                </p>
              );
            })}
          </div>
        );

      default:
        return cell.value;
    }
  }
  return (
    <div className="relative overflow-scroll min-h-[80vh] ">
      <Card
        heading={"All Roles"}
        description={"Each Role can have multiple permissions"}
      >
        <div className="relative flex space-y-3 flex-col w-full">
          <DataGrid
            searchable={true}
            x_columns={[
              {
                Header: "Name",
                accessor: "name",
              },
              {
                Header: "Code",
                accessor: "code",
              },
              {
                Header: "Permissions",
                accessor: "permissions",
              },
              {
                Header: "Status",
                accessor: "status",
              },
              {
                Header: "Actions",
                accessor: "actions",
              },
            ]}
            api={"/admin/roles"}
            showHeader={true}
            cellFunction={cellFunction}
            height={"h-[calc(100vh-230px)]"}
          />
        </div>
      </Card>
      <Outlet />
    </div>
  );
}
