import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import customAxios from "middleware/customAxios";
import Card from "ui/Card";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";
import DataGrid from "components/data-grid/DataGrid";
import Loader from "components/loader/Loader";

export default function SalesIndex(isDashboard) {
  let navigate = useNavigate();
  let { report } = useParams();
  let dispatch = useDispatch();
  let [data, setData] = React.useState([]);
  let userData = useSelector((state) => state.data.userData);
  let [loading, setLoading] = React.useState(true);
  let outlets = userData.outlets;
  let [params, setParams] = React.useState({
    start: "2023-01-01",
    end: "2024-12-31",
    outlet: null,
  });
  let [columns, setColumns] = React.useState([]);

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: `Sales`,
      })
    );
    dispatch(
      setNavigationComponents(
        <div className="flex flex-row space-x-2">
          <PrimaryButton
            title={"New Sale"}
            onClick={() => {
              navigate("/sales/add");
            }}
          ></PrimaryButton>
        </div>
      )
    );
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Sales",
          path: "/sales",
        },
        {
          name: "Sales",
          path: `/sales`,
        },
      ])
    );
  }, []);

  React.useEffect(() => {
    setParams({
      ...params,
      outlet: outlets ? outlets[0] : null,
    });
  }, []);

  //   Data Fetch
  React.useEffect(() => {
    let urlParams = new URLSearchParams();

    urlParams.append("start", params.start);
    urlParams.append("end", params.end);
    urlParams.append("limit", 20);
    //   Fetch data
    customAxios.get("/admin/report/sales/?" + urlParams).then((res) => {
      setData(res.data);
      setColumns(res.columns);
      setLoading(false);
    });
  }, [params, report]);

  return (
    <div className="relative">
      <div className=" grid grid-cols-1 gap-2  h-[calc(100vh-120px)] bg-slate-50  rounded-xl top-0 w-full">
        <Card heading={"All Sales"} description={"Find all the sales here"}>
          <div className="py-3">
            {loading ? (
              <div className="flex justify-center items-center">
                <Loader />
              </div>
            ) : (
              <>
                {data.length > 1 ? (
                  <>
                    <DataGrid
                      searchable={true}
                      showHeader={true}
                      api={"/admin/report/sales/?"}
                      cellFunction={(cell) => {
                        if (cell.column.id == "referenceNumber") {
                          return (
                            <p
                              onClick={() => {
                                navigate(`/sales/view/${cell.row.original.id}`);
                              }}
                              className="capitalize hover"
                            >
                              {cell.value}
                            </p>
                          );
                        }
                        if (cell.column.id == "createdAt") {
                          return new Date(cell.value).toLocaleDateString();
                        }
                        if (cell.column.id == "isVoid") {
                          return cell.value ? (
                            <p className="text-red-500">Void</p>
                          ) : (
                            <p className="text-green-500">Completed</p>
                          );
                        }
                        return <p className="capitalize">{cell.value}</p>;
                      }}
                      height={"h-[calc(100vh-250px)]"}
                    />
                  </>
                ) : (
                  <div className="text-center space-y-2 p-5">
                    <h1 className="text-xl font-medium">
                      No data found for the date range selected
                    </h1>
                    <p>
                      Please select a different date range or contact your
                      system admin
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </Card>
        <Outlet />
      </div>
    </div>
  );
}
