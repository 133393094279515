import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { FaFileExcel, FaFilePdf } from "react-icons/fa";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { useParams } from "react-router-dom";

export default function ReportGenerator({ data, headers }) {
  const { report } = useParams();
  const generatePDF = () => {
    const doc = new jsPDF(
      "l", // orientation
      "pt", // unit
      "a4" // format
    );
    doc.autoTable({
      head: [headers],
      orientation: "landscape",
      textSize: 8,
      body: data.map((item) => [headers.map((header) => item[header])]),
    });
    doc.save(`SalesReport-${Date.now()}.pdf`);
  };

  return (
    <div className="flex flex-row space-x-5">
      {/* export to excel */}
      <button
        onClick={() => {
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          const fileName = `Report-${report}-${Date.now()}`;
          const ws = XLSX.utils.json_to_sheet(data);

          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const dataExcel = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(dataExcel, fileName + fileExtension);
        }}
        className="flex hover:text-green-600 flex-row space-x-2 items-center"
      >
        <FaFileExcel className="mr-2 h-10" />
        {/* icon */}
        Export to Excel
      </button>
      <button
        className="flex hover:text-red-600 flex-row space-x-2 items-center"
        onClick={generatePDF}
      >
        <FaFilePdf className="mr-2 h-10" />
        {/* icon */}
        Generate PDF
      </button>
    </div>
  );
}
