import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";

export default function DropDownButton({
  items,
  title,
  icon,
  className,
  setSelected,
}) {
  return (
    <div className="">
      <Menu>
        <div className="">
          <Menu.Button className="px-4 p-1.5 w-32 text-sm border border-slate-300 flex flex-row space-x-3 items-center justify-center text-slate-800 dark:text-slate-200 bg-slate-50 dark:bg-slate-900 font-semibold rounded-xl  hover:brightness-95">
            <div className="">{icon}</div>
            <p>{title}</p>
            <ChevronDownIcon
              className="ml-2 -mr-1 h-4 w-4"
              aria-hidden="true"
            />
          </Menu.Button>

          <Transition
            className="absolute w-inherit"
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="sticky  rounded-md mt-1 p-3 z-50   w-32  origin-bottom-right divide-y divide-gray-100  bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {items?.map((item, i) => (
                <Menu.Item key={i}>
                  {({ active }) => (
                    <Link
                      onClick={() => {
                        setSelected(item);
                      }}
                      to={
                        item.disabled
                          ? "#"
                          : {
                              pathname: item.path,
                              state: item?.state,
                            }
                      }
                      className={`${
                        active
                          ? "bg-white rounded-md text-black"
                          : "text-gray-900"
                      } group flex w-full space-x-2 items-center hover:text-yellow-500 text-slate-800 px-2 py-2 text-sm`}
                    >
                      {item.icon}
                      <p className="0">
                        {item?.title.replace(/[A-Z]/g, " $&").trim()}
                      </p>
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </div>
      </Menu>
    </div>
  );
}
