export const columns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Type",
    accessor: "groupType",
  },
  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "State",
    accessor: "state",
  },
  {
    Header: "City",
    accessor: "city",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];
