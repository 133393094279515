import React from "react";

export default function DashboardTile({
  heading,
  description,
  component,
  children,
  span,
  dark,
  gradient,
}) {
  return (
    <div
      className={` ${
        span ? `md:col-span-${span} col-span-4 ` : "col-span-2 md:col-span-1"
      } p-3 ${
        gradient
          ? "bg-gradient-to-b "
          : dark
          ? "bg-gradient-to-b from-slate-700 via-slate-700 to-slate-900 text-white"
          : "bg-white text-slate-800"
      } space-y-2 rounded-xl border justify-start border-slate-200`}
    >
      <div className="flex flex-row justify-between items-start">
        {heading && (
          <div className="flex flex-col">
            <h1 className="text-base md:text-lg font-bold ">{heading}</h1>
            <p className="text-sm md:text-sm text-gray-500">{description}</p>
          </div>
        )}

        <div className="flex flex-row items-center space-x-2">{component}</div>
      </div>
      <div className="flex flex-col space-y-1 ">{children}</div>
    </div>
  );
}
