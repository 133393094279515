import React from "react";
import FormGenerator from "components/form-generator/FormGenerator";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import { MultiSelect } from "react-multi-select-component";

export default function UsersEdit() {
  let dispatch = useDispatch();
  let [selectedRoles, setSelectedRoles] = React.useState([]);

  let data = {
    name: "Category 1",
    description: "This is a description",
    parentCategory: {
      name: "Category 2",
      id: 2,
    },
    image: "https://m.media-amazon.com/images/I/61HwpDbhcBL.jpg",
  };

  const options = [
    {
      label: "Admin",
      value: 1,
    },
    {
      label: "User",
      value: 2,
    },
    {
      label: "Guest",
      value: 3,
    },
    {
      label: "Super Admin",
      value: 4,
    },
  ];

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "Editable User",
      })
    );
    dispatch(setNavigationComponents(null));
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Users",
          path: "/products",
        },
        {
          name: "Add Product",
          path: "/products/add",
        },
      ])
    );
  }, []);

  const formData = {
    contents: [
      {
        fields: [
          {
            name: "username",
            displayName: "User Name",
            type: "field",
            inputType: "string",
            required: true,
          },
          {
            name: "email",
            displayName: "Email",
            type: "field",
            inputType: "email",
            required: true,
          },
          {
            name: "phone",
            displayName: "Phone",
            type: "field",
            inputType: "string",
            required: true,
          },
          {
            name: "password",
            displayName: "Password",
            type: "field",
            inputType: "password",
            required: true,
          },
          {
            name: "confirmPassword",
            displayName: "Confirm Password",
            type: "field",
            inputType: "password",
            required: true,
          },
        ],
      },
    ],
    initialValues: {
      name: data.name ?? "",
      description: data.description ?? "",
      parentCategory: data.parentCategory ?? "",
      image: data.image ?? "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().max(20).min(8).required("Password is required"),
      confirmPassword: Yup.string().required("Please confirm your password"),
    }),
    onSubmit: async (values, { setErrors }) => {
      console.log(values);
    },
  };

  return (
    <div className="absolute grid grid-cols-2 gap-10  rounded-xl top-0 w-full bg-white">
      <FormGenerator
        grids={1}
        modal={true}
        buttonSubmit={{ title: "Proceed" }}
        formData={formData}
      />

      <div className="flex flex-col space-y-1.5">
        <p>Select Roles</p>
        <MultiSelect
          style={{ height: 5 }}
          options={options}
          value={selectedRoles}
          onChange={setSelectedRoles}
          labelledBy="Select"
        />
      </div>
    </div>
  );
}
