import { MinusCircleIcon, PlusCircleIcon, XIcon } from "@heroicons/react/solid";

import DataGrid from "components/data-grid/DataGrid";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import React from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";
import DropDownButton from "ui/buttons/DropDownButton";
import Card from "ui/Card";
import moment from "moment";

export default function StockAdjustments() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "Stock Adjustments",
      })
    );
    dispatch(setNavigationComponents(null));
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/home",
        },
        {
          name: "Products",
          path: "/products/all",
        },
        {
          name: "Stock Adjustments",
          path: "/products/adjustments",
        },
      ])
    );
  });

  return (
    <div className="relative">
      <Card
        heading={"All Stock Adjustments"}
        description={"These are all the adjustments"}
      >
        <DataGrid
          x_columns={[
            {
              Header: "Type",
              accessor: "type",
            },
            {
              Header: "Product",
              accessor: "inventory.productVariant.product.name",
            },
            {
              Header: "Variant",
              accessor: "inventory.productVariant.name",
            },
            {
              Header: "Quantity",
              accessor: "quantity",
            },
            {
              Header: "Note",
              accessor: "note",
            },
            {
              Header: "Inventory",
              accessor: "inventory.userGroup.name",
            },
            {
              Header: "Created At",
              accessor: "createdAt",
            },
            {
              Header: "CreatedBy",
              accessor: "createdByUser.username",
            },
          ]}
          api={"/admin/inventory/adjustments"}
          height={"h-[calc(100vh-230px)]"}
          showHeader={true}
          cellFunction={(cell) => {
            if (cell.column.id === "type") {
              return cell.value == "add" ? (
                <PlusCircleIcon className="h-5 w-5 text-green-500" />
              ) : (
                <MinusCircleIcon className="h-5 w-5 text-red-500" />
              );
            } else if (cell.column.id == "quantity") {
              if (cell.row.original.type == "add") {
                return <span className="text-green-500">+{cell.value}</span>;
              }
              return <span className="text-red-500">{cell.value}</span>;
            } else if (cell.column.id == "createdAt") {
              return new moment(cell.value).format("DD MMMM YYYY");
            }
            return cell.value;
          }}
        />
      </Card>
      <Outlet />
    </div>
  );
}
