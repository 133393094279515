export const columns = [
  {
    Header: "Username",
    accessor: "username",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "userInfo.email",
  },
  {
    Header: "Phone",
    accessor: "userInfo.phone",
  },
  {
    Header: "Outlets",
    accessor: "userGroups",
  },
  {
    Header: "Roles",
    accessor: "roles",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];
