import React from "react";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "components/modal/Modal";
import SecondaryButton from "ui/buttons/SecondaryButton";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { Importer, ImporterField } from "react-csv-importer";
import "react-csv-importer/dist/index.css";
import DataGrid from "components/data-grid/DataGrid";
import { columns } from "../columns";
import customAxios from "middleware/customAxios";

export default function SerializedProductAdd({ setOpen }) {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(true);
  const [data, setData] = useState([]);
  const [showImporter, setShowImporter] = useState(true);
  const [serverMessage, setServerMessage] = useState("");
  const [log, setLog] = useState("");
  const [imported, setImported] = useState(false);

  let modalChange = () => {
    console.log("hit");
    setShowModal(false);
    navigate("/products/serialized-products");
  };

  React.useEffect(() => {
    setShowModal(true);
  }, []);

  return (
    <div className=" relative ">
      <Modal
        show={showModal}
        setShowModal={modalChange}
        width="max-w-5xl"
        heading="Serialized Product Add"
        subHeading={"Please upload the file to add serialized products"}
      >
        <div className="p-5 space-y-3">
          {showImporter && (
            <Importer
              chunkSize={10000} // optional, internal parsing chunk size in bytes
              assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
              restartable={true} // optional, lets user choose to upload another file when import is complete
              delimiter={","}
              onStart={({ file, fields }) => {
                // optional, invoked when user has mapped columns and started import
                console.log(
                  "starting import of file",
                  file,
                  "with fields",
                  fields
                );
              }}
              processChunk={async (rows) => {
                // required, receives a list of parsed objects based on defined fields and user column mapping;
                // may be called several times if file is large
                // (if this callback returns a promise, the widget will wait for it before parsing more data)
                console.log("received batch of rows", rows);
                setData((data) => [...data, ...rows]);

                // mock timeout to simulate processing
                await new Promise((resolve) => setTimeout(resolve, 500));
              }}
              onComplete={({ file, fields }) => {
                // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                console.log(
                  "finished import of file",
                  file,
                  "with fields",
                  fields
                );
              }}
              onClose={() => {
                // optional, invoked when import is done and user clicked "Finish"
                // (if this is not specified, the widget lets the user upload another file)
                setShowImporter(false);
                console.log("importer dismissed");
                setImported(true);
              }}
            >
              <ImporterField name="Name" label="Name" />
              <ImporterField name="Type" label="Type" />
              <ImporterField name="STBSerial" label="STB Serial" />
              <ImporterField
                name="SNCSerial"
                label="SNC Serial"
                required={false}
              />
              <ImporterField name="Seller" label="Seller" />
              <ImporterField name="Dealer" label="Dealer" />
              <ImporterField name="Customer" label="Customer" />
            </Importer>
          )}

          {serverMessage && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative ">
              <strong className="font-bold mr-5">Error!</strong>
              <span className="block sm:inline">{serverMessage}</span>
              <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                <svg
                  onClick={() => {
                    setServerMessage("");
                  }}
                  className="fill-current h-6 w-6 text-red-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <title>Close</title>
                  <path
                    fill-rule="evenodd"
                    // eslint-disable-next-line max-len
                    d="M14.348 14.849a1 1 0 01-1.414 0L10 11.414l-2.93 2.93a1 1 0 01-1.414-1.414l2.93-2.93-2.93-2.93a1 1 0 111.414-1.414l2.93 2.93 2.93-2.93a1 1 0 111.414 1.414l-2.93 2.93 2.93 2.93a1 1 0 010 1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
            </div>
          )}

          {log?.length > 0 && (
            <div className="bg-salte-100 border border-slate-400 text-slate-700 px-4 py-3 rounded relative">
              <div className="flex w-full justify-end items-center">
                <button onClick={() => setLog("")}>Clear</button>
              </div>

              {log?.map((item) => {
                return (
                  <div>
                    <span className="block sm:inline">{item.message}</span>
                  </div>
                );
              })}
            </div>
          )}

          {imported && (
            <DataGrid
              x_columns={[
                {
                  Header: "STB Serial",
                  accessor: "STBSerial",
                },
                {
                  Header: "SNC Serial",
                  accessor: "SNCSerial",
                },
                {
                  Header: "STB Type",
                  accessor: "Type",
                },
                {
                  Header: "Name",
                  accessor: "Name",
                },
                {
                  Header: "Dealer",
                  accessor: "Dealer",
                },
                {
                  Header: "Customer",
                  accessor: "Customer",
                },
              ]}
              cellFunction={(cell) => {
                return <div>{cell.value}</div>;
              }}
              height={"h-[calc(100vh-430px)]"}
              testData={data}
              showHeader={true}
            />
          )}
        </div>
        {imported && (
          <div class="bg-gray-50 sticky bottom-0 w-full px-4 py-3 md:flex grid grid-cols-2 gap-3  justify-end items-center flex-row space-x-3">
            <SecondaryButton onClick={modalChange} title={"Cancel"} />

            <PrimaryButton
              onClick={() => {
                let newData = data.map((item) => {
                  // if empty string then null
                  if (item.SNCSerial === "") {
                    item.SNCSerial = null;
                  }
                  if (item.Customer === "") {
                    item.Customer = null;
                  }
                  if (item.STBSerial === "") {
                    item.STBSerial = null;
                  }
                  return {
                    stbSerialNumber: item.STBSerial,
                    sncSerialNumber: item.SNCSerial,
                    name: item.Name,
                    type: item.Type,
                    seller: item.Seller,
                    dealer: item.Dealer,
                    customer: item.Customer,
                  };
                });

                customAxios
                  .post("/admin/serialized-products", newData)
                  .then(
                    (res) => {
                      console.log(res);
                      modalChange();
                    },
                    (err) => {
                      console.log({ err: err.response.data });
                      setLog(err.response.data.log);
                      setServerMessage(err.response.data.message);
                    }
                  )
                  .catch((err) => {
                    console.log(err);
                  });
              }}
              title={"Upload"}
            />
          </div>
        )}
        <button className="absolute -top-16 right-3" onClick={modalChange}>
          <svg
            className="fill-current h-6 w-6 text-gray-500"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path
              fill-rule="evenodd"
              // eslint-disable-next-line max-len
              d="M14.348 14.849a1 1 0 01-1.414 0L10 11.414l-2.93 2.93a1 1 0 01-1.414-1.414l2.93-2.93-2.93-2.93a1 1 0 111.414-1.414l2.93 2.93 2.93-2.93a1 1 0 111.414 1.414l-2.93 2.93 2.93 2.93a1 1 0 010 1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </Modal>
    </div>
  );
}
