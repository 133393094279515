import React from "react";

export default function PermissionError() {
  return (
    <div className="w-full h-[calc(100vh-230px)] flex flex-col space-y-3 justify-center items-center text-center">
      {/* No permissions */}
      <img
        src="https://cdn-icons-png.flaticon.com/512/3518/3518909.png"
        className="w-1/5 mx-auto"
      />
      <p className="text-sm text-red-500">You have no permissions</p>
      <p className="text-sm">
        Please contact your administrator to get access to the system
      </p>
    </div>
  );
}
