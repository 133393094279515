import FormGenerator from "components/form-generator/FormGenerator";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "ui/buttons/PrimaryButton";
import SecondaryButton from "ui/buttons/SecondaryButton";
import Checkbox from "components/checkbox/Checkbox";
import customAxios from "middleware/customAxios";
import HeadCard from "components/head-card/HeadCard";
import Card from "ui/Card";
import DataGrid from "components/data-grid/DataGrid";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/outline";
import { CameraIcon, XIcon } from "@heroicons/react/solid";
import MediaUploadModal from "components/media-upload-modal/MediaUploadModal";
import Modal from "components/modal/Modal";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import SecondarButton from "ui/buttons/SecondaryButton";

let metadata = [];

export default function ProductAdd() {
  let { id } = useParams();
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let [loading, setLoading] = React.useState(true);
  let [hasVariants, setHasVariants] = React.useState(false);
  let [categories, setCategories] = React.useState([]);
  let [showModal, setShowModal] = React.useState(false);
  let [fileName, setFileName] = React.useState("where");
  let [imageUploadPV, setImageUploadPV] = React.useState(false);
  let [productVariants, setProductVariants] = React.useState([
    {
      name: "",
      price: "",
      sku: "",
      code: "",
      alertQuantity: "",
      default: true,
    },
  ]);
  let [errors, setErrors] = React.useState([]);

  let [data, setData] = React.useState({});

  React.useEffect(() => {
    customAxios.get(`/admin/product/${id}`).then((res) => {
      setData(res.data);
      setProductVariants(res.data.variants);
      setLoading(false);
    });
  }, []);

  const [files, setFiles] = React.useState([]);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    multiple: false,
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      let filename = acceptedFiles[0].name;
      // remove white space
      filename = filename.replace(/\s/g, "");
      let filetype = acceptedFiles[0].type;
      let file = acceptedFiles[0];

      // change file contents
      let reader = new FileReader();

      reader.onload = function (e) {
        let contents = e.target.result;
        let base64 = contents.split(",")[1];
        file = new File([base64], filename, { type: filetype });
      };

      let formData = new FormData();

      console.log(file.name);
      formData.append("file", file);
      formData.append("mimetye", file.type);
      formData.append("filename", file.name);
      formData.append("size", file.size);
      formData.append("fieldname", "file");

      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}api/v1/admin/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          console.log(res);
          console.log(productVariants.length);
          //  add image to product variant
          productVariants[productVariants.length - 1].image = res.data.filename;
          setShowModal(false);
        });
    },
  });

  const thumbs = files.map((file) => (
    <div style={{ fontSize: 10 }} className="w-full" key={file.name}>
      <div className="p-0.5 px-2 border border-secondary text-secondary rounded-md">
        <div
          className="w-full bg-contain bg-no-repeat bg-center "
          style={{
            backgroundImage: `url(${file.preview})`,
            height: 350,
          }}
        />

        <p>{file.name}</p>
        <p>{file.type}</p>
      </div>
    </div>
  ));

  let modalChange = () => {
    setShowModal(!showModal);
  };

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "Edit Product",
      })
    );
    dispatch(setNavigationComponents(null));
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Products",
          path: "/products",
        },
        {
          name: "Edit Product",
          path: "/products/edit",
        },
      ])
    );
  });

  React.useEffect(() => {
    customAxios.get("/admin/product-groups").then((res) => {
      setCategories(res.data.filter((x) => x.type === "category"));
    });
  }, [data]);

  return (
    <div className="flex flex-col space-y-3">
      <div>
        {/* show Errors */}
        {Object.keys(errors).length > 0 && (
          <div
            className="bg-red-100 border flex-col border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <strong className="font-bold">Error!</strong>
            <span className="block sm:inline">{errors.message}</span>
          </div>
        )}
      </div>
      <Card
        heading={"General Information"}
        description={"Add the following information about the product"}
      >
        {!loading && (
          <FormGenerator
            heading="Add Product"
            grids={3}
            modal={true}
            buttonSubmit={{ title: "Submit" }}
            formData={{
              contents: [
                {
                  fields: [
                    {
                      name: "category",
                      displayName: "Product Category",
                      type: "comboBox",
                      required: true,
                      values: categories ?? [],
                      value: (formik) => {
                        return formik.values.parentCategory;
                      },
                      options: function (item) {
                        return (
                          <div className="grid grid-cols-12">
                            <span className="col-span-12">{item.name}</span>
                          </div>
                        );
                      },
                    },
                    {
                      name: "name",
                      displayName: "Name",
                      type: "field",
                      placeholder: "Product Name",
                      required: true,
                    },
                    {
                      name: "description",
                      displayName: "Description",
                      type: "textArea",
                      placeholder: "Product Description",
                      required: false,
                    },
                  ],
                },
              ],
              initialValues: {
                name: data?.name ?? "",
                description: data?.description ?? "",
                category: data?.categories[0]?.ProductGroup,
              },
              validationSchema: Yup.object().shape({
                name: Yup.string().required("Name is required"),
                description: Yup.string().required("Description is required"),
                category: Yup.object().required("Category is required"),
              }),
              onSubmit: async (values) => {
                console.log(values);
                // if (!productVariants[0].default) {
                //   setErrors({ message: "Please select a default variant" });
                // }
                let data = {
                  name: values.name,
                  description: values.description,
                  productVariants: productVariants,
                };

                let urlParams = new URLSearchParams();

                urlParams.append("category", values.category.id);

                customAxios
                  .put("/admin/product/" + id + "/?" + urlParams, data)
                  .then((res) => {
                    console.log(res);
                    navigate("/products/all");
                  });
              },
            }}
          />
        )}
      </Card>

      <Card
        heading={"Product Variants"}
        description={"Each product can have multiple variants"}
      >
        <div>
          <div className="flex flex-col space-y-3">
            <DataGrid
              x_columns={[
                {
                  Header: "Image",
                  accessor: "image",
                },
                {
                  Header: "Name",
                  accessor: "name",
                },
                {
                  Header: "Price",
                  accessor: "price",
                },
                {
                  Header: "SKU",
                  accessor: "sku",
                },
                {
                  Header: "Code",
                  accessor: "code",
                },
                {
                  Header: "Alert Quantity",
                  accessor: "alertQuantity",
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                },
              ]}
              testData={productVariants ?? []}
              showHeader={true}
              cellFunction={(cell) => {
                if (cell.column.id === "actions") {
                  return (
                    <div className="flex flex-row space-x-3 ">
                      {/* add */}

                      <button
                        onClick={() => {
                          let temp = [...productVariants];
                          temp.splice(cell.row.index + 1, 0, {
                            name: "",
                            price: "",
                            sku: "",
                            code: "",
                            alertQuantity: "",
                            default: false,
                          });
                          setProductVariants(temp);
                        }}
                        className="flex flex-row items-center space-x-1 p-2  rounded-full text-white bg-slate-400 hover:bg-slate-600"
                      >
                        <PlusCircleIcon className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => {
                          let temp = [...productVariants];
                          temp.splice(cell.row.index, 1);
                          setProductVariants(temp);
                        }}
                        disabled={productVariants.length === 1}
                        className="flex flex-row items-center space-x-1 p-2  rounded-full text-white bg-red-500 hover:bg-red-600"
                      >
                        <TrashIcon className="w-5 h-5" />
                      </button>
                    </div>
                  );
                }
                if (cell.column.id === "image") {
                  return (
                    <button
                      onClick={() => {
                        modalChange();
                        setImageUploadPV(cell.row.index);
                      }}
                    >
                      {cell.value ? (
                        <img
                          className="h-20 w-20 "
                          src={process.env.REACT_APP_SERVER_URL + cell.value}
                          alt=""
                        />
                      ) : (
                        <CameraIcon className="h-10 w-10 text-gray-400 hover:text-amber-400" />
                      )}
                    </button>
                  );
                }
                return (
                  <div className="flex flex-row space-x-2">
                    <input
                      value={cell.row.original[cell.column.id]}
                      onChange={(e) => {
                        let temp = [...productVariants];
                        temp[cell.row.index][cell.column.id] = e.target.value;
                        setProductVariants(temp);
                        // change default to false in first variant
                        if (cell.row.index === 0) {
                          let temp = [...productVariants];
                          temp[cell.row.index].default = true;
                          setProductVariants(temp);
                        }
                      }}
                      type="text"
                      className="w-full p-1.5 border border-slate-200 rounded-lg"
                    />
                  </div>
                );
              }}
            />
          </div>
        </div>
      </Card>

      <div>
        <Modal
          show={showModal}
          setShowModal={modalChange}
          heading="Add Media"
          subHeading="Insert a picture"
        >
          <div className="p-5 flex-col  space-y-6 justify-center items-center w-full flex text-center">
            <div className="w-full">
              <div className="flex flex-col w-full relative space-y-2">
                {!files?.length > 0 ? (
                  <div
                    {...getRootProps({ className: "dropzone" })}
                    className="border border-secondary bg-white w-full  focus:outline-none rounded-md space-y-3 flex flex-col items-center justify-center  p-5"
                  >
                    <input {...getInputProps()} />
                    <img
                      className="h-10 w-10"
                      src="https://cdn-icons-png.flaticon.com/128/3685/3685850.png"
                    />
                    <p className="text-primary" style={{ fontSize: 10 }}>
                      {`Drag 'n' drop some files here, or click to select files`}
                    </p>
                    {/* fileupload  */}
                  </div>
                ) : (
                  <>
                    <aside className="">{thumbs}</aside>
                    <SecondarButton
                      onClick={() => setFiles([])}
                      title="Remove current"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <button
            onClick={() => {
              setShowModal(false);
              setFiles([]);
            }}
            className="flex fixed top-10 right-5 flex-row justify-end space-x-2"
          >
            <XIcon className="h-5 w-5 text-gray-400 " />
          </button>
        </Modal>
      </div>
    </div>
  );
}
