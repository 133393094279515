import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Modal from "components/modal/Modal";
import SecondaryButton from "ui/buttons/SecondaryButton";
import LoadingPrimaryButton from "ui/buttons/LoadingPrimaryButton";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { CheckIcon } from "@heroicons/react/outline";
import { Popover } from "@headlessui/react";
import customAxios from "middleware/customAxios";

export default function AssignTechnician({ setOpen }) {
  let navigate = useNavigate();

  const [showModal, setShowModal] = useState(true);
  const [technianQuery, settechnianQuery] = React.useState("");
  const [selectedTechnician, setSelectedTechnician] = React.useState(null);
  const [technicians, setTechnicians] = React.useState([]);
  let id = useParams().id;
  let [selectedSerializedProduct, setSelectedSerializedProduct] =
    React.useState({});

  let modalChange = () => {
    console.log("hit");
    setShowModal(false);
    navigate("/products/serialized-products");
  };

  React.useEffect(() => {
    customAxios.get("/admin/serialized-product/" + id).then((res) => {
      setSelectedSerializedProduct(res);
    });
  }, [id]);

  React.useEffect(() => {
    let urlParams = new URLSearchParams();
    urlParams.append("type", "supervisorGroup");

    if (technianQuery.length > 3) {
      customAxios.get("/admin/userGroups").then((res) => {
        setTechnicians(res);
      });
    }
  }, [technianQuery]);

  React.useEffect(() => {
    setShowModal(true);
  }, []);

  return (
    <div className="  ">
      <Modal
        show={showModal}
        setShowModal={modalChange}
        heading="Assign STB to Technician"
        subHeading="Please fill the form below to assign STB to a technician."
      >
        <div className="p-5 space-y-2">
          {/* Assign technician */}
          <div>
            <div className="grid grid-cols-1 gap-3">Technician</div>
            <Popover className={"relative focus:outline-none "}>
              {({ open, close }) => (
                <>
                  <Popover.Button
                    className={`w-full ${
                      open ? "" : "text-opacity-70"
                    } flex flex-row py-2`}
                  >
                    <div className="flex flex-row space-x-3 items-center w-full">
                      <div className="flex flex-col border border-slate-300 w-full p-2 rounded-md space-y-1">
                        <h1 className="text-sm text-start ">
                          {selectedTechnician?.name ?? (
                            <p className="text-yellow-600">
                              Please Select a technician
                            </p>
                          )}
                        </h1>
                      </div>
                    </div>
                  </Popover.Button>
                  <Popover.Panel className="absolute z-10 min-w-xl px-4 top-2 -left-4">
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="relative grid gap-2 bg-white w-full  p-2 ">
                        <div className="flex flex-row space-x-3 items-center">
                          <div className="flex flex-col space-y-2 w-full">
                            <input
                              autoFocus={true}
                              className="w-full border border-slate-300 rounded-md p-2"
                              placeholder="Search technician"
                              value={technianQuery}
                              onChange={(e) => {
                                if (e.target.value.length > 6) return;
                                settechnianQuery(e.target.value);
                              }}
                              maxLength={6}
                            />
                            <div className="flex flex-col text-sm space-y-2">
                              <p>Results</p>
                              {technicians.length === 0 ? (
                                <p className="text-yellow-600">
                                  No Technician Found
                                </p>
                              ) : (
                                <>
                                  {technicians?.map((technician) => {
                                    return (
                                      <button
                                        onClick={() => {
                                          setSelectedTechnician(technician);
                                          close();
                                        }}
                                        className={`flex flex-row  hover:bg-white items-center space-x-4 p-2 rounded-xl ${
                                          selectedTechnician?.id ==
                                          technician?.id
                                            ? "bg-white border-2 border-slate-400"
                                            : ""
                                        }`}
                                      >
                                        {selectedTechnician?.id ===
                                        technician?.id ? (
                                          <CheckIcon className="h-6 w-6 text-yellow-500" />
                                        ) : (
                                          <div className="h-6 w-6"></div>
                                        )}

                                        <p className="text-sm font-medium text-slate-900">
                                          {technician?.name} -{" "}
                                          {technician?.accountNumber}
                                        </p>
                                      </button>
                                    );
                                  })}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <PrimaryButton title="Add Technician" onClick={close} />
                      </div>
                    </div>
                  </Popover.Panel>
                </>
              )}
            </Popover>
          </div>
          {/* Ticket no */}
          <div className="grid grid-cols-1  gap-3">
            <p className="text-sm">Ticket No</p>
            {/* input */}
            <div className="flex flex-row space-x-3 items-center">
              <div className="flex flex-col space-y-2 w-full">
                <input
                  autoFocus={true}
                  className="w-full border border-slate-300 rounded-md p-2"
                  value={selectedSerializedProduct?.ticketNo}
                />
              </div>
            </div>
          </div>
          {/* Remarks */}
          <div className="grid grid-cols-1  gap-3">
            <div className="grid grid-cols-1 gap-3">Remarks</div>
            {/* input */}
            <div className="flex flex-row space-x-3 items-center">
              <div className="flex flex-col space-y-2 w-full">
                <textarea
                  autoFocus={true}
                  className="w-full border border-slate-300 rounded-md p-2"
                  value={selectedSerializedProduct?.remarks}
                />
              </div>
            </div>
          </div>
        </div>

        <div class="bg-gray-50 sticky bottom-0 w-full px-4 py-3 md:flex grid grid-cols-2 gap-3  justify-end items-center flex-row space-x-3">
          <SecondaryButton onClick={modalChange} title={"Cancel"} />

          <PrimaryButton
            onClick={() => {
              customAxios
                .put(
                  "/admin/serialized-products/" + id + "/assign-technician",
                  {
                    accountNumber: selectedTechnician?.accountNumber,
                  }
                )
                .then(
                  (res) => {
                    modalChange();
                    console.log(res);
                  },
                  (err) => {
                    console.log(err);
                    alert(err.response.data.message);
                  }
                );
            }}
            title={"Assign"}
          />
        </div>
      </Modal>
    </div>
  );
}
