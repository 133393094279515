import { Combobox, Listbox } from "@headlessui/react";
import {
  ChartBarIcon,
  ChartSquareBarIcon,
  CheckIcon,
  TableIcon,
} from "@heroicons/react/solid";
import BarChart from "components/charts/bar-chart/barChart";
import LineChart from "components/charts/line-chart/LineChart";
import PieChart from "components/charts/pie-chart/PieChart";
import DashboardTile from "components/dashboard-tile/DashboardTile";
import { currencyFormatter } from "functions/currencyFormatter";
import customAxios from "middleware/customAxios";
import React, { Fragment } from "react";
import { MdReport } from "react-icons/md";
import { MultiSelect } from "react-multi-select-component";
import Card from "ui/Card";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/modal/Modal";
import { setGlobalSelectedInventory } from "features/data/DataSlice";
import SalesIndex from "pages/sales/SalesIndex";
import OrderRequests from "pages/order-requests/OrderRequests";
import {
  CodeIcon,
  CubeIcon,
  PlayIcon,
  PlusIcon,
  TagIcon,
} from "@heroicons/react/outline";
import moment from "moment";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CentralDashboard() {
  let [sales, setSales] = React.useState([]);
  let [salesCount, setSalesCount] = React.useState({});
  let [loading, setLoading] = React.useState(true);
  let selectedInventory = useSelector((state) => state.data.selectedInventory);
  let [showOutletModal, setShowOutletModal] = React.useState(false);
  let [orderRequests, setOrderRequests] = React.useState([]);
  let navigate = useNavigate();
  let dispatch = useDispatch();

  function outletModalChange() {
    setShowOutletModal(!showOutletModal);
  }

  let decodedToken = JSON.parse(
    atob(localStorage.getItem("token").split(".")[1])
  );

  let permissions = decodedToken?.permissions;

  let outlets = decodedToken?.outlets;

  let dayTimeGreetings = () => {
    let date = new Date();
    // check if morning
    if (date.getHours() < 12) {
      return "Good Morning";
    } else if (date.getHours() >= 12 && date.getHours() < 16) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  React.useEffect(() => {
    let urlParams = new URLSearchParams();
    let start = moment().startOf("day").format("YYYY-MM-DD");
    let end = moment().endOf("day").format("YYYY-MM-DD");
    urlParams.append("start", start);
    urlParams.append("end", end);
    urlParams.append("limit", 1000);
    urlParams.append("outlet", selectedInventory?.group?.id ?? null);
    customAxios
      .get("/admin/report/sales/inventory/" + selectedInventory?.id)
      .then((res) => {
        setSales(res);
        setLoading(false);
      });
    customAxios.get("/admin/order-requests?" + urlParams).then((res) => {
      setOrderRequests(res.data);
    });

    customAxios
      .get("/admin/report/pos-sales/count" + "?" + urlParams)
      .then((res) => {
        setSalesCount(res);
      });
  }, [selectedInventory]);

  return (
    <div className="flex flex-col space-y-3">
      <div className="grid grid-cols-4 gap-3">
        <DashboardTile>
          <p>{dayTimeGreetings()}</p>
          <div className="flex flex-col text-start pb-1 text-2xl font-medium  space-y-2">
            {decodedToken?.name}
          </div>
          <div className="flex flex-col space-y-1 items-start">
            <p>Selected Inventory/Outlet:</p>
            <h5
              onClick={() => {
                outlets.length > 0 && setShowOutletModal(true);
              }}
              className="text-sm w-fit   p-1 bg-slate-800 rounded-md px-4 text-white"
            >
              {selectedInventory?.group?.name ?? "Medianet"}
            </h5>
          </div>
        </DashboardTile>
        {/* order requests */}
        <DashboardTile dark={true}>
          <div className="space-y-1 flex flex-col">
            <div className="flex flex-row justify-start space-x-3 items-center">
              <CubeIcon className="h-10 w-10 text-indigo-500" />
              <div>
                <p>Order Requests Today</p>
                <div className="flex flex-col text-start text-2xl font-medium  space-y-2">
                  <h1 className="text-4xl">
                    {
                      orderRequests.filter(
                        (x) =>
                          moment(x.createdAt).format("DD MMMM yyyy") ==
                          moment().format("DD MMMM yyyy")
                      ).length
                    }
                  </h1>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-4 space-around w-full p-1 ite">
              <div>
                <p>Pending</p>
                <p>
                  {orderRequests.filter((x) => x.status == "pending").length}
                </p>
              </div>
              <div>
                <p>Approved</p>
                <p>
                  {orderRequests.filter((x) => x.status == "approved").length}
                </p>
              </div>
              <div>
                <p>Rejected</p>
                <p>
                  {orderRequests.filter((x) => x.status == "rejected").length}
                </p>
              </div>
              <div>
                <p>Completed</p>
                <p>
                  {orderRequests.filter((x) => x.status == "delivered").length}
                </p>
              </div>
            </div>
          </div>
        </DashboardTile>
        <DashboardTile dark={true}>
          <div className="space-y-1 flex flex-col">
            <div className="flex flex-row justify-start space-x-3 items-center">
              <TagIcon className="h-10 w-10 text-red-500" />
              <div>
                <p>Sales Today</p>
                <div className="flex flex-col text-start text-2xl font-medium  space-y-2">
                  <h1 className="text-4xl">{salesCount?.total ?? 0}</h1>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-5 space-around w-full p-1 ite">
              <div>
                <p>Completed</p>
                <p>2</p>
              </div>
              <div>
                <p>Voided</p>
                <p>
                  {orderRequests.filter((x) => x.status == "approved").length}
                </p>
              </div>
            </div>
          </div>
        </DashboardTile>

        <DashboardTile dark={true}>
          <div className="space-y-1 flex flex-col">
            <div className="flex flex-row justify-start space-x-3 items-center">
              <PlusIcon className="h-10 w-10 text-orange-500" />
              <div>
                <p>Adjustments Today</p>
                <div className="flex flex-col text-start text-2xl font-medium  space-y-2">
                  <h1 className="text-4xl">0</h1>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-5 space-around w-full p-1 ite">
              <div>
                <p>Addition</p>
                <p>0</p>
              </div>
              <div>
                <p>Substraction</p>
                <p>0</p>
              </div>
            </div>
          </div>
        </DashboardTile>

        <DashboardTile
          span={window.innerWidth < 768 ? 4 : 2}
          heading={"Total Sales"}
          description={"This months total sales"}
          component={
            <div className="flex flex-row items-center space-x-2">
              <ChartSquareBarIcon className="h-5 w-5 text-slate-300" />
            </div>
          }
        >
          {/* Select inventory */}

          {!loading && (
            <>
              <h1 className="text-lg md:text-xl font-bold pb-2 md:pb-5">
                {currencyFormatter(
                  sales?.datasets?.data.reduce(
                    (a, b) => {
                      return a + b;
                    }
                    // 0
                  )
                )}
              </h1>
              <LineChart datasets={[sales?.datasets]} labels={sales?.labels} />
            </>
          )}
        </DashboardTile>
        <DashboardTile
          heading={"Most Sales Completed"}
          description={"The staff with most sales this week"}
        >
          Coming Soon
          {/* Rank Card */}
          {/* first place */}
          {/* <div className="flex relative p-2  border rounded-md bg-slate-100  flex-col space-y-2">
            <div>
              <div className="flex flex-row items-center space-x-2">
                <h1 className="text-xl font-medium">Admin</h1>
              </div>
            </div>
            <div className="flex flex-row justify-between items-center space-x-2">
              <div>
                <label className="block text-[10px] font-medium text-gray-700 dark:text-gray-200">
                  Total Sales
                </label>
                <p className="text-sm text-slate-900 dark:text-slate-200">
                  1000
                </p>
              </div>
              <div>
                <label className="block text-[10px] font-medium text-gray-700 dark:text-gray-200">
                  Total Sold
                </label>
                <p className="text-sm text-slate-900 dark:text-slate-200">
                  1000
                </p>
              </div>
              <div>
                <label className="block text-[10px] font-medium text-gray-700 dark:text-gray-200">
                  Total Revenue
                </label>
                <p className="text-sm text-slate-900 dark:text-slate-200">
                  1000
                </p>
              </div>
            </div>
          </div> */}
          {/* second place */}
        </DashboardTile>
        <DashboardTile
          heading={"Inventory Eagle View"}
          span={
            // check if mobile or not
            window.innerWidth < 768 ? 6 : 1
          }
          description={"Alerts for low inventory in Central inventory"}
          component={
            <div className="flex flex-row items-center space-x-2">
              <ChartSquareBarIcon className="h-5 w-5 text-slate-300" />
            </div>
          }
        >
          Coming Soon
        </DashboardTile>

        <DashboardTile
          heading={"Recent Sales"}
          span={
            // check if mobile or not
            window.innerWidth < 768 ? 6 : 2
          }
          description={"Get a quick overview of your recent Sales"}
          component={
            <div className="flex flex-row items-center space-x-2">
              <TableIcon className="h-5 w-5 text-slate-300" />
            </div>
          }
        >
          Coming soon
        </DashboardTile>
        <DashboardTile dark={false}>
          <h1>Most Sales by Employee Today</h1>
        </DashboardTile>
        <DashboardTile
          heading={"Recent Order Requests"}
          span={
            // check if mobile or not
            window.innerWidth < 768 ? 6 : 2
          }
          description={"Get a quick overview of your recent order requests"}
          component={
            <div className="flex flex-row items-center space-x-2">
              <TableIcon className="h-5 w-5 text-slate-300" />
            </div>
          }
        >
          Coming soon
        </DashboardTile>
        <Modal
          show={showOutletModal}
          setShowModal={outletModalChange}
          heading={"Select an Outlet"}
        >
          <div className="grid grid-cols-1 mb-0">
            <div className="space-y-2 relative">
              <>
                <div className="overflow-hidden rounded-lg  ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-2 bg-white px-5 py-2 sm:gap-2 sm:p-8">
                    {outlets?.map((outlet) => {
                      return (
                        <button
                          onClick={() => {
                            setShowOutletModal(false);
                            dispatch(setGlobalSelectedInventory(outlet));
                          }}
                          className={`flex flex-row  hover:bg-white items-center space-x-4 p-2 rounded-xl ${
                            selectedInventory?.id == outlet.id
                              ? "bg-white border-2 border-slate-400"
                              : ""
                          }`}
                        >
                          {selectedInventory?.id === outlet.id ? (
                            <CheckIcon className="h-6 w-6 text-yellow-500" />
                          ) : (
                            <div className="h-6 w-6"></div>
                          )}

                          <p className="text-xl font-medium text-slate-900">
                            {outlet.group?.name}
                          </p>
                        </button>
                      );
                    })}
                    {permissions?.includes("w-inventory") && (
                      <PrimaryButton
                        title={"Add Inventory Group"}
                        onClick={() => navigate("/outlets/add")}
                      />
                    )}
                  </div>
                </div>
              </>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
