const { createSlice } = require("@reduxjs/toolkit");
const customAxios = "middleware/customAxios";

const initialState = {
  categories: [],
  selectedProduct: {},
  userData: {},
  selectedInventory: {},
  orderRequestSelectedInventory: {},
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setGlobalSelectedInventory: (state, action) => {
      state.selectedInventory = action.payload;
      localStorage.setItem("selectedInventory", JSON.stringify(action.payload));
    },
    setOrderRequestSelectedInventory: (state, action) => {
      state.orderRequestSelectedInventory = action.payload;
    },
  },
});

// async thunk api call
export const fetchCategories = () => async (dispatch) => {
  const response = await customAxios.get("/admin/product-groups");
  dispatch(setCategories(response?.data?.data));
};

export const {
  setCategories,
  setSelectedProduct,
  setUserData,
  setGlobalSelectedInventory,
  setOrderRequestSelectedInventory,
} = dataSlice.actions;

export default dataSlice.reducer;
