import React from "react";
import FormGenerator from "components/form-generator/FormGenerator";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import customAxios from "middleware/customAxios";
import { useNavigate } from "react-router-dom";
import Card from "ui/Card";
import { MultiSelect } from "react-multi-select-component";
import DataGrid from "components/data-grid/DataGrid";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/solid";

export default function OutletADD() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let [selectedUsers, setSelectedUsers] = React.useState([]);
  let [users, setUsers] = React.useState([]);
  let [userData, setUserData] = React.useState([]);

  React.useEffect(() => {
    customAxios.get("/admin/users").then((res) => {
      let users = res.data.map((user) => {
        return {
          label: (
            <p>
              <span className="capitalize">{user.username}</span> -{" "}
              {user.userInfo?.firstName} {user.userInfo?.lastName} -
              {user.userInfo?.email}
            </p>
          ),
          value: user.id,
          data: user,
        };
      });
      setUsers(users);
    });
  }, []);

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "Add Inventory Group",
      })
    );
    dispatch(setNavigationComponents(null));
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Outlets",
          path: "/outlets",
        },
        {
          name: "Add Outlet",
          path: "/outletss/add",
        },
      ])
    );
  }, [users]);

  const formData = {
    contents: [
      {
        fields: [
          {
            name: "groupType",
            displayName: "Group Type",
            type: "comboBox",
            required: true,
            values: [
              {
                name: "Outlet",
                value: "Outlet",
              },
              {
                name: "Business Partner",
                value: "BP",
              },
              {
                name: "Supervisor Group",
                value: "SP",
              },
            ],
            value: (formik) => {
              return formik.values.groupType;
            },
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },
          {
            name: "name",
            displayName: "Name",
            type: "field",
            inputType: "string",
            required: true,
          },
          {
            name: "phone",
            displayName: "Phone",
            type: "field",
            inputType: "string",
            required: true,
          },

          {
            name: "state",
            displayName: "State",
            type: "field",
            inputType: "string",
            required: true,
          },
          {
            name: "address",
            displayName: "Address",
            type: "textArea",
            inputType: "string",
            required: true,
          },
          {
            name: "city",
            displayName: "City",
            type: "field",
            inputType: "string",
            required: true,
          },
        ],
      },
    ],
    initialValues: {
      name: "",
      phone: "",
      address: "",
      state: "",
      city: "",
      groupType: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      phone: Yup.string().required("Phone is required"),
      address: Yup.string().required("Address is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
    }),
    onSubmit: async (values) => {
      let users = selectedUsers.map((user) => {
        return user.data?.id;
      });

      let data = {
        name: values.name,
        description: values.description,
        groupType: values.groupType.value,
        address: values.address,
        phone: values.phone,
        city: values.city,
        state: values.state,
        users: users,
      };

      try {
        customAxios.post("/admin/user-group", data).then(
          (res) => {
            if (res) {
              // reset form
              navigate("/outlets");
            }
          },
          (err) => {
            console.log(err);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  };

  React.useEffect(() => {
    let users = selectedUsers.map((user) => {
      return {
        username: user.data?.username,
        firstName: user.data?.userInfo?.firstName,
        lastName: user.data?.userInfo?.lastName,
        email: user.data?.userInfo?.email,
        phone: user.data?.userInfo?.phone,
        address: user.data?.userInfo?.address,
        city: user.data?.userInfo?.city,
        state: user.data?.userInfo?.state,
      };
    });
    setUserData(users);
  }, [selectedUsers]);

  return (
    <div className="absolute flex flex-col space-y-3  align-top  min-h-[calc(100vh-120px)] rounded-xl top-0 w-full pb-10 bg-slate-50">
      <Card
        heading={"Add Inventory Group"}
        description={`Please fill the following details`}
      >
        <FormGenerator
          grids={2}
          modal={true}
          buttonSubmit={{ title: "Save Changes" }}
          formData={formData}
        />
      </Card>
      <Card
        heading={"Add Users to Inventory Group"}
        description={`Please fill the following details`}
      >
        <div className="space-y-3">
          <MultiSelect
            options={users || []}
            value={selectedUsers}
            onChange={setSelectedUsers}
            labelledBy={"Select"}
            placeholder={"Select Users"}
          />
          <DataGrid
            cellFunction={(cell) => {
              // if (cell.column.id === "actions") {
              //   return (
              //     <div className="flex flex-row space-x-3 justify-start items-center ">
              //       <button
              //         onClick={() => {
              //           let temp = [...userData];
              //           temp.splice(cell.row.index, 1);
              //           setUserData(temp);
              //           setSelectedUsers(temp);
              //         }}
              //         // disabled={userData.length === 1}
              //         className="flex flex-row items-center space-x-1 p-2  rounded-full text-white bg-red-500 hover:bg-red-600"
              //       >
              //         <TrashIcon className="w-5 h-5" />
              //       </button>
              //     </div>
              //   );
              // }
              return cell.value;
            }}
            x_columns={[
              {
                Header: "Username",
                accessor: "username",
              },
              {
                Header: "First Name",
                accessor: "firstName",
              },
              {
                Header: "Last Name",
                accessor: "lastName",
              },
              {
                Header: "Email",
                accessor: "email",
              },
              {
                Header: "Phone",
                accessor: "phone",
              },
              {
                Header: "Address",
                accessor: "address",
              },
              {
                Header: "State",
                accessor: "state",
              },
              {
                Header: "City",
                accessor: "city",
              },
              // {
              //   Header: "Actions",
              //   accessor: "actions",
              // },
            ]}
            testData={userData || []}
            showHeader={true}
          />
        </div>
      </Card>
    </div>
  );
}
