import React from "react";

export const columns = [
  {
    Header: "Name",
    accessor: "data.name",
    Footer: (info) => {
      return <h1 className="text-base font-bold">Total</h1>;
    },
  },
  {
    Header: "Code",
    accessor: "data.code",
  },
  {
    Header: "SKU",
    accessor: "data.sku",
  },
  {
    Header: "Unit Price",
    accessor: "data.price",
  },
  {
    Header: "Quantity",
    accessor: "data.quantity",
    Footer: (info) => {
      // Only calculate total visits if rows change
      const total = React.useMemo(
        () => info.rows.reduce((sum, row) => row.values.quantity + sum, 0),
        [info.rows]
      );

      return <div className="p-1 text-base"> {total}</div>;
    },
  },
  {
    Header: "Current Stock",
    accessor: "currentStock",
  },

  {
    Header: "Applied Discount",
    accessor: "data.appliedDiscount",
  },
  {
    Header: "Discount",
    accessor: "data.discount",
  },
  {
    Header: "Total",
    accessor: "data.total",
    Footer: (info) => {
      // Only calculate total visits if rows change
      const total = React.useMemo(
        () => info.rows.reduce((sum, row) => row.values.data?.total + sum, 0),
        [info.rows]
      );

      return <div className="p-1 text-base font-bold">ss</div>;
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];
