import React from "react";

export default function HeadCard({ title, description }) {
  return (
    <div className="flex flex-col space-y-1 pb-2 pl-2">
      <h1 className="text-xl font-medium capitalize">{title}</h1>
      {description && <p className="text-slate-600 text-sm">{description}</p>}
    </div>
  );
}
