import dayjs from "dayjs"
import { months } from "moment"


export default function MonthSelect ({handleMonthSelect, changeMonth}) {
    months = []

    for (let i = 0; i < 12; i++)
    {
        let monthName = dayjs().month(i).format('MMMM')
        months.push(monthName)
    }

    return (
        <>
            <div className="grid grid-cols-4 grid-rows-3 gap-x-1 gap-y-1">
                {
                    months.map((month, i) => (
                        <div onClick={() => {
                            handleMonthSelect();
                            changeMonth(i)
                        }

                        } 
                        className="text-center hover:bg-orange-50 cursor-pointer p-3">{month.substring(0, 3)}</div>
                    ))
                }
            </div>
        </>
    )
}