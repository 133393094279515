import { CheckIcon } from "@heroicons/react/outline";
import React from "react";

export default function Checkbox({ checked, onClick }) {
  return (
    <div
      onClick={onClick}
      className={`rounded border  min-w-5 min-h-5  h-5 w-5 ${
        checked ? "" : "hover:border-2"
      } hover:cursor-pointer }`}
    >
      {checked && (
        <CheckIcon
          className={`w-5 h-5 min-w-5 min-h-5 rounded p-0.5 text-white ${
            checked ? "bg-amber-400" : "bg-red-600"
          }`}
          aria-hidden="true"
        />
      )}
    </div>
  );
}
