import React from "react";
import FormGenerator from "components/form-generator/FormGenerator";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { MultiSelect } from "react-multi-select-component";

import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import Card from "ui/Card";
import DataGrid from "components/data-grid/DataGrid";
import { columns } from "./columns";
import { MinusIcon, PlusIcon, XIcon } from "@heroicons/react/outline";
import DropDownButton from "ui/buttons/DropDownButton";
import { PencilAltIcon } from "@heroicons/react/solid";
import { Combobox } from "@headlessui/react";
import axios from "axios";

export default function AdjustmentAdd() {
  let dispatch = useDispatch();
  let [total, setTotal] = React.useState(0);
  let [cart, setCart] = React.useState([]);
  let [cartData, setCartData] = React.useState([]);

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "Add Adjustment",
      })
    );
    dispatch(setNavigationComponents(null));
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Adjustments",
          path: "/sales",
        },
        {
          name: "Add Adjustment",
          path: "/products/adjustments/add",
        },
      ])
    );
  }, []);

  const formData = {
    contents: [
      {
        fields: [
          {
            name: "outlet",
            displayName: "Outlet",
            type: "comboBox",
            required: true,
            values:
              [
                {
                  name: "None",
                  id: 0,
                },
                {
                  name: "Category 1",
                  id: 1,
                },
                {
                  name: "Category 2",
                  id: 2,
                },
              ] ?? [],
            value: (formik) => {
              return formik.values.outlet;
            },
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },

          {
            name: "salesNote",
            displayName: "Adjutsment Note",
            type: "editor",
            value: (formik) => {
              return formik.values.description;
            },
          },
          {
            name: "Attachment",
            displayName: "Attachment",
            type: "fileUpload",
            value: (formik) => {
              return formik.values.image;
            },
          },
        ],
      },
    ],
    initialValues: {
      name: "",
      description: "",
      parentCategory: "",
      image: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().max(20).min(8).required("Password is required"),
      confirmPassword: Yup.string().required("Please confirm your password"),
    }),
    onSubmit: async (values, { setErrors }) => {
      console.log(values);
    },
  };

  React.useEffect(() => {
    if (cart.length > 0) {
      //  extract data from cart items
      let data = cart.map((item) => {
        return {
          name: item.name,
          description: item.description,
          variants: item.variants,
        };
      });
      setCartData(data);
    }
  }, [cart]);

  let options = [
    {
      id: 1,
      label: "HDMI Cable",
      value: 1,
      name: "HDMI Cable",
      description: "Adapter",
      variants: [
        {
          variant: "20 meter",
          quantity: 1,
          adjustmentType: "Add",
          stock: 1,
        },
        {
          variant: "100 meter",
          quantity: 1,
          adjustmentType: "Add",
          stock: 1,
        },
      ],
    },
    {
      id: 2,
      label: "Adapter",
      value: 2,
      name: "HDMI Cable",
      description: "Adapter",
      variants: [
        {
          variant: "20 meter",
          quantity: 1,
          adjustmentType: "Add",
          stock: 1,
        },
        {
          variant: "100 meter",
          quantity: 1,
          adjustmentType: "Add",
          stock: 1,
        },
      ],
    },
  ];

  return (
    <div className="absolute grid grid-cols-1 gap-10 pb-20  rounded-xl top-0 w-full bg-white">
      <div className="w-full">
        <FormGenerator
          grids={3}
          modal={true}
          buttonSubmit={{ title: "Proceed" }}
          formData={formData}
        />
      </div>

      <Card heading={"Create Adjustment"}>
        <MultiSelect value={cart} onChange={setCart} options={options} />
        <div className="w-full">
          <DataGrid
            showHeader={true}
            x_columns={[
              {
                Header: "Name",
                accessor: "name",
              },
              {
                Header: (
                  <DataGrid
                    table={false}
                    showHeader={true}
                    x_columns={[
                      {
                        Header: <p className="w-[150px]">Variant</p>,
                        accessor: "variant",
                      },
                      {
                        Header: "Quantity",
                        accessor: "quantity",
                      },
                      {
                        Header: "Adjustment Type",
                        accessor: "adjustmentType",
                      },
                      {
                        Header: "Current Stock",
                        accessor: "stock",
                      },
                    ]}
                    testData={[]}
                  />
                ),
                accessor: "variants",
              },
            ]}
            testData={cartData}
            cellFunction={(cell) => {
              if (cell.column.id == "variants") {
                return (
                  <DataGrid
                    second={false}
                    showHeader={false}
                    x_columns={[
                      {
                        Header: <p className="w-[150px]">Variant</p>,
                        accessor: "variant",
                      },
                      {
                        Header: "Quantity",
                        accessor: "quantity",
                      },
                      {
                        Header: "Adjustment Type",
                        accessor: "adjustmentType",
                      },
                      {
                        Header: "Current Stock",
                        accessor: "stock",
                      },
                    ]}
                    testData={cell.value}
                    cellFunction={(cell) => {
                      if (cell.column.id == "adjustmentType") {
                        return <p>Joh</p>;
                      }
                      if (cell.column.id == "variant") {
                        return <p className=" ">{cell.value}</p>;
                      }

                      return cell.value;
                    }}
                    showCard={false}
                  />
                );
              }
              return cell.value;
            }}
          />
        </div>
      </Card>
    </div>
  );
}
