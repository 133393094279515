import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import customAxios from "middleware/customAxios";
import Card from "ui/Card";
import { Popover } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";
import DataGrid from "components/data-grid/DataGrid";
import Loader from "components/loader/Loader";
import ReportGenerator from "./ReportGenerator";
import { replaceDashes } from "functions/replaceDashes";
import DatePickerV2 from "components/date-picker-v2/DatePickerV2";
import DatePickerMain from "components/date-picker-v2/DatePickerMain";
import moment from "moment";

export default function Reports() {
  let navigate = useNavigate();
  let { report } = useParams();
  let dispatch = useDispatch();
  let [data, setData] = React.useState([]);
  let userData = useSelector((state) => state.data.userData);
  let [loading, setLoading] = React.useState(true);
  let outlets = userData.outlets;
  let [selectedTimeline, setSelectedTimeline] = React.useState("Today");
  let [params, setParams] = React.useState({
    start: "",
    end: "",
    outlet: null,
    limit: 1000000,
  });
  let urlParams = new URLSearchParams();
  let [columns, setColumns] = React.useState([]);

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: `${replaceDashes(report)} Report`,
      })
    );
    dispatch(setNavigationComponents(null));
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Analytics",
          path: "/analytics",
        },
        {
          name: replaceDashes(report) + " Report",
          path: `/analytics/view/${report}`,
        },
      ])
    );
  });

  React.useEffect(() => {
    setParams({
      ...params,
      outlet: outlets ? outlets[0] : null,
    });
  }, []);

  //   Data Fetch
  React.useEffect(() => {
    urlParams.append("outlet", params.outlet?.group.id);
    urlParams.append("start", moment(params.start).format("YYYY-MM-DD"));
    urlParams.append("end", moment(params.end).format("YYYY-MM-DD"));
    urlParams.append("limit", params.limit);

    //   Fetch data
    customAxios
      .get("/admin/report/" + report + "/?" + urlParams)
      .then((res) => {
        setData(res.data);
        setColumns(res.columns);
        // chck
        setLoading(false);
      });
  }, [params, report]);

  return (
    <div className="absolute grid grid-cols-1 gap-10 z-50 h-[calc(100vh-200px)] bg-slate-50  rounded-xl top-0 w-full">
      <Card
        heading={replaceDashes(report)}
        description={"View " + replaceDashes(report) + " reports"}
      >
        <div className="flex flex-row justify-between items-center px-2">
          <div className="flex flex-row space-x-3 items-center ">
            {/* Select outlet */}
            <Popover className={"relative focus:outline-none font-sans"}>
              {({ open, close }) => (
                <>
                  <Popover.Button
                    className={`${
                      open ? "" : "text-opacity-70"
                    } group rounded-md inline-flex bg-white p-2 items-center text-slate-500 hover:text-slate-700 outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500`}
                  >
                    <h1 className="text-sm font-medium e focus:outline-none text-black">
                      {params.outlet
                        ? params.outlet?.group?.name
                        : "Select Destination"}
                    </h1>
                    <ChevronDownIcon
                      className={`${
                        open ? "" : "text-opacity-70"
                      } ml-2 h-5 w-5  text-slate-500 group-hover:text-slate-700`}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 top-12 -left-6">
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="relative grid gap-2 bg-white p-5">
                        {outlets
                          ?.filter((x) => x.group.groupType == "Outlet")
                          .map((outlet) => {
                            return (
                              <button
                                onClick={() => {
                                  setParams({
                                    ...params,
                                    outlet: outlet,
                                  });
                                  close();
                                }}
                                className={`flex flex-row  hover:bg-white items-center space-x-4 p-2 rounded-xl ${
                                  params.outlet?.id == outlet?.id
                                    ? "bg-white border-2 border-slate-400"
                                    : ""
                                }`}
                              >
                                {params?.outlet?.id === outlet?.id ? (
                                  <CheckIcon className="h-6 w-6 text-yellow-500" />
                                ) : (
                                  <div className="h-6 w-6"></div>
                                )}

                                <p className="text- font-medium text-slate-900">
                                  {outlet.group?.name}
                                </p>
                              </button>
                            );
                          })}
                      </div>
                    </div>
                  </Popover.Panel>
                </>
              )}
            </Popover>
            {/* Select Date Range */}
            <Popover className={"relative focus:outline-none font-sans"}>
              {({ open, close }) => (
                <>
                  <Popover.Button
                    className={`${
                      open ? "" : "text-opacity-70"
                    } group rounded-md inline-flex bg-white p-2 items-center text-slate-500 hover:text-slate-700 outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500`}
                  >
                    <h1 className="text-sm font-medium e focus:outline-none text-black">
                      {/* Select Date Range */}
                      {moment(params.start).format("DD MMMM yyyy") +
                        " - " +
                        moment(params.end).format("DD MMMM yyyy")}
                    </h1>
                    <ChevronDownIcon
                      className={`${
                        open ? "" : "text-opacity-70"
                      } ml-2 h-5 w-5  text-slate-500 group-hover:text-slate-700`}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Popover.Panel className="absolute z-10 w-max max-w-xl px-4 top-12 -left-6">
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className=" grid gap-2 bg-white p-5">
                        <div className="flex flex-row space-x-3  justify-between items-center">
                          <p
                            className={` text-sm    p-1 px-2 rounded-md  cursor-pointer ${
                              selectedTimeline === "Today"
                                ? "bg-yellow-500 text-white hover:bg-yellow-400"
                                : "bg-white hover:bg-slate-200 text-slate-900"
                            }`}
                            onClick={() => {
                              setParams({
                                ...params,
                                start: moment().format("YYYY-MM-DD"),
                                end: moment().format("YYYY-MM-DD"),
                              });
                              setSelectedTimeline("Today");
                              close();
                            }}
                          >
                            Today
                          </p>
                          <p
                            className={` text-sm    p-1 px-2 rounded-md  cursor-pointer ${
                              selectedTimeline === "Yesterday"
                                ? "bg-yellow-500 text-white hover:bg-yellow-400"
                                : "bg-white hover:bg-slate-200 text-slate-900"
                            }`}
                            onClick={() => {
                              setParams({
                                ...params,
                                start: moment().subtract(1, "days"),
                                end: moment().subtract(1, "days"),
                              });
                              setSelectedTimeline("Yesterday");
                              close();
                            }}
                          >
                            Yesterday
                          </p>
                          <p
                            className={` text-sm    p-1 px-2 rounded-md  cursor-pointer ${
                              selectedTimeline === "This Week"
                                ? "bg-yellow-500 text-white hover:bg-yellow-400"
                                : "bg-white hover:bg-slate-200 text-slate-900"
                            }`}
                            onClick={() => {
                              setParams({
                                ...params,
                                start: moment().startOf("week"),
                                end: moment().endOf("week"),
                              });
                              setSelectedTimeline("This Week");
                              close();
                            }}
                          >
                            This Week
                          </p>
                          <p
                            className={` text-sm    p-1 px-2 rounded-md  cursor-pointer ${
                              selectedTimeline === "Last Week"
                                ? "bg-yellow-500 text-white hover:bg-yellow-400"
                                : "bg-white hover:bg-slate-200 text-slate-900"
                            }`}
                            onClick={() => {
                              setParams({
                                ...params,
                                start: moment()
                                  .subtract(1, "week")
                                  .startOf("week"),
                                end: moment().subtract(1, "week").endOf("week"),
                              });
                              setSelectedTimeline("Last Week");
                              close();
                            }}
                          >
                            Last Week
                          </p>
                          <p
                            className={` text-sm    p-1 px-2 rounded-md  cursor-pointer ${
                              selectedTimeline === "This Month"
                                ? "bg-yellow-500 text-white hover:bg-yellow-400"
                                : "bg-white hover:bg-slate-200 text-slate-900"
                            }`}
                            onClick={() => {
                              setParams({
                                ...params,
                                start: moment().startOf("month"),
                                end: moment().endOf("month"),
                              });
                              setSelectedTimeline("This Month");
                              close();
                            }}
                          >
                            This Month
                          </p>
                        </div>
                        <div className="flex flex-row space-x-3 items-center ">
                          <DatePickerMain
                            selected={params.start}
                            onDateChange={(date) => {
                              setParams({
                                ...params,
                                start: date,
                              });
                              console.log(moment(date).format("YYYY-MM-DD"));
                            }}
                            onChange={(date) => {
                              setParams({
                                ...params,
                                start: date,
                              });
                              console.log(moment(date).format("YYYY-MM-DD"));
                            }}
                            dateFormat="dd/MM/yyyy"
                            className="w-32"
                          />
                          <p className="text-sm font-medium text-slate-900">
                            To
                          </p>
                          <DatePickerMain
                            selected={params.start}
                            onDateChange={(date) => {
                              setParams({
                                ...params,
                                end: date,
                              });
                              console.log(moment(date).format("YYYY-MM-DD"));
                            }}
                            dateFormat="dd/MM/yyyy"
                            className="w-32"
                          />
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </>
              )}
            </Popover>
          </div>

          <ReportGenerator
            headers={columns.map((column) => {
              return column;
            })}
            data={data}
          />
        </div>

        <div className="py-3">
          {data.length > 1 ? (
            <>
              {loading ? (
                <div className="flex justify-center items-center">
                  <Loader />
                </div>
              ) : (
                <DataGrid
                  showHeader={true}
                  testData={data}
                  x_columns={columns}
                  cellFunction={(cell) => {
                    if (cell.column.id == "createdAt") {
                      return new Date(cell.value).toLocaleDateString();
                    }
                    return <p className="capitalize">{cell.value}</p>;
                  }}
                  height={"h-[calc(100vh-380px)]"}
                />
              )}
            </>
          ) : (
            <div className="text-center space-y-2 p-5 h-[calc(100vh-310px)]">
              <h1 className="text-xl font-medium">
                No data found for the date range selected
              </h1>
              <p>
                Please select a different date range or contact your system
                admin
              </p>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}
