export const columns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Method",
    accessor: "method",
  },
  {
    Header: "Percentage",
    accessor: "percentage",
  },
  {
    Header: "Fixed Amount",
    accessor: "fixedAmount",
  },
  {
    Header: "List of Products",
    accessor: "products",
  },
  {
    Header: "Created By",
    accessor: "createdByUser.username",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];
