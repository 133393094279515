import { Popover } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import Modal from "components/modal/Modal";
import {
  addDiscountToItem,
  addToCart,
  removeFromCart,
  setGlobalAssignType,
  setGlobalSelectedSerializedProduct,
} from "features/cart/CartSlice";
import customAxios from "middleware/customAxios";
import React from "react";
import { MdDelete, MdRemove } from "react-icons/md";
import { useDispatch } from "react-redux";

export default function OrderItemCard({ item }) {
  const [discounts, setDiscounts] = React.useState([]);
  const [showDiscountModal, setShowDiscountModal] = React.useState(false);
  const [selectedDiscount, setSelectedDiscount] = React.useState(undefined);

  let dispatch = useDispatch();

  React.useEffect(() => {
    customAxios.get("/admin/discounts").then((res) => {
      setDiscounts(res.data);
    });
  }, []);

  return (
    <div className="relative grid grid-cols-12 border gap- bg-white p-2 rounded-md">
      {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
      {/* // Order item */}
      <div className="flex flex-row space-x-3 col-span-5">
        <div
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_SERVER_URL + item?.image
            })`,
          }}
          className="bg-cover w-20 h-20 bg-center bg-white rounded-xl flex items-center justify-center"
        />
        <div className=" flex justify-center items-start flex-col space-y-0">
          <h1 className="text-sm font-light">{item?.productName}</h1>
          <h1 className="text-sm font-bold">{item?.name}</h1>

          {/* // Price */}
          <div className=" ">
            {item.appliedDiscount !== undefined && (
              <p className="text-slate-800 line-through">MVR {item?.price}</p>
            )}

            <p className="text-slate-800 ">
              MVR {item?.discountPrice ?? item.price}
            </p>
          </div>
        </div>
      </div>
      {/* discount */}
      <div className="col-span-4 flex items-center justify-center">
        <div>
          {selectedDiscount === undefined ? (
            <button
              onClick={() => setShowDiscountModal(true)}
              className="p-1 px-2 border bg-white border-slate-400 rounded-lg text-[10px]"
            >
              No Selected Discount
            </button>
          ) : (
            <p
              onClick={() => setShowDiscountModal(true)}
              className="p-1 px-2 border bg-white uppercase font-medium border-slate-400 rounded-lg text-sm"
            >
              {selectedDiscount.name}
            </p>
          )}
        </div>
      </div>
      {/* // Quantity */}
      {!item.isSerialized ? (
        <div className="col-span-3 flex justify-center items-center flex-col space-y-1">
          <div className="flex flex-row space-x-3  p-1.5 bg-white rounded-full h-fit justify-center items-center">
            {/* Minus Button */}
            {item?.quantity === 1 ? (
              <div
                onClick={() => {
                  dispatch(
                    removeFromCart({
                      id: item.id,
                      image: item.image,
                      productName: item.variantName,
                      name: item.name,
                      price: item.price,
                      unitPrice: item.unitPrice,
                      quantity: 1,
                    })
                  );
                }}
                className="flex items-center hover:cursor-pointer hover:ring-2 ring-yellow-500 ring-offset-2 justify-center text-white p-1.5 rounded-full bg-red-500"
              >
                <MdDelete className="w-4 h-4 rounded-full text-primary" />
              </div>
            ) : (
              <button
                onClick={() => {
                  dispatch(
                    removeFromCart({
                      id: item.id,
                      image: item.image,
                      productName: item.variantName,
                      name: item.name,
                      price: item.price,
                      unitPrice: item.unitPrice,
                      quantity: 1,
                    })
                  );
                }}
                className="flex items-center hover:cursor-pointer hover:ring-2 ring-yellow-500 active:brightness-110 ring-offset-2 justify-center text-white p-1.5 rounded-full bg-yellow-500"
              >
                <MinusIcon className="w-4 h-4 rounded-full text-slate-50" />
              </button>
            )}

            {/* Quantity */}
            <div className="flex items-center justify-center ">
              <p className="text-slate-900">{item?.quantity}</p>
            </div>

            {/* Plus Button */}
            <div
              onClick={() => {
                dispatch(
                  addToCart({
                    id: item.id,
                    image: item.image,
                    productName: item.variantName,
                    name: item.name,
                    price: item.price,
                    quantity: 1,
                  })
                );
              }}
              className="flex items-center hover:cursor-pointer hover:ring-2 ring-yellow-500 active:brightness-110 ring-offset-2 justify-center text-white p-1.5 rounded-full bg-yellow-500"
            >
              <PlusIcon className="w-4 h-4 " />
            </div>
          </div>
        </div>
      ) : (
        <div className="col-span-3 flex justify-center items-center flex-col space-y-1">
          <div className="flex flex-row space-x-3  p-1.5 bg-white rounded-full h-fit justify-center items-center">
            <div
              onClick={() => {
                dispatch(
                  removeFromCart({
                    id: item.id,
                    image: item.image,
                    productName: item.variantName,
                    name: item.name,
                    price: item.price,
                    unitPrice: item.unitPrice,
                    quantity: 1,
                  })
                );
                dispatch(setGlobalAssignType(null));
                dispatch(setGlobalSelectedSerializedProduct(null));
              }}
              className="flex items-center hover:cursor-pointer hover:ring-2 ring-yellow-500 ring-offset-2 justify-center text-white p-1.5 rounded-full bg-red-500"
            >
              <MdDelete className="w-4 h-4 rounded-full text-primary" />
            </div>
          </div>
        </div>
      )}

      <Modal
        heading={"Select Discount"}
        show={showDiscountModal}
        setShow={setShowDiscountModal}
      >
        <div className="grid grid-cols-4 gap-3 p-8">
          {discounts.map((discount) => (
            <div
              onClick={() => {
                setSelectedDiscount(discount);
                setShowDiscountModal(false);
                dispatch(
                  addDiscountToItem({
                    id: item.id,
                    image: item.image,
                    productName: item.variantName,
                    name: item.name,
                    price: item.price,
                    unitPrice: item.unitPrice,
                    quantity: item.quantity,
                    appliedDiscount: discount,
                    quantity: item.quantity,
                  })
                );
              }}
              className={`${
                selectedDiscount?.id === discount?.id
                  ? "bg-slate-900 text-white"
                  : "bg-white text-slate-900"
              } flex flex-col items-start hover:shadow-lg cursor-pointer justify-center space-y-2 p-5  shadow-md border border-slate-200   rounded-xl`}
            >
              <p className="text-sm font-bold">{discount.name}%</p>
              <p className="text-sm font-light">
                {discount?.fixedAmount === null
                  ? discount.percentage + "%"
                  : "MVR " + discount?.fixedAmount}
              </p>
            </div>
          ))}
          <button
            onClick={() => {
              setSelectedDiscount(undefined);
              setShowDiscountModal(false);
            }}
          >
            Remove Discount
          </button>
        </div>

        <button
          onClick={() => setShowDiscountModal(false)}
          className="fixed top-6 right-6"
        >
          <XIcon className="w-5 h-5" />
        </button>
      </Modal>
      {/* Sub total and Tax */}
    </div>
  );
}
