import DataGrid from "components/data-grid/DataGrid";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import { itemRender } from "functions/ItemRender";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";
import SecondaryButton from "ui/buttons/SecondaryButton";
import Card from "ui/Card";

let productData = {
  id: 1,
  name: "HDMI Cable",
  description: "HDMI Cable",
  image: "https://m.media-amazon.com/images/I/61HwpDbhcBL.jpg",
  variants: [
    {
      id: 1,
      title: "HDMI Cable 1",
      price: 100,
      stock: 10,
    },
    {
      id: 2,
      title: "HDMI Cable 2",
      price: 200,
      stock: 20,
    },
  ],
};

export default function CategoriesView() {
  let dispatch = useDispatch();
  let category = useLocation().state;

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: productData.name,
      })
    );
    dispatch(
      setNavigationComponents(<PrimaryButton title={"Request for Stock"} />)
    );
    dispatch(
      setBreadCrumbs([
        {
          name: "Products",
          link: "/products",
        },
        {
          name: productData.name,
          link: `/products/${productData.id}`,
        },
      ])
    );
    dispatch(
      setNavigationComponents(
        <div className="flex flex-row space-x-3">
          <PrimaryButton title={"Edit"} />
          <SecondaryButton title={"Delete"} />
        </div>
      )
    );
  }, []);

  const cellFunction = (cell) => {
    if (cell.column.id == "title") {
      const urlParams = new URLSearchParams();
      urlParams.append("title", cell.row.original.title);
      return (
        <div className="flex flex-row py-1 text-sm space-x-3 items-center">
          <img
            className="h-14 w-14 border border-slate-200 rounded-md "
            src={
              cell.row.original?.image
                ? cell.row.original?.image
                : "https://www.mydthpay.com/img/review.png"
            }
          />
          <Link
            to={
              `/products/${cell.row.original.id}` + "?" + urlParams.toString()
            }
            className=" hover:cursor-pointer hover:underline"
          >
            {cell.value}
          </Link>
        </div>
      );
    }
    if (cell.column.id == "variants") {
      return (
        <div className="flex flex-row py-1 text-sm space-x-3 items-center">
          {cell.value.map((variant, i) => {
            return (
              <div className="flex flex-row space-x-2 items-center">
                {variant.title}
              </div>
            );
          })}
        </div>
      );
    }

    return cell.value;
  };

  return (
    <div className="absolute bg-white rounded-xl top-0 w-full ">
      <div className="grid grid-cols-1 gap-3">
        <Card heading={"Category Information"}>
          <div className="grid grid-cols-6 gap-4">
            <div>
              <img
                className="w-40 h-40 rounded-md"
                src={category.image}
                alt="product"
              />
            </div>
            <div className="col-span-5 grid grid-cols-2">
              {itemRender("Name", category.name)}
              {itemRender("Description", category.description)}
              {itemRender("Added On", category.created_at)}
              {itemRender("Updated On", category.updated_at)}
            </div>
          </div>
        </Card>
        <Card heading={"Product under Category"}>
          <DataGrid
            x_columns={[
              {
                Header: "Title",
                accessor: "title",
              },
              {
                Header: "Description",
                accessor: "description",
              },
              {
                Header: "Variants",
                accessor: "variants",
              },

              {
                Header: "UpdatedOn",
                accessor: "updatedAt",
              },
              {
                Header: "Added On",
                accessor: "createdAt",
              },
            ]}
            testData={[
              {
                id: 1,
                title: "HDMI Cable",
                image: "https://m.media-amazon.com/images/I/61HwpDbhcBL.jpg",
                description: "HDMI Cable used for connecting devices",
                variants: [
                  {
                    id: 1,
                    title: "HDMI Cable 1",
                  },
                  {
                    id: 2,
                    title: "HDMI Cable 2",
                  },
                ],
                createdAt: "2021-01-01",
                updatedAt: "2021-01-01",
              },
            ]}
            showHeader={true}
            cellFunction={(cell) => cellFunction(cell)}
          />
        </Card>
      </div>
    </div>
  );
}
