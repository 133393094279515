import React from "react";
import { useSelector } from "react-redux";

export default function PrimaryButton({
  title,
  type,
  onClick,
  disabled,
  width,
}) {
  let isPOS = useSelector((state) => state.navigation.isPOS);
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      style={{
        width: width ?? "auto",
        fontSize: isPOS ? "1rem" : "0.8rem",
        padding: isPOS ? "0.7rem 1rem" : "0.7rem 2rem",
      }}
      className="px-4 bg-slate-800 dark:bg-yellow-300  py-4  text-sm md:text-sm flex items-center justify-center text-slate-50 dark:text-slate-700 font-semibold rounded-md hover:brightness-150  dark:hover:brightness-95"
    >
      {title}
    </button>
  );
}
