import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function BreadCrumbs() {
  let breadCrumbs = useSelector((state) => state.navigation.breadCrumbs);
  return (
    <div className="text-slate-600 dark:text-slate-300 flex space-x-3 text-sm md:text-sm justify-start items-center">
      {breadCrumbs?.map((b, i) => {
        return (
          <div
            key={i}
            className="flex flex-row space-x-1 justify-start items-center"
          >
            <Link
              className={`${
                i !== breadCrumbs.length - 1 ? "hover:text-slate-300" : ""
              }`}
              to={b.link}
            >
              {b.name}
            </Link>
            {/* Check of item is the last index */}
            {i !== breadCrumbs.length - 1 && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            )}
          </div>
        );
      })}
    </div>
  );
}
