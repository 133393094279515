import Modal from "components/modal/Modal";
import {
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import { itemRender } from "functions/ItemRender";
import React from "react";
import { useDispatch } from "react-redux";
import PrimaryButton from "ui/buttons/PrimaryButton";
import SecondaryButton from "ui/buttons/SecondaryButton";
import Card from "ui/Card";

export default function SettingsGeneral() {
  let [showModal, setShowModal] = React.useState(false);
  let [gst, setGST] = React.useState(8);
  let modalChange = () => {
    setShowModal(!showModal);
  };
  let dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "General",
        path: "/settings/general",
      })
    );
    dispatch(setNavigationComponents(null));
  }, []);
  return (
    <div>
      <Card
        heading={"General Service Tax Rate"}
        description={
          "This is the GST rate that will be applied to all products and services."
        }
        component={
          <PrimaryButton onClick={() => modalChange()} title={"Change"} />
        }
      >
        {itemRender("GST Rate", "8%")}
      </Card>

      <Modal
        show={showModal}
        setShowModal={modalChange}
        heading="Change GST Rate"
        subHeading="Please enter the new GST rate. This will be applied to all products and services."
      >
        <div className="p-5">
          <div class="mt-1 flex flex-row space-x-2 justify-start items-center font-bold">
            <input
              id="gst"
              name="gst"
              type="numeric"
              autocomplete="off"
              required
              autoFocus={true}
              value={gst}
              onChange={(e) => {
                setGST(e.target.value);
              }}
              class="shadow-sm focus:ring-slate-500 focus:ring-2 p-2 focus:outline-none block w-fit border-gray-300 rounded-md"
            />
            <p>%</p>
          </div>
        </div>
        <div class="bg-gray-50 sticky bottom-0 w-full px-4 py-3 md:flex grid grid-cols-2 gap-3  justify-end items-center flex-row space-x-3">
          <SecondaryButton onClick={modalChange} title={"Cancel"} />

          <PrimaryButton title={"Submit"} />
        </div>
      </Modal>
    </div>
  );
}
