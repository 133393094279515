import {
  setCurrent,
  setIsPOS,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import jwtDecode from "jwt-decode";
import React from "react";
import { useDispatch } from "react-redux";
import CentralDashboard from "./dashboard/CentralDashboard";
import { setUserData } from "features/data/DataSlice";
import { useSelector } from "react-redux";

export default function HomeIndex() {
  let dispatch = useDispatch();
  let token = localStorage.getItem("token");

  let decodedToken = null;

  if (token) {
    decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      // get the current path
      let currentPath = window.location.pathname;
      // if the current path is not login, then redirect to login
      let urlParams = new URLSearchParams();
      urlParams.append("redirectURL", currentPath);
      localStorage.removeItem("token");
      window.location.href = "/login" + "?" + urlParams.toString();
    }
  } else {
    window.location.href = "/auth/login";
  }

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "Dashboard",
      })
    );
    dispatch(setNavigationComponents(null));
    dispatch(setIsPOS(false));
    dispatch(
      setUserData({
        name: decodedToken?.name,
        id: decodedToken?.id,
        outlets: decodedToken?.outlets,
        permissions: decodedToken?.permissions,
      })
    );
  }, []);

  return (
    <div>
      <CentralDashboard data={decodedToken} />
    </div>
  );
}
