import Tabs from "components/tab/Tabs";
import {
  addToCart,
  setGlobalAssignType,
  setGlobalSelectedCustomer,
  setGlobalSelectedSerializedProduct,
} from "features/cart/CartSlice";
import { setIsPOS } from "features/navigation/navigationSlice";
import customAxios from "middleware/customAxios";
import ComboBoxComponent from "components/combo-box/ComboBoxComponent";
import { Combobox, Popover } from "@headlessui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckIcon, ChevronDownIcon, XIcon } from "@heroicons/react/solid";
import Modal from "components/modal/Modal";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { itemRender } from "functions/ItemRender";
import SecondaryButton from "ui/buttons/SecondaryButton";
import { setGlobalSelectedInventory } from "features/data/DataSlice";

export default function POSIndex() {
  let navigate = useNavigate();
  let cart = useSelector((state) => state.cart);
  let [showOutletModal, setShowOutletModal] = React.useState(false);
  let dispatch = useDispatch();
  let data = useSelector((state) => state.data.userData);
  let [outlets, setOutlets] = React.useState([]);
  let [items, setItems] = React.useState([]);
  let [loading, setLoading] = React.useState(true);
  let [selectedCustomer, setSelectedCustomer] = React.useState(null);
  let [customerQuery, setCustomerQuery] = React.useState("");
  let [customers, setCustomers] = React.useState([]);
  let [selectedInventory, setSelectedInventory] = React.useState(null);
  let permissions = data?.permissions;
  let [assignType, setAssignType] = React.useState("");
  let [show, setShow] = React.useState(false);
  let [serializedProducts, setSerializedProducts] = React.useState([]);
  let [selectedItem, setSelectedItem] = React.useState(null);
  let [selectedSerializedProduct, setSelectedSerializedProduct] =
    React.useState({});

  let [cartHasSerializedProduct, setCartHasSerializedProduct] =
    React.useState(false);

  React.useEffect(() => {
    let cartSerializedProducts = cart.items.find((x) => x.isSerialized);
    if (cartSerializedProducts) {
      setCartHasSerializedProduct(true);
    }
  }, [cart]);

  function modalChange() {
    setShow(false);
  }

  function outletModalChange() {
    setShowOutletModal(false);
  }

  React.useEffect(() => {
    setOutlets(
      data.outlets?.filter((outlet) => {
        return outlet?.group?.name != "Medianet";
      })
    );
  }, [data]);

  React.useEffect(() => {
    customAxios
      .get(
        "/admin/serialized-products/warehouse/" + selectedInventory?.group?.id
      )
      .then((res) => {
        let temp = res.map((x) => {
          return {
            id: x.id,
            label: x.stbSerialNumber,
            name: x.stbSerialNumber,
            productVariant: x.productVariant,
            type: x.type,
            sncSerialNumber: x.sncSerialNumber,
            stbSerialNumber: x.stbSerialNumber,
          };
        });
        setSerializedProducts(temp);
      });
  }, [assignType, selectedInventory]);

  React.useEffect(() => {
    dispatch(setIsPOS(true));
    if (selectedInventory == null) {
      if (outlets.length > 0) {
        setShowOutletModal(true);
      } else {
      }
    }
  }, [outlets]);

  React.useEffect(() => {
    let urlParams = new URLSearchParams();
    urlParams.append("outlet", selectedInventory?.group?.id);
    customAxios
      .get("/admin/product-variants?" + urlParams.toString())
      .then((res) => {
        setItems(res.data);
        setLoading(false);
      });
  }, [selectedInventory]);

  let serializedOptions = [
    {
      type: "new connection",
      icon: "https://www.svgrepo.com/show/478228/connection-2.svg",
    },
    {
      type: "migration",
      icon: "https://www.svgrepo.com/show/182863/exchange-change.svg",
    },
    {
      type: "replacement",
      icon: "https://www.svgrepo.com/show/478228/connection-2.svg",
    },
    {
      type: "card assignment",
      icon: "https://www.svgrepo.com/show/478228/card.svg",
    },
  ];

  function renderConnectionType() {
    return (
      <>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            STB Serial Number
          </label>
          <Combobox
            onChange={(e) => {
              setSelectedSerializedProduct(e);
              dispatch(setGlobalSelectedSerializedProduct(e));
            }}
          >
            <ComboBoxComponent
              options={function (item) {
                return (
                  <div className="grid grid-cols-12">
                    <span className="col-span-12">{item.name}</span>
                  </div>
                );
              }}
              values={serializedProducts.filter(
                (x) => x.productVariant.id == selectedItem.id
              )}
            />
          </Combobox>
        </div>
        {selectedSerializedProduct && (
          <div className="space-y-3 flex justify-end flex-col items-end">
            <div className="w-full bg-slate-50 p-5 grid grid-cols-5 gap-3 rounded-xl">
              <img
                src={
                  process.env.REACT_APP_SERVER_URL +
                  selectedSerializedProduct?.productVariant?.image
                }
                className="w-20 object-contain"
              />
              <div className="grid grid-cols-3 col-span-4 gap-3">
                {itemRender(
                  "Product",
                  selectedSerializedProduct?.productVariant?.name
                )}
                {itemRender(
                  "Full Box",
                  selectedSerializedProduct?.isFullBox ? "Yes" : "No"
                )}
                {itemRender(
                  "STB Serial Number",
                  selectedSerializedProduct?.name
                )}
                {itemRender("Type", selectedSerializedProduct?.type)}
                {itemRender(
                  "SNC Serial Number",
                  selectedSerializedProduct?.sncSerialNumber
                )}
              </div>
            </div>
            <div className="flex flex-row  items-center space-x-3">
              <SecondaryButton
                title={"Go Back"}
                onClick={() => {
                  setAssignType("");
                  setSelectedSerializedProduct({});
                }}
              />
              <PrimaryButton
                title={"Assign"}
                onClick={() => {
                  // check if cart item has a serialized product

                  dispatch(
                    addToCart({
                      id: selectedItem.id,
                      image: selectedItem.image,
                      productName: selectedItem.product.name,
                      name: selectedItem.name,
                      price: selectedItem.price,
                      unitPrice: selectedItem.price,
                      isSerialized: selectedItem.isSerialized,
                      discount: selectedItem.discount ?? null,
                      quantity: 1,
                    })
                  );
                  console.log("e");
                  setShow(false);
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  }

  function renderAssignType() {
    switch (assignType) {
      case "migration":
        return (
          <div className="space-y-3">
            <p>Are you sure you want to set this connection as migration?</p>
            {renderConnectionType()}
          </div>
        );
      case "new connection":
        return (
          <div className="  space-y-3 overflow-auto ">
            {renderConnectionType()}
          </div>
        );
      case "replacement":
        return (
          <div className="  space-y-3 overflow-auto ">
            {renderConnectionType()}
          </div>
        );
      default:
        return <p>jdj</p>;
    }
  }

  React.useEffect(() => {
    let urlParams = new URLSearchParams();
    urlParams.append("accountNumber", customerQuery);

    if (customerQuery.length > 3) {
      customAxios.get("/admin/customers?" + urlParams).then((res) => {
        setCustomers(res);
      });
    }
  }, [customerQuery]);

  return (
    <div className="space-y-3 w-full ">
      <div className="flex justify-between py-3  ">
        <div className="items-start space-y-2 flex flex-col justify-center">
          <h1 className="text-2xl font-bold ">Medianet POS</h1>
          <h5
            onClick={() => {
              outlets.length > 0 && setShowOutletModal(true);
            }}
            className="text-sm  p-1 bg-slate-800 rounded-md px-4 text-white"
          >
            {selectedInventory?.group?.name ?? "Medianet"}
          </h5>
        </div>
        <div>
          <Popover className={"relative focus:outline-none "}>
            {({ open, close }) => (
              <>
                <Popover.Button
                  className={`w-full ${
                    open ? "" : "text-opacity-70"
                  } flex flex-row py-2`}
                >
                  <div className="flex flex-row space-x-3 items-center w-full">
                    <div className="flex flex-col border bg-white border-slate-300 w-full p-2 rounded-md space-y-1">
                      <h1 className="text-sm text-start ">
                        {selectedCustomer?.name ? (
                          <div className="font-bold space-x-3 text-base px-2 flex flex-row items-center ">
                            <p className="font-bold ">Customer:</p>
                            <p className="text-slate-600">
                              {selectedCustomer?.name}
                            </p>
                          </div>
                        ) : (
                          <p className="text-slate-600 px-4 text-base">
                            Please Select a Customer
                          </p>
                        )}
                      </h1>
                    </div>
                  </div>
                </Popover.Button>
                <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 top-14 -right-0">
                  <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="relative grid gap-2 w-inherit bg-white  p-2 ">
                      <div className="flex flex-row space-x-3 items-center">
                        <div className="flex text-sm flex-col space-y-2 w-full">
                          <input
                            className="w-full border border-slate-300 rounded-md p-2"
                            autoFocus={true}
                            placeholder="Search Customer"
                            value={customerQuery}
                            type="number"
                            onChange={(e) => {
                              if (e.target.value.length > 6) return;
                              setCustomerQuery(e.target.value);
                            }}
                            maxLength={6}
                          />
                          <div className="flex flex-col text-sm space-y-2">
                            <p>Results</p>
                            {customers.length === 0 ? (
                              <p className="text-yellow-600">
                                No Customer Found
                              </p>
                            ) : (
                              <>
                                {customers?.map((customer) => {
                                  return (
                                    <button
                                      onClick={() => {
                                        setSelectedCustomer(customer);
                                        dispatch(
                                          setGlobalSelectedCustomer(customer)
                                        );
                                        close();
                                      }}
                                      className={`flex flex-row  hover:bg-white items-center space-x-4 p-2 rounded-xl ${
                                        selectedCustomer?.id == customer?.id
                                          ? "bg-white border-2 border-slate-400"
                                          : ""
                                      }`}
                                    >
                                      {selectedCustomer?.id === customer?.id ? (
                                        <CheckIcon className="h-6 w-6 text-yellow-500" />
                                      ) : (
                                        <div className="h-6 w-6"></div>
                                      )}

                                      <p className="text-sm font-medium text-slate-900">
                                        {customer?.name} -{" "}
                                        {customer?.accountNumber}
                                      </p>
                                    </button>
                                  );
                                })}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </>
            )}
          </Popover>
        </div>
      </div>

      <div
        className={`overflow-auto max-h-[calc(100vh-120px)] pb-10 col-span-7 align-top grid grid-cols-3 md:grid-cols-4 gap-2`}
      >
        {loading ? (
          <div className="text-center">Loading...</div>
        ) : (
          <>
            {items.length != 0 && (
              <>
                {items
                  .filter((x) => x.inventory?.quantity > 0)
                  ?.map((item, i) => (
                    <div
                      key={i}
                      className={`${
                        item.isSerialized ? " bg-red-400" : "bg-white"
                      }h-fit relative cursor-pointer bg-white hover:shadow-xl  p-2 rounded-md border border-slate-200`}
                    >
                      {/* create a gradient */}
                      {item?.inventory?.quantity == 0 && (
                        <div className="absolute top-0 left-0 w-full h-full bg-slate-700 opacity-50 rounded-md">
                          <div className="absolute top-0 z-50 left-0 w-full h-full flex flex-col justify-center items-center">
                            <p className="text-white text-xl font-bold">
                              Out of Stock
                            </p>
                          </div>
                        </div>
                      )}

                      <div
                        onClick={() => {
                          if (item?.inventory?.quantity > 0) {
                            if (item.isSerialized) {
                              let serializedProductFound = cart.items.filter(
                                (x) => x.isSerialized
                              );
                              console.log(cart.items);
                              console.log(
                                "serializedProductFound",
                                serializedProductFound
                              );
                              if (serializedProductFound?.length >= 1) {
                                setShow(false);
                              } else {
                                if (item.isSerialized) {
                                  setSelectedItem(item);
                                  setShow(true);
                                }
                              }
                              setSelectedItem(item);
                            } else {
                              dispatch(
                                addToCart({
                                  id: item.id,
                                  image: item.image,
                                  productName: item.product.name,
                                  name: item.name,
                                  price: item.price,
                                  unitPrice: item.price,
                                  isSerialized: item.isSerialized,
                                  discount: item.discount ?? null,
                                  quantity: 1,
                                })
                              );
                            }
                          } else {
                            alert("Out of Stock");
                          }
                        }}
                        className="flex flex-col w-full  space-y-2"
                      >
                        <div
                          style={{
                            backgroundImage: `url(${
                              process.env.REACT_APP_SERVER_URL + item.image
                            })`,
                          }}
                          className="flex items-center border p-2 border-slate-200 justify-center bg-center bg-gradient-to-b from-slate-700 via-slate-800 to-slate-900 bg-contain bg-no-repeat h-[calc(180px)] w-full rounded-md   "
                        ></div>

                        <div className="p-1">
                          <div className="flex justify-between">
                            <p className="text-sm text-slate-400">
                              {item.product.name}
                            </p>
                            <p className="text-green-600 text-sm">MVR</p>
                          </div>
                          <div className="flex justify-between space-x-2">
                            <p className=" grow truncate  ">{item.name}</p>

                            <p className=" shrink flex-none font-semibold  ">
                              {item.price}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            )}
          </>
        )}
      </div>

      <Modal
        show={showOutletModal}
        setShowModal={outletModalChange}
        heading={"Select an Outlet"}
      >
        <div className="grid grid-cols-1 mb-0">
          <div className="space-y-2 relative">
            <>
              <div className="overflow-hidden rounded-lg  ring-1 ring-black ring-opacity-5">
                <div className="relative grid gap-2 bg-white px-5 py-2 sm:gap-2 sm:p-8">
                  {outlets?.map((outlet) => {
                    return (
                      <button
                        onClick={() => {
                          setSelectedInventory(outlet);
                          setShowOutletModal(false);
                          dispatch(setGlobalSelectedInventory(outlet));
                        }}
                        className={`flex flex-row  hover:bg-white items-center space-x-4 p-2 rounded-xl ${
                          selectedInventory?.id == outlet.id
                            ? "bg-white border-2 border-slate-400"
                            : ""
                        }`}
                      >
                        {selectedInventory?.id === outlet.id ? (
                          <CheckIcon className="h-6 w-6 text-yellow-500" />
                        ) : (
                          <div className="h-6 w-6"></div>
                        )}

                        <p className="text-xl font-medium text-slate-900">
                          {outlet.group?.name}
                        </p>
                      </button>
                    );
                  })}
                  {permissions?.includes("w-inventory") && (
                    <PrimaryButton
                      title={"Add Inventory Group"}
                      onClick={() => navigate("/outlets/add")}
                    />
                  )}
                </div>
              </div>
            </>
          </div>
        </div>
      </Modal>

      {/* Serialized Product Assign Modal */}
      <Modal
        show={show}
        setShowModal={modalChange}
        heading={"Please Assign the Serialized Products"}
        subHeading={"Please proceed with one of the options below"}
      >
        <div
          className={`grid ${
            assignType == "" ? "grid-cols-4" : "grid-cols-1"
          } gap-3 p-5`}
        >
          {assignType == "" ? (
            <>
              {serializedOptions.map((option) => (
                <div
                  onClick={() => {
                    setAssignType(option.type);
                    dispatch(setGlobalAssignType(option.type));

                    // if assign type is migration
                  }}
                  className="flex flex-col border-slate-100 p-5 capitalize border hover:bg-slate-50 cursor-pointer  items-center space-y-2"
                >
                  <img src={option.icon} className="h-20 w-20" />
                  <p>{option.type}</p>
                </div>
              ))}
              <button
                onClick={() => setShow(false)}
                className="fixed top-10 right-10"
              >
                <XIcon className="h-6 w-6 text-red-500" />
              </button>
            </>
          ) : (
            <div className="w-full">{renderAssignType()}</div>
          )}
        </div>
      </Modal>
    </div>
  );
}
