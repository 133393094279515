import { XIcon } from "@heroicons/react/solid";
import DataGrid from "components/data-grid/DataGrid";
import Modal from "components/modal/Modal";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import { itemRender } from "functions/ItemRender";
import customAxios from "middleware/customAxios";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";
import SecondaryButton from "ui/buttons/SecondaryButton";
import Card from "ui/Card";
import { useReactToPrint } from "react-to-print";
import Invoice from "components/printables/Invoice";
import { useRef } from "react";
import { cellFunction } from "./CellFunction";
import BillPrint from "components/pos/order/components/components/BillPrint";

export default function SalesView() {
  let dispatch = useDispatch();
  let [data, setData] = React.useState({});
  const componentRef = useRef();
  const componentRefBill = useRef();
  let [loading, setLoading] = React.useState(true);
  let [orderProducts, setOrderProducts] = React.useState([]);
  let userData = useSelector((state) => state.data.userData);
  let permissions = userData?.permissions;
  let [show, setShowModal] = React.useState(false);
  let [trigger, setTrigger] = React.useState(Math.random());
  let [paymentVerificationData, setPaymentVerificationData] = React.useState(
    {}
  );
  let [remark, setRemark] = React.useState("");

  let [modalContent, setModalContent] = React.useState({
    heading: "",
    subHeading: "",
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrintBill = useReactToPrint({
    content: () => componentRefBill.current,
  });

  function modalChange() {
    setShowModal(!show);
  }

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "View" + " " + data.referenceNumber,
      })
    );

    dispatch(
      setBreadCrumbs([
        {
          name: "Sales",
          link: "/sales",
        },
        {
          name: "View" + " " + data.referenceNumber,
          link: `/saless/view/${data.id}`,
        },
      ])
    );
    if (data?.isVoid) {
      dispatch(setNavigationComponents(<></>));
    } else {
      dispatch(
        setNavigationComponents(
          <div className="flex flex-row space-x-2">
            <div
              onClick={() => {
                setModalContent({
                  heading: "Void Sale",
                  subHeading: "Are you sure you want to void this sale?",
                  remark: true,
                  primary: {
                    title: "Void Sale",
                    onClick: () => {
                      customAxios
                        .put(`/admin/sale/void/${data.id}`, {
                          remark,
                        })
                        .then((res) => {
                          setTrigger(Math.random());
                          modalChange();
                        });
                    },
                  },
                  secondary: {
                    title: "Cancel",
                    onClick: () => {
                      modalChange();
                    },
                  },
                });

                setShowModal(true);
              }}
              to={`/order-requests/edit/${data.id}`}
            >
              <PrimaryButton title="Void Sale" />
            </div>
          </div>
        )
      );
    }
  }, [data, permissions, trigger]);

  let { id } = useParams();

  React.useEffect(() => {
    customAxios.get(`/admin/sales/${id}`).then((res) => {
      setData(res.data);
      setOrderProducts(res.orderRequestProducts);
      setLoading(false);
    });
  }, [trigger]);

  const statusRender = (isVoid) => {
    switch (isVoid) {
      case true:
        return (
          <div className="flex flex-row space-x-3 items-center">
            <div className="w-5 h-5 rounded-full bg-red-500 animate-pulse"></div>
            <p className="text-sm font-semibold">Void</p>
          </div>
        );
      case false:
        return (
          <div className="flex flex-row space-x-3">
            <div className="w-5 h-5 rounded-full bg-teal-500"></div>
            <p className="text-sm font-semibold">Completed</p>
          </div>
        );
      default:
        return (
          <div className="flex flex-row space-x-3">
            <div className="w-5 h-5 rounded-full bg-green-500"></div>
            <p className="text-sm font-semibold">Completed</p>
          </div>
        );
    }
  };

  return (
    <div className="absolute  bg-slate-50 h-[calc(100vh-20px)]  w-full ">
      <div className="grid grid-cols-1 gap-3">
        {loading ? (
          <div className="flex flex-row justify-center items-center">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-3">
            {statusRender(data?.isVoid)}
            {data?.payment?.status === "pending" && (
              <div className="flex flex-row bg-amber-100 border-2 border-amber-500 rounded-xl p-5 justify-start items-start">
                <div className="flex flex-row space-x-2">
                  <p className="text-base text-amber-500 font-medium">
                    Pending payment verification. Please verify the payment
                  </p>
                </div>
              </div>
            )}
            <div className="grid grid-cols-12 gap-3">
              <div className="col-span-8 space-y-3">
                <Card heading={"Sale Details"}>
                  <div className="grid grid-cols-4 gap-4">
                    {itemRender("Reference Number", data?.referenceNumber)}
                    {itemRender(
                      "Outlet / BP / Tech Team",
                      data?.userGroup?.name
                    )}
                    {itemRender(
                      "Sale Date",
                      moment(data?.createdAt).format("MMMM Do YYYY, h:mm a")
                    )}
                    {itemRender("Sale By", data?.createdByUser?.username)}
                  </div>
                </Card>
                <div className="grid grid-cols-12 gap-3">
                  <div className={`col-span-12`}>
                    <Card heading={"Order Request"}>
                      <DataGrid
                        x_columns={[
                          {
                            Header: "Name",
                            accessor: "name",
                          },
                          {
                            Header: "SKU",
                            accessor: "productVariant.sku",
                          },
                          {
                            Header: "Quantity",
                            accessor: "quantity",
                          },
                          {
                            Header: "Unit Price",
                            accessor: "unitPrice",
                          },
                          {
                            Header: "Discount",
                            accessor: "discount",
                          },
                          {
                            Header: "Total Price",
                            accessor: "totalPrice",
                          },
                          {
                            Header: "Assigned Serial",
                            accessor: "serializedProduct.stbSerialNumber",
                          },
                        ]}
                        testData={data.saleProducts || []}
                        showHeader={true}
                        cellFunction={(cell) => cellFunction(cell)}
                      />
                    </Card>
                  </div>
                  <div className="col-span-12">
                    {data?.isVoid && (
                      <Card heading={"Void Details"}>
                        <div className="grid grid-cols-3">
                          {itemRender("Voided Reason", data?.voidRemark)}
                          {itemRender(
                            "Voided On",
                            `${moment(data?.voidedAt).format("DD MMMM YYYY")}`
                          )}
                          {itemRender(
                            "Voided By",
                            data?.voidedByUser?.username
                          )}
                        </div>
                      </Card>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-span-4 space-y-3">
                <Card heading={"Invoice Details"}>
                  <div className="grid grid-cols-2 gap-8 pb-3">
                    {itemRender("Invoice Number", data?.invoice?.invoiceNumber)}
                    {itemRender(
                      "Invoice Date",
                      moment(data?.invoice?.createdAt).format("DD MMM YYYY ")
                    )}
                    {itemRender(
                      "Payment Type",
                      // capitalize the first letter
                      data?.payment?.paymentMethod.charAt(0).toUpperCase() +
                        data?.payment?.paymentMethod.slice(1) || "-"
                    )}
                    {itemRender(
                      "Total Amount",
                      `MVR ${data?.invoice?.totalAmount}`
                    )}
                  </div>
                  <SecondaryButton
                    onClick={handlePrint}
                    title={"Print Invoice"}
                  />
                  <PrimaryButton
                    onClick={handlePrintBill}
                    title={"Print Bill"}
                  />
                </Card>
                <Card heading={"Customer Details"}>
                  <div className="grid grid-cols-2 gap-4">
                    {itemRender(
                      "Account Number",
                      data?.customer[0]?.accountNumber ?? "-"
                    )}
                    {itemRender("Name", data?.customer[0]?.name ?? "-")}
                    {itemRender("Address", data?.customer[0]?.address ?? "-")}
                    {itemRender(
                      "Phone Number",
                      data?.customer[0]?.phone ?? "-"
                    )}
                    {itemRender("Email", data?.customer[0]?.email ?? "-")}
                  </div>
                </Card>
              </div>
            </div>
            <div className=" absolute  w-full  -z-20" ref={componentRef}>
              <Invoice data={data} />
            </div>
            <div className=" fixed  w-full top-[800px]  -z-20">
              <div ref={componentRefBill}>
                <BillPrint collectiveData={data} />
              </div>
            </div>
          </div>
        )}
        <Modal
          heading={modalContent.heading}
          subHeading={modalContent.subHeading}
          show={show}
          setShowModal={modalChange}
        >
          <div>{modalContent?.content ? modalContent.content : ""}</div>
          <div>
            {modalContent?.remark && (
              <div className="space-y-1 p-5">
                <h1 className="text-sm font-medium">Remark</h1>
                <textarea
                  onChange={(e) => setRemark(e.target.value)}
                  className="w-full border border-gray-300 rounded-md p-2"
                  rows={3}
                />
              </div>
            )}
          </div>

          <div class="bg-gray-50 sticky bottom-0 w-full px-4 py-3 md:flex grid grid-cols-2 gap-3  justify-end items-center flex-row space-x-3">
            {modalContent?.secondary && (
              <SecondaryButton
                onClick={modalContent?.secondary?.onClick}
                title={modalContent?.secondary?.title}
              />
            )}

            {modalContent?.primary && (
              <PrimaryButton
                onClick={() => {
                  customAxios
                    .put(`/admin/sale/void/${data.id}`, {
                      remark,
                    })
                    .then((res) => {
                      setTrigger(Math.random());
                      modalChange();
                    });
                }}
                title={modalContent?.primary?.title}
              />
            )}

            <button
              onClick={modalChange}
              className="fixed top-8 hover:bg-slate-50 p-1 right-5"
            >
              <XIcon className="h-6 w-6" />
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
}
