import DataGrid from "components/data-grid/DataGrid";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { columns } from "./columns";
import { useNavigate } from "react-router-dom";
import DropDownButton from "ui/buttons/DropDownButton";
import { FaDrumSteelpan, FaDumpster, FaEdit } from "react-icons/fa";
import {
  CheckCircleIcon,
  PencilAltIcon,
  XIcon,
} from "@heroicons/react/outline";
import moment, { Moment } from "moment";
import Card from "ui/Card";

export default function OrderRequests() {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "Order Requests",
      })
    );
    dispatch(
      setNavigationComponents(
        <PrimaryButton
          title="Add Order Request"
          onClick={() => navigate("/order-requests/add")}
        />
      )
    );
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/home",
        },
        {
          name: "Order Requests",
          path: "/order-requests",
        },
      ])
    );
  }, []);

  const cellFunction = (cell) => {
    if (cell.column.id === "referenceNumber") {
      return (
        <div
          onClick={() => {
            navigate("/order-requests/view/" + cell.row.original.id, {
              state: cell.row.original,
            });
          }}
          className="flex flex-row items-center space-x-3 cursor-pointer"
        >
          <p className="hover:underline">{cell.value}</p>
        </div>
      );
    }
    if (cell.column.id === "orderId") {
      return (
        <div
          onClick={() => {
            navigate("/order-requests/view/" + cell.row.original.id, {
              state: cell.row.original,
            });
          }}
          className="flex flex-row items-center space-x-3 cursor-pointer"
        >
          <p className="hover:underline">{cell.value}</p>
        </div>
      );
    }
    if (cell.column.id === "createdAt") {
      return moment(cell.value).format("DD MMMM YYYY");
    }

    if (cell.column.id === "actions") {
      return (
        <DropDownButton
          items={[
            // {
            //   title: "Accept",
            //   icon: <CheckCircleIcon className="h-5 w-5" />,
            //   path: "/order-requests/accept/" + cell.row.original.id,
            //   state: cell.row.original,
            // },
            // {
            //   title: "Reject",
            //   icon: <XIcon className="h-5 w-5" />,
            //   path: "/order-requests/reject/" + cell.row.original.id,
            //   state: cell.row.original,
            // },
            {
              title: "Edit",
              icon: <PencilAltIcon className="h-5 w-5" />,
              path: "/order-requests/edit/" + cell.row.original.id,
              state: cell.row.original,
            },
            {
              title: "Delete",
              icon: <FaDrumSteelpan className="h-5 w-5" />,
              path: "/order-requests/delete/" + cell.row.original.id,
              state: cell.row.original,
            },
          ]}
          title="Actions"
          icon={<i className="fas fa-ellipsis-v"></i>}
          className=""
          setSelected={(item) => {
            console.log(item);
          }}
          path="/sales/"
        />
      );
    }
    if (cell.column.id === "status") {
      switch (cell.value) {
        case "shipped":
          return (
            <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-fuchsia-100 text-fuchsia-800">
              Shipped
            </span>
          );
        case "delivered":
          return (
            <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-blue-100 text-blue-800">
              Recieved
            </span>
          );
        case "rejected":
          return (
            <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-red-100 text-red-800">
              Rejected
            </span>
          );
        case "approved":
          return (
            <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-teal-100 text-teal-800">
              Approved
            </span>
          );
        case "cancelled":
          return (
            <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-gray-100 text-gray-800">
              Cancelled
            </span>
          );
        default:
          return (
            <span className="px-2 inline-flex text-[10px] leading-5  rounded-full bg-amber-100 text-amber-800">
              Pending
            </span>
          );
          break;
      }
    }
    return cell.value;
  };

  return (
    <div className="relative">
      <Card
        heading="Order Requests"
        description={"Find all the order requests here"}
      >
        <DataGrid
          search={true}
          x_columns={columns}
          searchable={true}
          api={"/admin/order-requests"}
          showHeader={true}
          height={"h-[calc(100vh-230px)]"}
          cellFunction={cellFunction}
        />
      </Card>
      <Outlet />
    </div>
  );
}
