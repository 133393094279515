import axios from "axios";

import React from "react";
import { useDropzone } from "react-dropzone";
import PrimaryButton from "ui/buttons/PrimaryButton";
import SecondarButton from "ui/buttons/SecondaryButton";

export default function FileUpload({
  setFieldValue,
  field,
  fieldText,
  previewHeight,
}) {
  const [files, setFiles] = React.useState([]);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    multiple: false,
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      let filename = acceptedFiles[0].name;
      // remove white space
      filename = filename.replace(/\s/g, "");
      let filetype = acceptedFiles[0].type;
      let file = acceptedFiles[0];

      // change file contents
      let reader = new FileReader();

      reader.onload = function (e) {
        let contents = e.target.result;
        let base64 = contents.split(",")[1];
        file = new File([base64], filename, { type: filetype });
      };

      let formData = new FormData();

      console.log(file.name);
      formData.append("file", file);
      formData.append("mimetye", file.type);
      formData.append("filename", file.name);
      formData.append("size", file.size);
      formData.append("fieldname", "file");

      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/admin/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          console.log(res);
          setFieldValue(field, res.data.filename);
        });
    },
  });

  const thumbs = files.map((file) => (
    <div style={{ fontSize: 10 }} className="w-full" key={file.name}>
      <div className="p-0.5 px-2 border border-secondary text-secondary rounded-md">
        <div
          className="w-full bg-contain bg-no-repeat bg-center "
          style={{
            backgroundImage: `url(${file.preview})`,
            height: previewHeight ?? 350,
          }}
        />

        <p>{file.name}</p>
        <p>{file.type}</p>
      </div>
    </div>
  ));

  return (
    <div className="flex flex-col w-full relative space-y-2">
      {!files?.length > 0 ? (
        <div
          {...getRootProps({ className: "dropzone" })}
          className="border border-secondary bg-slate-100 w-full  focus:outline-none rounded-md space-y-3 flex flex-col items-center justify-center  p-5"
        >
          <input {...getInputProps()} />
          <img
            className="h-10 w-10"
            src="https://cdn-icons-png.flaticon.com/128/3685/3685850.png"
          />
          <p className="text-primary" style={{ fontSize: 10 }}>
            {fieldText ??
              `Drag 'n' drop some files here, or click to select files`}
          </p>
          {/* fileupload  */}
        </div>
      ) : (
        <>
          <aside className="">{thumbs}</aside>
          <SecondarButton onClick={() => setFiles([])} title="Remove current" />
        </>
      )}
    </div>
  );
}
