import Checkbox from "components/checkbox/Checkbox";
import Modal from "components/modal/Modal";

import moment from "moment";
import React, { useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";
import SecondaryButton from "ui/buttons/SecondaryButton";
import Invoice from "./printables/invoice";
import { useReactToPrint } from "react-to-print";
import axios from "axios";

export default function PaymentsPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  let paymentNumber = useParams().paymentNumber;
  let [loading, setLoading] = React.useState(true);
  let [customerAgreed, setCustomerAgreed] = React.useState(false);
  let [pageData, setPageData] = React.useState({});
  let [showPayLaterModal, setShowPayLaterModal] = React.useState(false);
  let [showInvoice, setShowInvoice] = React.useState(false);
  let [refreshTrigger, setRefreshTrigger] = React.useState(
    Math.random().toString()
  );
  let [paymentTriggered, setPaymentTriggered] = React.useState(false);
  const componentRef = useRef();
  let [isPaymentSuccess, setIsPaymentSuccess] = React.useState(false);
  let [isPaymentFailed, setIsPaymentFailed] = React.useState(false);

  const handleInvoicePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  React.useEffect(() => {
    setShowInvoice(false);
    axios
      .get(
        process.env.REACT_APP_AXIOS_URL +
          "/admin/assesment/payments/fetch-payment-details/" +
          paymentNumber,
        {
          params: {
            paymentNumber: paymentNumber,
          },
        }
      )
      .then((res) => {
        setPageData(res.data);
        axios
          .get(
            process.env.REACT_APP_AXIOS_URL +
              "/admin/assesment/payments/check-payment/" +
              res.data.id,
            {
              timeout: 10000,
            }
          )
          .then(
            (res) => {},
            (err) => {
              setIsPaymentFailed(true);
            }
          );
        if (searchParams.get("STATE") == "CONFIRMED") {
          setIsPaymentSuccess(true);
        } else if (searchParams.get("STATE") == "FAILED") {
          setIsPaymentFailed(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [paymentNumber, refreshTrigger]);

  function statusUpdateHandler(status) {
    if (status == "pay_later") {
      axios
        .put(
          process.env.REACT_APP_AXIOS_URL +
            `/admin/assesment/update-payment-status/${pageData?.id}?status=${status}`
        )
        .then((res) => {
          if (res.status == 200) {
            alert("Payment status updated to pay later");
            setShowPayLaterModal(false);
            setRefreshTrigger(Math.random().toString());
          } else {
            alert("Something went wrong");
            setShowPayLaterModal(false);
          }
        });
      setRefreshTrigger(Math.random().toString());
      return;
    }
    alert("end");
  }

  return (
    <div
      style={{
        backgroundImage: `url("https://i0.wp.com/hotelinsidermv.com/wp-content/uploads/2021/08/m-logo-1920-720-1.jpg?fit=1920%2C720&ssl=1)`,
      }}
      className="bg-fit w-screen overflow-hidden  h-screen flex justify-center items-center"
    >
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="w-full  space-y-3 md:space-y-5 pt-5 mx-5  max-w-3xl md:max-w-xl  text-center flex flex-col justify-center bg-white p-5 rounded-2xl shadow">
          <div className="flex flex-col space-y-2 items-center">
            <img
              src="https://medianet.mv/img/medianet_logo.png"
              className=" h-10 w-10 md:h-14 md:w-14"
            />
            <p className="text-xs md:text-sm md:text-xl font-medium text-center">
              Quick Pay
            </p>
          </div>

          <div className="flex justify-center items-center flex-col space-y-1">
            <p className="text-xs md:text-sm font-medium">Amount</p>
            <p className=" text-2xl md:text-3xl font-bold col-span-2">
              MVR {pageData?.amount}
            </p>
          </div>
          {/* payment alert */}
          {pageData.status == "paid" && (
            <div className="flex bg-green-100 p-5 flex-col space-y-2">
              <p className="text-xl font-medium">
                Payment has been successfully completed.
              </p>
              <p className="text-xs md:text-sm font-medium">
                Thank you for using Medianet Online Payment.
              </p>
            </div>
          )}

          <div className="gap-3 md:gap-5 p-3 md:p-5 grid grid-cols-4 rounded-2xl bg-neutral-100">
            <div className="flex justify-start col-span-3 items-start flex-col">
              <p className="text-xs md:text-sm font-medium">Payment Number</p>
              <p className="text-xs md:text-sm font-bold col-span-2">
                {pageData?.reference}
              </p>
            </div>

            <div className="flex w-full justify-start items-start col-span-2 flex-col">
              <p className="text-xs md:text-sm font-medium">
                Customer Account #:
              </p>
              <p className="text-xs md:text-sm font-bold capitalize col-span-2">
                {pageData?.invoice?.assesment?.customer?.accountNumber}
              </p>
            </div>
            <div className="flex w-full justify-start items-start col-span-2 flex-col">
              <p className="text-xs md:text-sm font-medium">Customer Name:</p>
              <p className="text-xs md:text-sm font-bold capitalize col-span-2">
                {pageData?.invoice?.assesment?.customer?.name}
              </p>
            </div>
            <div className="flex w-full justify-start items-start col-span-2 flex-col">
              <p className="text-xs md:text-sm font-medium">Ticket #:</p>
              <p className="text-xs md:text-sm font-bold capitalize col-span-2">
                {pageData?.invoice?.assesment?.ticketNumber}
              </p>
            </div>
            <div className="flex justify-start items-start col-span-2 flex-col">
              <p className="text-xs md:text-sm font-medium">
                Payment initiated on:
              </p>
              <p className="text-xs md:text-sm font-bold col-span-2">
                {new Date().toLocaleDateString()}
              </p>
            </div>
            <div className="flex justify-start col-span-3 items-start flex-col">
              <p className="text-xs md:text-sm font-medium">Services:</p>
              <div className="flex flex-row space-x-2 text-left">
                {pageData?.invoice?.assesment?.assesmentItems?.map((item) => {
                  return (
                    <p className="text-xs md:text-sm w-max font-bold col-span-1">
                      {item?.serviceItem?.name},{" "}
                    </p>
                  );
                })}
              </div>
            </div>
            <div className="flex justify-start col-span-2 items-start flex-col">
              <p className="text-xs md:text-sm font-medium">Invoice#</p>
              <p className="text-xs md:text-sm font-bold col-span-1">
                {pageData?.invoice?.invoiceNumber}
              </p>
            </div>
            {pageData?.status == "sent" || pageData?.status == "pay_later" ? (
              <div className="flex justify-start col-span-2  items-start flex-col">
                <SecondaryButton
                  onClick={() => {
                    setShowInvoice(true);
                    handleInvoicePrint();
                  }}
                  title={"Print Invoice"}
                />
              </div>
            ) : (
              <div></div>
            )}
          </div>

          {pageData?.status == "sent" || pageData.status == "pay_later" ? (
            <div className="flex flex-row space-x-3 items-start">
              <Checkbox
                className="text-xs md:text-sm font-medium"
                checked={customerAgreed}
                onClick={() => {
                  setCustomerAgreed(!customerAgreed);
                }}
              />
              <p className="text-xs md:text-sm text-start font-normal">
                I hereby acknowledge that I accept the above mentioned charged
                amount and agree to pay the same.
              </p>
            </div>
          ) : (
            <div></div>
          )}

          {pageData?.status == "sent" || pageData?.status == "pay_later" ? (
            <div className="flex flex-col space-y-3 items-center">
              <button
                disabled={paymentTriggered}
                onClick={() => {
                  if (customerAgreed) {
                    setPaymentTriggered(true);
                    //   new page redirect
                    //   get url param from link
                    axios
                      .get(
                        process.env.REACT_APP_AXIOS_URL +
                          "/admin/assesment/payments/trigger-payment/" +
                          pageData?.id
                      )
                      .then((res) => {
                        if (res.data?.url) {
                          window.location.href = res.data.url;
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                      })
                      .finally(() => {
                        setPaymentTriggered(false);
                      });
                  } else {
                    alert("Please agree to the terms and conditions");
                  }
                }}
                className="w-full p-2 text-base rounded-xl text-white bg-slate-800"
              >
                {paymentTriggered ? (
                  <div className="flex flex-row justify-center items-center">
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                      ></path>
                    </svg>
                    Processing...
                  </div>
                ) : (
                  <div>
                    {pageData?.status == "pay_later"
                      ? `Pay before ${
                          // add 14 days to the created date
                          moment(pageData?.createdAt)
                            .add(14, "days")
                            .format("DD MMM YYYY")
                        }`
                      : "Pay Now"}
                  </div>
                )}
              </button>

              {pageData?.status == "sent" && (
                <button
                  onClick={() => {
                    if (customerAgreed) {
                      setShowPayLaterModal(true);
                    } else {
                      alert("Please agree to the terms and conditions");
                    }
                  }}
                  className="text-black underline text-xs md:text-sm font-medium"
                >
                  Pay Later
                </button>
              )}

              <div className="flex flex-row justify-center items-center">
                <img
                  src="https://s3-ap-southeast-1.amazonaws.com/mse-misc/company-images/logo/BML.png"
                  className="h-14 w-14"
                />
                <img
                  src="https://usa.visa.com/dam/VCOM/regional/ve/romania/blogs/hero-image/visa-logo-800x450.jpg"
                  className="h-14 w-14 object-contain"
                />
                <img
                  src="https://logos-world.net/wp-content/uploads/2020/09/Mastercard-Logo.png"
                  className="h-14 w-14 object-contain"
                />
              </div>
            </div>
          ) : (
            <div className="flex justify-center col-span-2  items-center flex-col">
              <PrimaryButton
                width={"w-full w-[100%]"}
                onClick={() => {
                  setShowInvoice(true);
                  handleInvoicePrint();
                }}
                title={"Print Invoice"}
              />
            </div>
          )}
          {showInvoice && (
            <div className=" absolute  over -z-20" ref={componentRef}>
              <Invoice data={pageData} />
            </div>
          )}
        </div>
      )}
      <Modal
        show={showPayLaterModal}
        onClose={() => {
          setShowPayLaterModal(false);
        }}
      >
        <div className="flex max-w-xl flex-col space-y-5 p-5">
          <p className="text-xl font-bold">Pay Later</p>
          <p className="text-xs md:text-sm font-normal">
            Enjoy the convenience of paying within 14 days. Simply use the link
            provided in our message or visit any of our outlets for in-store
            payment. Please have your invoice number at hand for outlet
            payments.
          </p>
          <div className="flex flex-row space-x-5">
            <SecondaryButton
              onClick={() => {
                setShowPayLaterModal(false);
              }}
              title={"Cancel"}
            ></SecondaryButton>
            <PrimaryButton
              onClick={() => {
                statusUpdateHandler("pay_later");
              }}
              title={"Pay Later"}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
