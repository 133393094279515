import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import navigationReducer from "../features/navigation/navigationSlice";
import cartReducer from "../features/cart/CartSlice";
import notificationReducer from "features/notification/NotificationSlice";
import DataReducer from "features/data/DataSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    navigation: navigationReducer,
    cart: cartReducer,
    notification: notificationReducer,
    data: DataReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
