import Modal from "components/modal/Modal";
import React from "react";
import SecondaryButton from "ui/buttons/SecondaryButton";
import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/solid";
import { useParams, useNavigate } from "react-router-dom";
import customAxios from "middleware/customAxios";
import LoadingPrimaryButton from "ui/buttons/LoadingPrimaryButton";
import { set } from "date-fns";
import { itemRender } from "functions/ItemRender";

export default function Refurbish() {
  let navigate = useNavigate();
  let { id } = useParams();
  let [show, setShow] = React.useState(true);
  let [submitted, setSubmitted] = useState(false);
  let [loading, setLoading] = useState(false);
  let [serializedProductInfo, setSerializedProductInfo] = useState({});

  function modalChange() {
    setShow(!show);
    navigate("/products/serialized-products");
  }

  React.useEffect(() => {
    fetchSerializedProductInfo();
  }, []);

  let fetchSerializedProductInfo = async () =>
    await customAxios.get(`/admin/serialized-product/${id}`).then((res) => {
      setSerializedProductInfo(res);
      setLoading(false);
    });

  return (
    <div className="  ">
      <Modal
        show={show}
        setShowModal={modalChange}
        heading="Are you sure you want to refurbish the following STB?"
        subHeading="This action cannot be reverted"
      >
        <div className="p-5 space-y-2">
          {itemRender("Serial Number", serializedProductInfo.stbSerialNumber)}
          {itemRender("Customer Name", serializedProductInfo?.customer?.name)}
        </div>
        <div className="flex flex-row bg-slate-50 justify-end space-x-2 p-3">
          <SecondaryButton
            title={"Cancel"}
            className="bg-slate-300 text-white rounded-md px-3 py-2"
            onClick={modalChange}
          ></SecondaryButton>
          <LoadingPrimaryButton
            log={"Changing to Refurbished..."}
            submitted={submitted}
            onClick={() => {
              setSubmitted(true);
              customAxios
                .put(`/admin/serialized-product/${id}/refurbish`)
                .then((res) => {
                  setLoading(false);
                  setSubmitted(false);
                  modalChange();
                });
            }}
            title={"OK"}
            className="bg-slate-300 text-white rounded-md px-3 py-2"
          ></LoadingPrimaryButton>
        </div>
      </Modal>
    </div>
  );
}
