import {
  ArrowCircleLeftIcon,
  ArrowSmLeftIcon,
  DocumentTextIcon,
} from "@heroicons/react/outline";
import {
  CashIcon,
  CreditCardIcon,
  DocumentIcon,
  UserIcon,
} from "@heroicons/react/solid";
import {
  clearCart,
  setCurrentSale,
  setGlobalSelectedCustomer,
} from "features/cart/CartSlice";
import Lottie from "react-lottie-player";
import React from "react";
import {
  FaCashRegister,
  FaCreditCard,
  FaEnvelope,
  FaFileInvoice,
  FaPage4,
  FaUser,
} from "react-icons/fa";
import { MdDocumentScanner, MdRemove } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "ui/buttons/PrimaryButton";
import SecondaryButton from "ui/buttons/SecondaryButton";
import OrderItemCard from "./OrderItemCard";
import Modal from "components/modal/Modal";
import customAxios from "middleware/customAxios";
import { useNavigate } from "react-router-dom";
import Successlottie from "assets/lotties/success.json";
import BillPrint from "./components/BillPrint";
import { currencyFormatter } from "functions/currencyFormatter";
import { useReactToPrint } from "react-to-print";

let paymentMethods = [
  {
    name: "cash",
    icon: <CashIcon className="h-7 w-7" />,
  },
  {
    name: "card",
    icon: <FaCreditCard className="h-5 w-5" />,
  },
  {
    name: "account",
    icon: <FaUser className="h-5 w-5" />,
  },
  {
    name: "cheque",
    icon: <FaEnvelope className="h-5 w-5" />,
  },
  {
    name: "bankTransfer",
    icon: <FaFileInvoice className="h-5 w-5" />,
  },
];

export default function CurrentOrder() {
  let [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(
    paymentMethods[0]
  );
  let [checkOut, setCheckOut] = React.useState(false);
  let dispatch = useDispatch();
  let [orderProceeded, setOrderProceeded] = React.useState(false);
  let [checkoutData, setCheckoutData] = React.useState({
    paymentMethod: {
      name: "Cash",
    },
  });
  let cart = useSelector((state) => state.cart);
  let selectedInventory = useSelector((state) => state.data.selectedInventory);
  let selectedCustomer = useSelector((state) => state.cart.selectedCustomer);
  let assignType = useSelector((state) => state.cart.assignType);
  let [orderCompleted, setOrderCompleted] = React.useState(false);
  const componentRef = React.useRef();
  let selectedSerializedProduct = useSelector(
    (state) => state.cart.selectedSerializedProduct
  );
  let [currentSaleId, setCurrentSaleId] = React.useState(null);
  let currentSale = useSelector((state) => state.cart.currentSale);

  let navigate = useNavigate();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  React.useEffect(() => {
    setSelectedPaymentMethod(paymentMethods[0]);
  }, []);

  let quickCash = [10, 20, 50, 100, 200, 500, 1000];

  let quickNote = [
    "Software Issue",
    "Fiber Migration",
    "Package Offer",
    "No Coax Offer",
    "Coax Damage",
    "Under MCare",
  ];

  let saleData = {
    userGroupId: selectedInventory?.group?.id,
    customerReference: selectedCustomer?.accountNumber ?? "none",
    note: checkoutData?.note,
    isDraft: false,
    tin: checkoutData?.customerTin,
    discount: cart?.discount ?? 0,
    collectiveDiscount: cart?.discount,
    discountId: null,
    attachment: cart?.attachment ?? null,
    totalTax: 0,
    totalAmount: cart?.total,
    paymentMethod: selectedPaymentMethod?.name,
    paymentNote: checkoutData?.paymentNote ?? "Nah",
    saleProducts: cart?.items?.map((product) => {
      return {
        productVariantId: product?.id,
        quantity: parseInt(product?.quantity),
        discount: product?.discount ?? 0,
        discountId: product?.appliedDiscount?.id ?? null,
        tax: 0,
        total: product?.price,
        unitPrice: parseInt(product?.unitPrice),
      };
    }),
  };

  function handleSaleEnd() {
    handlePrint();
    setOrderCompleted(false);
    setOrderProceeded(false);
    dispatch(clearCart());
    dispatch(setGlobalSelectedCustomer(null));
  }

  let handleCheckout = () => {
    setCheckOut(true);
    if (
      selectedCustomer?.accountNumber == null &&
      selectedInventory?.id == null
    ) {
      return alert("Please select a customer or inventory");
    }

    if (cart?.items?.find((product) => product.isSerialized)) {
      customAxios.post("/admin/sale", saleData).then((res) => {
        setCurrentSaleId(res.sale.id);
        console.log(res.sale);
        dispatch(setCurrentSale(res.sale));
        let data = {
          accountNumber: selectedCustomer?.accountNumber,
          saleId: res.sale.id ?? currentSaleId,
        };
        if (assignType == "migration") {
          let data = {
            type: assignType,
            saleId: currentSale.id,
            customerId: selectedCustomer.id,
          };
          customAxios
            .put(
              "/admin/serialized-products/" + selectedSerializedProduct.id,
              data
            )
            .then((res) => {
              console.log(res);
              setOrderProceeded(false);
              setOrderCompleted(true);
              setCheckOut(false);
            });
        } else {
          customAxios
            .put(
              "/admin/serialized-products/" +
                selectedSerializedProduct.id +
                "/assign-customer",
              data
            )
            .then((res) => {
              let data = {
                type: assignType,
                saleId: currentSale.id,
                customerId: selectedCustomer.id,
              };
              customAxios
                .put(
                  "/admin/serialized-products/" + selectedSerializedProduct.id,
                  data
                )
                .then((res) => {
                  console.log(res);
                  setOrderProceeded(false);
                  setOrderCompleted(true);
                  setCheckOut(false);
                });
            });
        }
      });
    } else {
      customAxios.post("/admin/sale", saleData).then((res) => {
        dispatch(setCurrentSale(res.sale));
        setCheckOut(false);
        setOrderProceeded(false);
        setOrderCompleted(true);
      });
    }
  };

  return (
    <>
      <div className="relative flex flex-col space-y-5 h-[calc(100vh-30px)] overflow-auto ">
        <div className="bg-white  rounded-md">
          <div className="flex flex-row justify-center items-center border-b pl-3 px-2 rounded-t-md text-white p-2  bg-slate-700">
            <h1 className="text-md font-bold w-full ">Current Order</h1>
            {cart?.items?.length > 0 && (
              <div className="max-w-xl">
                <SecondaryButton
                  title="Clear"
                  onClick={() => dispatch(clearCart())}
                />
              </div>
            )}
          </div>
          {/* Order items */}
          <div className="flex relative flex-col space-y-2 p-3 py-2 h-[calc(100vh-450px)]  overflow-auto ">
            {cart.items?.length == 0 && (
              <div className="flex flex-col space-y-3 justify-center items-center h-full">
                <div
                  style={{
                    backgroundImage: `url('https://cdn3.vectorstock.com/i/1000x1000/01/77/business-man-pushing-an-empty-cart-vector-24830177.jpg)`,
                  }}
                  className="w-40 h-40 bg-cover bg-center bg-no-repeat"
                ></div>
                <h1 className="text-sm font-normal">No Items in Cart</h1>
              </div>
            )}
            {cart.items?.map((item, i) => (
              <OrderItemCard key={i} item={item}></OrderItemCard>
            ))}
            {/* Make gradient  */}
          </div>

          {/* billed */}
          <div className="flex flex-col space-y-2  pt-3 bg-white rounded-lg">
            <div className="w-full grid grid-cols-2 gap-1 text-base px-4">
              <div className="flex flex-col justify-center items-start">
                <h1 className=" font-bold">Sub Total</h1>
              </div>
              <div className="flex flex-col justify-center items-end">
                <h1 className=" font-medium">
                  {currencyFormatter(cart?.total)}
                </h1>
              </div>
              <div className="flex flex-col justify-center items-start">
                <h1 className=" font-bold">Discount</h1>
              </div>
              <div className="flex flex-col justify-center items-end">
                {/* <h1 className=" font-medium">MVR 10.00</h1> */}
                <h1 className=" font-medium">
                  {currencyFormatter(cart?.totalDiscount)}
                </h1>
              </div>
            </div>
            <div className=" py-2 px-4 border-black flex flex-row justify-between  text-xl bg-amber-400 text-slate-900 font-bold p-2 rounded-b-md items-center w-full">
              <h1 className=" ">Total</h1>
              <h2>
                MVR{" "}
                {
                  // Total
                  (cart?.total - cart?.totalDiscount).toFixed(2)
                }
              </h2>
            </div>
          </div>
        </div>

        <div className="flex  justify-start space-y-2 bg-white  rounded-xl  flex-col  w-full ">
          {/* Payment Method */}
          <div className="flex flex-col space-y-2   rounded-lg">
            <div className="flex flex-row justify-center items-center border-b pl-3 px-1 rounded-t-md text-white p-3  bg-slate-700">
              <h1 className="text-md font-bold w-full ">Payment Method</h1>
            </div>
            <div className="grid grid-cols-5 p-2">
              {
                // map payment methods
                paymentMethods.map((method, i) => (
                  <div
                    key={i}
                    className="flex flex-col group cursor-pointer  justify-center items-center space-y-2"
                    onClick={() => {
                      setSelectedPaymentMethod(method);
                      setCheckoutData({
                        ...checkoutData,
                        paymentMethod: method,
                      });
                    }}
                  >
                    <div className="flex justify-center items-center flex-col space-y-2">
                      <div
                        className={`${
                          selectedPaymentMethod == method
                            ? "bg-yellow-500 text-white"
                            : "bg-slate-200 text-slate-900"
                        } flex flex-row group-hover:ring-2 ring-offset-2  justify-center items-center rounded-full p-3 h-14 w-14`}
                      >
                        {method.icon}
                      </div>
                      <p>
                        <span className="font-bold text-xs">{method.name}</span>
                      </p>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          {/* buttons */}
          <div className="grid grid-cols-2 pt-2">
            <div className="col-span-2 flex flex-row space-x-3 justify-around ">
              <PrimaryButton
                width={"100%"}
                title="PROCEED"
                onClick={() => {
                  if (selectedCustomer?.accountNumber == null) {
                    return alert("Please select a customer");
                  } else if (cart?.items?.length == 0) {
                    return alert("Please add items to cart");
                  } else {
                    setOrderProceeded(true);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal show={orderProceeded}>
        <div className="flex flex-col space-y-4 w-[calc(100vw-800px)]">
          <div className="flex flex-col bg-white rounded-xl  space-y-4">
            <div className="flex flex-row justify-start items-center border-b space-x-3 pl-3 px-2 rounded-t-md text-white p-2  bg-slate-700">
              <div
                onClick={() => setOrderProceeded(false)}
                className="flex items-center cursor-pointer hover:brightness-110 justify-center p-1 rounded-full bg-slate-200"
              >
                <ArrowSmLeftIcon className="h-6 w-6 text-slate-800" />
              </div>
              <h1 className="text-xl font-bold">Checkout</h1>
            </div>

            {selectedPaymentMethod.name == "Cash" && (
              <div className="p-5">
                <div className="flex flex-col space-y-3">
                  <h1 className="text-sm font-bold">Quick Cash Select</h1>
                  <div className="grid grid-cols-7 space-x-3 items-center">
                    {
                      // Quick cash select
                      quickCash.map((item) => (
                        <div
                          className={`${
                            checkoutData?.recieved == item
                              ? "bg-slate-900 text-white"
                              : ""
                          } flex items-center cursor-pointer hover:brightness-110  bg-slate-200 justify-center p-3 rounded-full `}
                          onClick={() => {
                            setCheckoutData({
                              ...checkoutData,
                              recieved: item,
                            });
                          }}
                        >
                          <h1
                            className={`${
                              checkoutData?.recieved == item
                                ? "bg-slate-900 text-white"
                                : ""
                            } text-base font-bold`}
                          >
                            {item}
                          </h1>
                        </div>
                      ))
                    }
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-3 text-xl font-bold">
                  <div>
                    <label className="text-sm ">Recieved</label>
                    <input
                      type="number"
                      value={checkoutData?.recieved ?? ""}
                      onChange={(e) => {
                        setCheckoutData({
                          ...checkoutData,
                          recieved: e.target.value,
                        });
                      }}
                      className="w-full p-3 border border-slate-200 rounded-xl outline-slate-800 "
                    />
                  </div>
                  <div>
                    <label className="text-sm ">Payable</label>
                    <div
                      className="w-full p-3 cursor-not-allowed bg-slate-200 rounded-xl
                "
                    >
                      {
                        // Total
                        (cart?.total - cart?.totalDiscount).toFixed(2)
                      }
                    </div>
                  </div>
                  <div>
                    <label className="text-sm ">Change</label>
                    <div
                      className="w-full p-3 cursor-not-allowed bg-yellow-500 rounded-xl
                "
                    >
                      {
                        // Change
                        checkoutData?.recieved
                          ? (
                              checkoutData?.recieved -
                              (cart?.total - cart?.totalDiscount).toFixed(2)
                            ).toFixed(2) ?? 0
                          : 0
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}

            {selectedPaymentMethod.name == "Card" && (
              <div className="flex flex-col space-y-3">
                <h1 className="text-sm font-bold">Transaction Details</h1>
              </div>
            )}

            <div className="flex flex-col space-y-1 text-sm font-bold px-4">
              <div className="flex flex-wrap ">
                {quickNote.map((item) => (
                  <p
                    className="p-1 px-2 m-2 rounded-full bg-slate-200 cursor-pointer hover:brightness-110"
                    onClick={() => {
                      setCheckoutData({
                        ...checkoutData,
                        note: item,
                      });
                    }}
                  >
                    <span className="font-bold text-sm">{item}</span>
                  </p>
                ))}
              </div>
              <label>Sales Note</label>
              <textarea
                value={checkoutData?.note ?? ""}
                onChange={(e) => {
                  setCheckoutData({
                    ...checkoutData,
                    note: e.target.value,
                  });
                }}
                className="w-full p-3 border font-normal text-lg border-slate-200 rounded-xl outline-slate-800 "
              />
            </div>
            <div className="flex flex-col space-y-1 text-sm font-bold px-4">
              <label>Customer TIN</label>
              <input
                onChange={(e) => {
                  setCheckoutData({
                    ...checkoutData,
                    customerTin: e.target.value,
                  });
                }}
                className="w-full p-3 border font-normal text-lg border-slate-200 rounded-xl outline-slate-800 "
              />
            </div>
            <div className="p-3">
              <PrimaryButton
                disabled={checkOut}
                onClick={() => {
                  handleCheckout();
                }}
                title="SUBMIT"
                width={"100%"}
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal show={orderCompleted}>
        <div className="p-5 flex-col -space-y-1 flex justify-center items-center text-center">
          <h1 className="text-2xl font-medium">Sale Completed</h1>
          <div className=" justify-center   items-center text-center  ">
            <Lottie
              loop={false}
              animationData={Successlottie}
              play
              style={{ width: 200, height: 200 }}
            />
          </div>
          <div className="flex flex-row space-x-3 w-full justify-between">
            <PrimaryButton
              onClick={() => {
                handleSaleEnd();
              }}
              title="OK"
              width={"100%"}
            />
            <SecondaryButton
              onClick={() => {
                handlePrint();
              }}
              title={"Print"}
              width={"100%"}
            />
          </div>
          <div className=" fixed  w-full top-[800px]  -z-20">
            <div ref={componentRef}>
              <BillPrint
                checkoutData={checkoutData}
                data={cart}
                currentSale={currentSale}
                outlet={selectedInventory}
                customer={selectedCustomer}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
