import React from "react";
import FormGenerator from "components/form-generator/FormGenerator";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import { MultiSelect } from "react-multi-select-component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import customAxios from "middleware/customAxios";
import {
  addNotification,
  setShow,
} from "features/notification/NotificationSlice";

export default function OutletEdit() {
  let dispatch = useDispatch();
  let { id } = useParams();
  let [outlet, setOutlet] = React.useState({});
  let [selectedRoles, setSelectedRoles] = React.useState([]);
  let [loading, setLoading] = React.useState(true);
  let navigate = useNavigate();

  let userData = useSelector((state) => state.data.userData);
  let permissions = userData.permissions;

  React.useEffect(() => {
    if (permissions && permissions[0].length > 0) {
      if (!permissions[0].includes("r-sinventory")) {
        navigate("/401");
      }
    }
  }, []);

  React.useEffect(() => {
    customAxios.get("/outlets/" + id).then((res) => {
      setOutlet(res.data);
      setLoading(false);
    });

    dispatch(
      setCurrent({
        name: "Edit Outlet",
      })
    );
    dispatch(setNavigationComponents(null));
    dispatch(
      setBreadCrumbs([
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Users",
          path: "/products",
        },
        {
          name: "Add Product",
          path: "/products/add",
        },
      ])
    );
  }, []);

  const formData = {
    contents: [
      {
        fields: [
          {
            name: "name",
            displayName: "Name",
            type: "field",
            inputType: "string",
            required: true,
          },
          {
            name: "phone",
            displayName: "Phone",
            type: "field",
            inputType: "string",
            required: true,
          },
          {
            name: "address",
            displayName: "Address",
            type: "textArea",
            inputType: "string",
            required: true,
          },
          {
            name: "state",
            displayName: "State",
            type: "field",
            inputType: "string",
            required: true,
          },
          {
            name: "city",
            displayName: "City",
            type: "field",
            inputType: "string",
            required: true,
          },
        ],
      },
    ],
    initialValues: {
      name: outlet.name,
      phone: outlet.phone ?? "",
      address: outlet.address ?? "",
      state: outlet.state ?? "",
      city: outlet.city ?? "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      phone: Yup.string().required("Phone is required"),
      address: Yup.string().required("Address is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
    }),
    onSubmit: async (values, { setErrors }) => {
      customAxios.put("/outlets/" + id, values).then((res) => {
        if (res) {
          // dispatch(setShow(true));
          dispatch(addNotification("Outlet updated successfully"));
          // navigate("/outlets");
        }
      });
    },
  };

  return (
    <div className="absolute grid grid-cols-2 gap-10 min-h-[75vh]  rounded-xl top-0 w-full bg-white">
      {!loading && (
        <FormGenerator
          grids={1}
          modal={true}
          buttonSubmit={{ title: "Proceed" }}
          formData={formData}
        />
      )}
    </div>
  );
}
