import { Pie } from "react-chartjs-2";
import ChartContainer from "../charts-container/ChartsContainer";
import { normaliseDatasets } from "../HelperFunctions";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables)

export default function PieChart ({className, title, labels, datasets}) {
    const normalisedDatasets = normaliseDatasets(datasets);

    const dataConfig = {
        datasets: normalisedDatasets,
        labels: labels
    }

    const options = {
        responsive: true
    }

    return (
        <Pie options={options} data={dataConfig}/>
    )
}

PieChart.defaultProps = {
    className: "",
    title: "",
    data: [],
    labels: [],
    datasets: []
}