import React from "react";
import "./App.css";
import MainLayout from "./layout/MainLayout";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import POSLayout from "layout/POSLayout";
import customAxios from "middleware/customAxios";
import {
  setGlobalSelectedInventory,
  setSelectedInventory,
  setUserData,
} from "features/data/DataSlice";

import AOS from "aos";
import "aos/dist/aos.css";
import jwtDecode from "jwt-decode";

AOS.init();

// You can also pass an optional settings object
// below listed default settings
AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
  initClassName: "aos-init", // class applied after initialization
  animatedClassName: "aos-animate", // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 40, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: "ease", // default easing for AOS animations
  once: true, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
});

function App() {
  let [isPOS, setIsPOS] = React.useState(false);
  let userData = useSelector((state) => state.data.userData);
  let dispatch = useDispatch();
  let navigate = useNavigate();

  let token = localStorage.getItem("token");

  let decodedToken = null;

  if (token) {
    decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      // get the current path
      let currentPath = window.location.pathname;
      // if the current path is not login, then redirect to login
      let urlParams = new URLSearchParams();
      urlParams.append("redirectURL", currentPath);
      localStorage.removeItem("token");
      window.location.href = "/login" + "?" + urlParams.toString();
    }
  }

  // get path name
  let pathname = window.location.pathname;
  // check if path name is /pos
  React.useEffect(() => {
    if (pathname === "/pos") {
      setIsPOS(true);
    } else {
      setIsPOS(false);
    }
  }, []);

  React.useEffect(() => {
    if (userData?.outlets?.length > 0) {
      dispatch(setGlobalSelectedInventory(userData.outlets[0]));
    }
    dispatch(
      setUserData({
        name: decodedToken?.name,
        id: decodedToken?.id,
        outlets: decodedToken?.outlets,
        permissions: decodedToken?.permissions,
      })
    );
  }, []);

  return (
    <>
      {isPOS ? (
        <POSLayout modal={true}>
          <Outlet />
        </POSLayout>
      ) : (
        <MainLayout>
          <Outlet />
        </MainLayout>
      )}
    </>
  );
}

export default App;
