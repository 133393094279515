import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "components/modal/Modal";
import SecondaryButton from "ui/buttons/SecondaryButton";
import LoadingPrimaryButton from "ui/buttons/LoadingPrimaryButton";
import PrimaryButton from "ui/buttons/PrimaryButton";
import customAxios from "middleware/customAxios";
import { useParams } from "react-router-dom";

export default function OutletDelete({ setOpen }) {
  let navigate = useNavigate();
  let { id } = useParams();
  const [showModal, setShowModal] = useState(true);

  let modalChange = () => {
    console.log("hit");
    setShowModal(false);
    navigate("/outlets");
  };

  React.useEffect(() => {
    setShowModal(true);
  }, []);

  return (
    <div className="  ">
      <Modal
        show={showModal}
        setShowModal={modalChange}
        heading="Delete Outlet"
        subHeading="Are you sure you want to delete this outlet?"
      >
        <div class="bg-gray-50 sticky bottom-0 w-full px-4 py-3 md:flex grid grid-cols-2 gap-3  justify-end items-center flex-row space-x-3">
          <SecondaryButton onClick={modalChange} title={"Cancel"} />

          <PrimaryButton
            title={"Delete"}
            onClick={() =>
              customAxios.delete("/outlets/" + id).then((res) => {
                navigate("/outlets");
              })
            }
          />
        </div>
      </Modal>
    </div>
  );
}
