import { XIcon } from "@heroicons/react/solid";
import DataGrid from "components/data-grid/DataGrid";
import Modal from "components/modal/Modal";
import {
  setBreadCrumbs,
  setCurrent,
  setNavigationComponents,
} from "features/navigation/navigationSlice";
import { itemRender } from "functions/ItemRender";
import customAxios from "middleware/customAxios";
import moment from "moment";
import React from "react";
import { MultiSelect } from "react-multi-select-component";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";
import SecondaryButton from "ui/buttons/SecondaryButton";
import Card from "ui/Card";

export default function OrderRequestView() {
  let dispatch = useDispatch();
  let [data, setData] = React.useState({});
  let [loading, setLoading] = React.useState(true);
  let [orderProducts, setOrderProducts] = React.useState([]);
  let userData = useSelector((state) => state.data.userData);
  let permissions = userData?.permissions;
  let [show, setShowModal] = React.useState(false);
  let [trigger, setTrigger] = React.useState(Math.random());
  let [selectedSPs, setSelectedSPs] = React.useState([]);
  let [serializedProducts, setSerializedProducts] = React.useState([]);
  let [finalSerializedProducts, setFinalSerializedProducts] = React.useState(
    []
  );
  let [paymentVerificationData, setPaymentVerificationData] = React.useState(
    {}
  );
  let [cartHasSerializedProducts, setCartHasSerializedProducts] =
    React.useState(false);

  let [remark, setRemark] = React.useState("");

  let [modalContent, setModalContent] = React.useState({
    heading: "",
    subHeading: "",
  });

  function modalChange() {
    setShowModal(!show);
  }

  React.useEffect(() => {
    customAxios.get("/admin/serialized-products/all").then((res) => {
      let data = res.data
        .filter((x) => x.status == "available" && x.userGroupId == 1)
        .map((item) => {
          return {
            label: item.stbSerialNumber ?? item.sncSerialNumber ?? "",
            value: item.id,
            data: item,
          };
        });
      setSerializedProducts(data);
    });
  }, []);

  React.useEffect(() => {
    dispatch(
      setCurrent({
        name: "View" + " " + data.referenceNumber,
      })
    );

    dispatch(
      setBreadCrumbs([
        {
          name: "Order Requests",
          link: "/order-requests",
        },
        {
          name: "View" + " " + data.referenceNumber,
          link: `/order-requests/view/${data.id}`,
        },
      ])
    );
    dispatch(
      setNavigationComponents(
        <>
          {permissions?.length > 0 && data?.status == "pending" && (
            <div className="flex flex-row space-x-3">
              {permissions[0]?.includes("u-order-request") && (
                <PrimaryButton
                  onClick={() => {
                    setModalContent({
                      heading:
                        "Are you sure you want to accept the order request?",
                      subHeading:
                        "Once you accept the order request, the order status will be changed to en route and the user will be notified.",

                      primary: {
                        title: "Accept",
                        onClick: () => {
                          customAxios
                            .patch(
                              `/admin/order-request/${data.id}/` + "approved"
                            )
                            .then((res) => {
                              console.log(res);
                              setTrigger(Math.random());
                              setShowModal(false);
                            });
                        },
                      },
                    });
                    setShowModal(true);
                  }}
                  title={"Accept"}
                />
              )}
              {permissions[0]?.includes("u-order-request") && (
                <SecondaryButton
                  onClick={() => {
                    setModalContent({
                      heading:
                        "Are you sure you want to reject the order request?",
                      subHeading:
                        "Once you reject the order request, the order status will be changed to rejected and the user will be notified.",
                      remark: true,
                      primary: {
                        title: "Submit",
                        onClick: () => {
                          let body = {
                            remark: remark,
                          };
                          customAxios
                            .patch(
                              `/admin/order-request/${data.id}/` + "rejected",
                              body
                            )
                            .then((res) => {
                              console.log(res);
                              setTrigger(Math.random());
                              setShowModal(false);
                            });
                        },
                      },
                    });
                    setShowModal(true);
                  }}
                  title={"Reject"}
                />
              )}
              {permissions[0]?.includes("u-order-request") && (
                <SecondaryButton
                  onClick={() => {
                    setModalContent({
                      heading:
                        "Are you sure you want to cancel the order request?",
                      subHeading:
                        "Once you cancel the order request, the order status will be changed to cancelled and the user will be notified.",
                      remark: true,
                      primary: {
                        title: "Submit",
                        onClick: () => {
                          let body = {
                            remark: remark,
                          };
                          customAxios
                            .patch(
                              `/admin/order-request/${data.id}/` + "cancel",
                              body
                            )
                            .then((res) => {
                              console.log(res);
                              setTrigger(Math.random());
                              setShowModal(false);
                            });
                        },
                      },
                    });
                    setShowModal(true);
                  }}
                  title={"Cancel"}
                />
              )}
            </div>
          )}
          {permissions[0]?.includes("u-order-request") &&
            data?.status == "shipped" && (
              <div className="flex text-base font-medium text-slate-600 flex-col justify-end items-end space-y-3">
                <div className="flex flex-row items-center space-x-4">
                  <h1 className="text-black">Have you recieved the order?</h1>
                  <PrimaryButton
                    onClick={() => {
                      setModalContent({
                        heading:
                          "Are you sure you want to mark the order as picked up?",
                        subHeading:
                          "Once you mark the order as picked up, the order status will be changed to delivered and the user will be notified.",

                        primary: {
                          title: "Yes",
                          onClick: () => {
                            customAxios
                              .patch(
                                `/admin/order-request/${data.id}/` + "delivered"
                              )
                              .then((res) => {
                                console.log(res);
                                setTrigger(Math.random());
                                //  refresh window
                                setShowModal(false);
                              });
                          },
                        },
                        secondary: {
                          title: "No",
                          onClick: () => {
                            setShowModal(false);
                          },
                        },
                      });
                      setShowModal(true);
                    }}
                    title={"Mark as Recieved"}
                  />
                </div>
              </div>
            )}
          {permissions[0]?.includes("u-order-request") &&
            data?.status == "approved" && (
              <div className="justify-end flex-row space-x-3 items-center w-full flex">
                <p className=" text-sm">Is the order shipped?</p>
                <PrimaryButton
                  title="Ship"
                  onClick={() => {
                    if (selectedSPs.length == 0) {
                      return alert("Please select all the serialized products");
                    }
                    customAxios
                      .patch("/admin/order-request/" + data.id + "/shipped", {
                        selectedSPs: selectedSPs.map((item) => item.data.id),
                      })
                      .then((res) => {
                        console.log(res);
                        setTrigger(Math.random() * 1000);
                      });
                  }}
                />
              </div>
            )}
        </>
      )
    );
  }, [data, permissions, trigger]);

  let { id } = useParams();

  React.useEffect(() => {
    customAxios.get(`/admin/order-request/${id}`).then((res) => {
      setData(res.data);
      setOrderProducts(res.orderRequestProducts);

      setFinalSerializedProducts(res.serializedProducts);
      setLoading(false);

      let isSerialized = res.orderRequestProducts.filter((item) => {
        return item?.isSerialized;
      });

      if (isSerialized.length > 0) {
        cartHasSerializedProducts(true);
      }
    });
  }, [trigger]);

  const cellFunction = (cell) => {
    if (cell.column.id === "name") {
      return (
        <div className="flex flex-row space-x-2">
          <img
            src={
              process.env.REACT_APP_SERVER_URL +
                cell.row.original?.productVariant?.image ||
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcKGtXOa4PF2h8Gr04XXB7cwJwDBdz8ScN892tcpsi_A&s"
            }
            className="w-10 h-10"
          />
          <div>
            <h1>{cell.row.original.productVariant?.product?.name}</h1>
            <p className="text-sm font-semibold">
              {cell.row.original.productVariant?.name}
            </p>
          </div>
        </div>
      );
    }
    if (cell.column.id === "quantity") {
      return (
        <div className="flex flex-row space-x-2">
          <p className="text-sm font-semibold">{cell.value}</p>
          <p className="text-sm font-semibold">
            {cell.row.original.productVariant?.unit?.name}
          </p>
        </div>
      );
    }
    if (cell.column.id === "unitPrice") {
      return (
        <div className="flex flex-row space-x-2">
          <p className="text-sm font-semibold">{cell.value}</p>
          <p className="text-sm font-semibold">
            {cell.row.original.productVariant?.price}
          </p>
        </div>
      );
    }
    if (cell.column.id === "totalPrice") {
      return (
        <div className="flex flex-row space-x-2">
          <p className="text-sm font-semibold">
            {cell.row.original.productVariant.price *
              cell.row.original.quantity}
          </p>
        </div>
      );
    }

    return cell.value ?? "-";
  };

  function SPSelectHandler(e) {
    console.log(e);
    data.orderRequestProducts.map((x) => {
      console.log(x);
    });
    // check if data.orderRequestProducts has the same id
    e.map((sp) => {
      let found = data.orderRequestProducts.find(
        (element) => element.productVariantId === sp.data.productVariantId
      );
      let limit = data.orderRequestProducts.find(
        (element) => element.productVariantId === sp.data.productVariantId
      ).quantity;

      console.log(found);

      if (found) {
        console.log(found);
        console.log("found");
        setSelectedSPs(e);
      }
    });
    // if yes, add it
    console.log(e);
    // if no, remove it
  }

  const statusRender = (status) => {
    switch (status) {
      case "shipped":
        return (
          <span className="px-4 inline-flex text-sm w-fit p-1 leading-5  rounded-full bg-fuchsia-100 text-fuchsia-800">
            Shipped
          </span>
        );
      case "recieved":
        return (
          <span className="px-4 inline-flex text-sm w-fit p-1 leading-5  rounded-full bg-blue-100 text-blue-800">
            Shipped
          </span>
        );
      case "rejected":
        return (
          <span className="px-4 inline-flex text-sm w-fit p-1 leading-5  rounded-full bg-red-100 text-red-800">
            Rejected
          </span>
        );
      case "approved":
        return (
          <span className="px-4 inline-flex text-sm w-fit p-1 leading-5  rounded-full bg-teal-100 text-teal-800">
            Approved
          </span>
        );
      case "delivered":
        return (
          <span className="px-4 inline-flex text-sm w-fit p-1 leading-5  rounded-full bg-teal-100 text-teal-800">
            Delivered
          </span>
        );
      default:
        return (
          <span className="px-4 inline-flex text-sm w-fit p-1 leading-5  rounded-full bg-amber-100 text-amber-800">
            Pending
          </span>
        );
        break;
    }
  };

  return (
    <div className="absolute  bg-slate-50 h-[calc(100vh-150px)] pb-10  rounded-xl top-0 w-full ">
      <div className="grid grid-cols-1 gap-3">
        {loading ? (
          <div className="flex flex-row justify-center items-center">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-3">
            {statusRender(data?.status)}
            {data?.payment?.status === "pending" && (
              <div className="flex flex-row bg-amber-100 border-2 border-amber-500 rounded-xl p-5 justify-start items-start">
                <div className="flex flex-row space-x-2">
                  <p className="text-base text-amber-500 font-medium">
                    Pending payment verification. Please verify the payment
                  </p>
                </div>
              </div>
            )}

            <Card heading={"Order Request Details"}>
              <div className="grid grid-cols-4 gap-4">
                {itemRender("Reference Number", data?.referenceNumber)}
                {itemRender("Outlet / BP / Tech Team", data?.userGroup.name)}
                {itemRender("Requested By", data?.createdByUser.username)}
                {itemRender(
                  "Requested Date",
                  moment(data?.createdAt).format("DD MMM YYYY ")
                )}
              </div>
            </Card>
            <div className="grid grid-cols-12 gap-3">
              <div
                className={`${
                  data?.userGroup?.groupType === "BP"
                    ? "col-span-8"
                    : "col-span-12"
                }`}
              >
                <Card heading={"Order Request"}>
                  <DataGrid
                    x_columns={[
                      {
                        Header: "Name",
                        accessor: "name",
                      },
                      {
                        Header: "SKU",
                        accessor: "productVariant.sku",
                      },
                      {
                        Header: "Quantity",
                        accessor: "quantity",
                      },
                      {
                        Header: "Unit Price",
                        accessor: "unitPrice",
                      },
                      {
                        Header: "Total Price",
                        accessor: "totalPrice",
                      },
                    ]}
                    testData={data.orderRequestProducts || []}
                    showHeader={true}
                    cellFunction={(cell) => cellFunction(cell)}
                  />
                </Card>
              </div>
              {data?.userGroup?.groupType === "BP" && (
                <div className={`col-span-4 capitalize`}>
                  <Card
                    heading={"Order Request Payment"}
                    description={
                      "View the attachment to verify the payment proof"
                    }
                  >
                    {itemRender("Invoice Number", data?.invoice?.invoiceNumber)}
                    {itemRender(
                      "Invoice Date",
                      moment(data?.invoice?.createdAt).format("DD MMM YYYY ")
                    )}
                    {itemRender(
                      "Payment Type",
                      data?.payment?.paymentMethod == "bankTransfer"
                        ? "Bank Transfer"
                        : "" || "-"
                    )}
                    {itemRender(
                      "Total Amount",
                      `MVR ${data?.invoice?.totalAmount}`
                    )}
                    {itemRender("Payment Status", data?.payment?.status)}
                    <div className=" space-y-1 pb-5">
                      <h1 className="text-sm font-medium">
                        Payment Attachment
                      </h1>
                      <button
                        onClick={() => {
                          setModalContent({
                            heading: "Payment Attachment",
                            subHeading: "Payment Attachment",
                            content: (
                              <div className="p-10">
                                <div
                                  className="w-full bg-contain bg-no-repeat bg-center "
                                  style={{
                                    backgroundImage: `url(${
                                      data?.payment?.attachment || ""
                                    })`,
                                    height: 350,
                                  }}
                                />
                              </div>
                            ),
                            secondary: {
                              title: "Close",
                              onClick: () => {
                                setShowModal(false);
                              },
                            },
                            // primary: {
                            //   title: "Verify",
                            //   onClick: () => {
                            //     setShowModal(false);
                            //   },
                            // },
                          });
                          setShowModal(true);
                        }}
                      >
                        View Attachment
                      </button>
                    </div>
                    {data.payment.status !== "paid" && (
                      <div className="grid grid-cols-2 gap-3">
                        <PrimaryButton
                          onClick={() => {
                            setModalContent({
                              heading:
                                "Are you sure you want to verify the payment?",
                              subHeading:
                                "Please refer to the payment attachment and verify the payment",

                              primary: {
                                title: "Verify",
                                onClick: () => {
                                  console.log(remark);
                                  customAxios
                                    .patch(
                                      `/admin/order-request/${data.id}/accept-payment`
                                    )
                                    .then((res) => {
                                      console.log(res);
                                      setShowModal(false);
                                      setTrigger(Math.random() * 1000);
                                    });
                                },
                              },
                            });
                            setShowModal(true);
                          }}
                          title="Verify"
                        />
                        <SecondaryButton
                          onClick={() => {
                            setModalContent({
                              remark: true,
                              heading:
                                "Are you sure you want to reject the payment ?",
                              subHeading:
                                "Please refer to the payment attachment and reject the payment",

                              primary: {
                                title: "Reject",
                                onClick: () => {
                                  console.log("Yoo");
                                },
                              },
                            });
                            setShowModal(true);
                          }}
                          title="Reject"
                        />
                      </div>
                    )}
                  </Card>
                </div>
              )}
            </div>

            {permissions[0]?.includes("r-order-request") && (
              <div className="grid grid-cols-12 gap-3 pb-10">
                <div
                  className={`${
                    data?.userGroup?.groupType === "BP"
                      ? "col-span-8"
                      : "col-span-12"
                  }`}
                >
                  <Card
                    heading={
                      data.status == "approved"
                        ? "Add Serialized Products"
                        : "Added Serialized Products"
                    }
                  >
                    {finalSerializedProducts?.length > 0 && (
                      <div className="flex flex-row p-2 w-full bg-slate-50 rounded-md space-x-3"></div>
                    )}

                    {permissions[0]?.includes("x-or-ship") &&
                      data.status == "approved" && (
                        <MultiSelect
                          options={serializedProducts ?? []}
                          value={selectedSPs}
                          onChange={SPSelectHandler}
                          labelledBy={"Select"}
                          placeholder={"Select Serialized Products"}
                        />
                      )}

                    <DataGrid
                      x_columns={[
                        {
                          Header: "STB Serial",
                          accessor:
                            data.status == "approved"
                              ? "data.stbSerialNumber"
                              : "stbSerialNumber",
                        },
                        {
                          Header: "SNC Serial",
                          accessor:
                            data.status == "approved"
                              ? "data.sncSerialNumber"
                              : "sncSerialNumber",
                        },
                        {
                          Header: "STB Type",
                          accessor:
                            data.status == "approved" ? "data.type" : "type",
                        },
                        {
                          Header: "Name",
                          accessor:
                            data.status == "approved"
                              ? "data.productVariant.name"
                              : "productVariant.name",
                        },
                        {
                          Header: "Dealer",
                          accessor:
                            data.status == "approved"
                              ? "data.dealer.name"
                              : "dealer.name",
                        },
                      ]}
                      testData={
                        data.status == "approved"
                          ? selectedSPs
                          : finalSerializedProducts || []
                      }
                      height={"h-[calc(100vh-330px)]"}
                      showHeader={true}
                      cellFunction={(cell) => cellFunction(cell)}
                    />

                    {selectedSPs.length > 0 && (
                      <div className="justify-end flex-row space-x-3 items-end w-full flex">
                        <SecondaryButton
                          title="Remove All"
                          onClick={() => setSelectedSPs([])}
                        />
                        <PrimaryButton
                          title="Confirm Selection"
                          onClick={() => {
                            if (selectedSPs.length > 0) {
                              alert(
                                "Please select all the serialized products"
                              );
                            }
                            customAxios
                              .patch(
                                "/admin/order-request/" + data.id + "/shipped",
                                {
                                  selectedSPs: selectedSPs.map(
                                    (item) => item.data.id
                                  ),
                                }
                              )
                              .then((res) => {
                                console.log(res);
                                setTrigger(Math.random() * 1000);
                              });
                          }}
                        />
                      </div>
                    )}
                  </Card>
                </div>
                {data?.userGroup?.groupType === "BP" && (
                  <div className={`col-span-4 capitalize`}>
                    <Card
                      heading={"Order Request Payment"}
                      description={
                        "View the attachment to verify the payment proof"
                      }
                    >
                      {itemRender(
                        "Invoice Number",
                        data?.invoice?.invoiceNumber
                      )}
                      {itemRender(
                        "Invoice Date",
                        moment(data?.invoice?.createdAt).format("DD MMM YYYY ")
                      )}
                      {itemRender(
                        "Payment Type",
                        data?.payment?.paymentMethod == "bankTransfer"
                          ? "Bank Transfer"
                          : "" || "-"
                      )}
                      {itemRender(
                        "Total Amount",
                        `MVR ${data?.invoice?.totalAmount}`
                      )}
                      {itemRender("Payment Status", data?.payment?.status)}
                      <div className=" space-y-1 pb-5">
                        <h1 className="text-sm font-medium">
                          Payment Attachment
                        </h1>
                        <button
                          onClick={() => {
                            setModalContent({
                              heading: "Payment Attachment",
                              subHeading: "Payment Attachment",
                              content: (
                                <div className="p-10">
                                  <div
                                    className="w-full bg-contain bg-no-repeat bg-center "
                                    style={{
                                      backgroundImage: `url(${
                                        data?.payment?.attachment || ""
                                      })`,
                                      height: 350,
                                    }}
                                  />
                                </div>
                              ),
                              secondary: {
                                title: "Close",
                                onClick: () => {
                                  setShowModal(false);
                                },
                              },
                              // primary: {
                              //   title: "Verify",
                              //   onClick: () => {
                              //     setShowModal(false);
                              //   },
                              // },
                            });
                            setShowModal(true);
                          }}
                        >
                          View Attachment
                        </button>
                      </div>
                      {data.payment.status !== "paid" && (
                        <div className="grid grid-cols-2 gap-3">
                          <PrimaryButton
                            onClick={() => {
                              setModalContent({
                                heading:
                                  "Are you sure you want to verify the payment?",
                                subHeading:
                                  "Please refer to the payment attachment and verify the payment",

                                primary: {
                                  title: "Verify",
                                  onClick: () => {
                                    console.log(remark);
                                    customAxios
                                      .patch(
                                        `/admin/order-request/${data.id}/accept-payment`
                                      )
                                      .then((res) => {
                                        console.log(res);
                                        setShowModal(false);
                                        setTrigger(Math.random() * 1000);
                                      });
                                  },
                                },
                              });
                              setShowModal(true);
                            }}
                            title="Verify"
                          />
                          <SecondaryButton
                            onClick={() => {
                              setModalContent({
                                remark: true,
                                heading:
                                  "Are you sure you want to reject the payment ?",
                                subHeading:
                                  "Please refer to the payment attachment and reject the payment",

                                primary: {
                                  title: "Reject",
                                  onClick: () => {
                                    console.log("Yoo");
                                  },
                                },
                              });
                              setShowModal(true);
                            }}
                            title="Reject"
                          />
                        </div>
                      )}
                    </Card>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <Modal
          heading={modalContent.heading}
          subHeading={modalContent.subHeading}
          show={show}
          setShowModal={modalChange}
        >
          <div>{modalContent?.content ? modalContent.content : ""}</div>
          <div>
            {modalContent?.remark && (
              <div className="space-y-1 p-5">
                <h1 className="text-sm font-medium">Remark</h1>
                <textarea
                  onChange={(e) => setRemark(e.target.value)}
                  className="w-full border border-gray-300 rounded-md p-2"
                  rows={3}
                />
              </div>
            )}
          </div>
          <div class="bg-gray-50 sticky bottom-0 w-full px-4 py-3 md:flex grid grid-cols-2 gap-3  justify-end items-center flex-row space-x-3">
            {modalContent?.secondary && (
              <SecondaryButton
                onClick={modalContent?.secondary?.onClick}
                title={modalContent?.secondary?.title}
              />
            )}

            {modalContent?.primary && (
              <PrimaryButton
                onClick={modalContent?.primary?.onClick}
                title={modalContent?.primary?.title}
              />
            )}

            <button
              onClick={modalChange}
              className="fixed top-4 hover:bg-slate-50 p-1 right-5"
            >
              <XIcon className="h-4 w-4 text-slate-300" />
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
}
