const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  isPOS: false,
  current: null,
  previous: null,
  breadCrumbs: [
    {
      name: "Home",
      link: "/",
    },
  ],
  navigationComponents: null,
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setCurrent: (state, action) => {
      state.current = action.payload;
    },
    setBreadCrumbs: (state, action) => {
      state.breadCrumbs = action.payload;
    },
    setNavigationComponents: (state, action) => {
      state.navigationComponents = action.payload;
    },
    setIsPOS: (state, action) => {
      state.isPOS = action.payload;
    },
  },
});

export const { setCurrent, setBreadCrumbs, setNavigationComponents, setIsPOS } =
  navigationSlice.actions;

export default navigationSlice.reducer;
