import moment from "moment";
import React from "react";

export default function Invoice(data) {
  let customer = data?.data?.invoice?.assesment?.customer;
  let pageData = data?.data;
  let assesment = data?.data?.invoice?.assesment;

  let gstTaxDeductionHandler = (amount, gst) => {
    let gstTax = (amount * gst) / 100;
    // 2 decimal places
    return (amount - gstTax).toFixed(2);
  };

  return (
    <div>
      <div className="w-[800px] h-[1200px] relative flex flex-col space-y-4">
        {/* paid stamp */}
        {pageData?.status === "paid" && (
          <div className="absolute top-20 right-0 text-white font-bold p-2">
            <img
              src="https://png.pngtree.com/png-vector/20230208/ourmid/pngtree-paid-stamp-vector-illustration-png-image_6585127.png"
              alt="paid"
              className="w-40 h-40 object-contain"
            />
          </div>
        )}

        <div className="flex bg-slate-800  space-x-4  items-center p-4 w-full ">
          <img
            src={"https://medianet.mv/img/medianet_logo_color.png"}
            alt="logo"
            className="w-8 h-8  rounded-md"
          />
          <h1 className="text-white font-bold text-xl italic">
            Medianet <span className="italic font-extralight">SIMS </span>
          </h1>
        </div>

        {/* Header */}
        <h1 className="text-2xl font-bold text-center">TAX INVOICE</h1>

        <div className="flex flex-col text-sm text-left  space-y-1 p-5">
          <div className="text-xl font-bold">Medianet Pvt Ltd</div>
          <p className="text-sm">c. 349/2005</p>

          <div className="max-w-sm ">H.Zonaria, Boduthakurufaanu Magu</div>
          <p>Male', 20057</p>
          <div className="text-sm max-w-sm ">Phone: 3320800</div>
          <p>Republic of Maldives</p>
          <p>
            <span className="font-bold">TIN:</span>
            <p className="inline-block ml-2">1000935GST501</p>
          </p>
        </div>
        <div className="grid grid-cols-4 px-5 pb-5">
          {/* Billed to */}
          <div className="flex flex-col space-y-2 ">
            <div className="text-sm font-bold">Billed to</div>
            <div className="text-sm max-w-sm ">{customer?.name}</div>
            <div className="text-sm max-w-sm ">{customer?.accountNumber}</div>
            <div className="text-sm max-w-sm ">{customer?.address}</div>
            <div className="text-sm max-w-sm ">
              {data?.customer?.phone ?? "-"}
            </div>
            <div className="text-sm max-w-sm ">
              {data?.customer?.email ?? "-"}
            </div>
          </div>
          <div className="flex flex-col space-y-2 "></div>
          <div className="col-span-2 text-sm flex justify-between items-center px-5">
            <div className="flex flex-col font-bold space-y-2 ">
              <p>Invoice Number</p>
              <p>Invoice Date</p>
              <p>Ticket Number</p>
              <p>Due Date</p>
              <p>Payment Status</p>
            </div>
            <div className="flex flex-col space-y-2 ">
              <p>{pageData?.reference}</p>
              <p>{moment(pageData?.createdAt).format("DD MMM YYYY HH:mm ")}</p>
              <p>{pageData?.invoice?.assesment?.ticketNumber}</p>
              <p>
                {/* 14 days from created date */}
                {moment(pageData?.invoice?.dueDate)
                  .add(14, "days")
                  .format("DD MMM YYYY HH:mm ")}
              </p>
              <p
                className={`uppercase ${
                  pageData?.status === "paid"
                    ? "text-green-500"
                    : "text-yellow-500"
                }`}
              >
                {/* 14 days from created date */}
                {pageData?.status}
              </p>
            </div>
          </div>
        </div>
        {/* Table */}
        <table className=" text-sm m-5 pt-10">
          <thead>
            <tr className="bg-slate-800 text-white ">
              <th className="p-2 text-start">#</th>
              <th className="p-2 text-start">Description</th>
              <th className="p-2 text-start">Quantity</th>
              <th className="p-2 text-start">Unit Price</th>
              <th className="p-2 text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            {assesment?.assesmentItems?.map((item, index) => {
              return (
                <tr
                  className={` text-start
                ${index % 2 === 0 ? "bg-gray-100" : "bg-white"}
              
              `}
                  key={index}
                >
                  <td className="p-2">{index + 1}</td>
                  <td className="p-2">{item?.serviceItem?.name ?? "-"}</td>

                  <td className="p-2">{item?.quantity}</td>
                  <td className="p-2">
                    {gstTaxDeductionHandler(
                      parseInt(item?.serviceItem?.price).toFixed(2),
                      8
                    )}
                  </td>
                  <td className="p-2 text-right">
                    {gstTaxDeductionHandler(
                      parseInt(item?.total).toFixed(2),
                      8
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot className="">
            <tr>
              <td className="p-2"></td>
              <td className="p-2"></td>
              <td className="p-2"></td>
              <td className="p-2">Sub Total</td>
              <td className="p-2 items-end text-right">
                {pageData?.invoice?.totalAmount -
                  ((pageData?.invoice?.totalAmount * 8) / 100).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className="p-2"></td>
              <td className="p-2"></td>
              <td className="p-2"></td>
              <td className="p-2">GST @8%</td>
              <td className="p-2 items-end text-right">
                {
                  // 8% of the total amount
                  (pageData?.invoice?.totalAmount * 8) / (100).toFixed(2)
                }
              </td>
            </tr>
            <tr className="font-bold text-lg text-right">
              <td className="p-2"></td>
              <td className="p-2"></td>
              <td className="p-2"></td>
              <td className="p-2">Total</td>
              <td className="p-2 items-end text-right">
                {/* change to currency format */}
                {pageData?.invoice?.totalAmount}
              </td>
            </tr>
          </tfoot>
        </table>
        {/* Terms and Conditions */}
        <div className="flex flex-col space-y-2 p-5 text-start  bottom-3">
          <div className="text-sm font-bold">Terms and Conditions</div>
          <p className="text-sm">
            The billed customer is required to pay the amount within 14 days
            from the invoice date. Use the link provided in our message or visit
            any of our outlets for in-store payment. Please have your invoice
            number at hand for outlet payments.
          </p>
        </div>
        <div className="flex flex-col space-y-2 p-5  bottom-3">
          <p className="text-xs text-center">
            This is a computer generated invoice and does not require a
            signature.
          </p>
        </div>
      </div>
    </div>
  );
}
