import React from "react";

export default function Loader() {
  return (
    <div>
      <div className="flex justify-center items-center">
        <div className="animate-spin rounded-full h-20 w-20 border-b-8 border-gray-900"></div>
      </div>
    </div>
  );
}
