export const columns = [
  {
    Header: "STB Serial",
    accessor: "stbSerialNumber",
  },
  {
    Header: "SNC Serial",
    accessor: "sncSerialNumber",
  },

  {
    Header: "Name",
    accessor: "productVariant.name",
  },
  {
    Header: "Dealer",
    accessor: "userGroup.name",
  },
  {
    Header: "Customer",
    accessor: "customer?.name",
  },
  {
    Header: "Sale Status",
    accessor: "status",
  },
  {
    Header: "STB Assigned",
    accessor: "isAssigned",
  },
  {
    Header: "Assigned Type",
    accessor: "assignType",
  },
  {
    Header: "Added On",
    accessor: "createdAt",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];
