import React from "react";
import { useSelector } from "react-redux";

export default function SecondaryButton({
  title,
  type,
  onClick,
  disabled,
  width,
}) {
  let isPOS = useSelector((state) => state.navigation.isPOS);
  return (
    <button
      type={type}
      onClick={onClick}
      style={{
        width: width ?? "auto",
        fontSize: isPOS ? "1rem" : "0.7rem",
        padding: isPOS ? "0.8rem 1rem" : "0.5rem 2rem",
      }}
      disabled={disabled}
      className="px-6 py-3 bg-gradient-to-b cursor-pointer from-white to-slate-100 border border-slate-200  w-fit text-xs flex items-center justify-center text-slate-800 dark:text-slate-700 font-medium rounded-md  hover:brightness-95"
    >
      {title}
    </button>
  );
}
